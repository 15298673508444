import {
  put, select, all, call,
} from 'redux-saga/effects';
import * as Immutable from 'immutable';

import { PositionedLabel } from 'types/positionedLabel';
import { selectors as positionedLabelsSelectors } from 'ducks/model/positionedLabels';
import { actions as labelsActions } from 'ducks/model/labels';
import { removePositionedLabel } from 'ducks/remove/removePositionedLabel';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export function* removeLabel(labelId: string) {
  const positionedLabels: Immutable.Map<string, PositionedLabel> = positionedLabelsSelectors
    .getAllPositionedLabels(yield select());

  const positionedLabelIds: string[] = positionedLabels.valueSeq()
    .filter(positionedLabel => positionedLabel.labelId === labelId)
    .map(positionedLabel => positionedLabel.positionedLabelId)
    .toArray();

  yield all(positionedLabelIds.map(positionedLabelId => call(removePositionedLabel, positionedLabelId)));

  yield put(labelsActions.remove(labelId));
}
/* eslint-enable @typescript-eslint/explicit-function-return-type */
