import {
  put, all, select,
} from 'redux-saga/effects';
import * as Immutable from 'immutable';

import { Figure } from 'types/figure';
import { Point } from 'types/point';
import { Wall } from 'types/wall';
import { WallType } from 'types/wallType';
import { getNonSharedWalls } from 'helpers/model/figureWalls';
import { getNonSharedPoints } from 'helpers/model/figurePoints';
import { selectors as pagesSelectors, actions as pagesActions } from 'ducks/model/pages';
import { selectors as figuresSelectors, actions as figuresActions } from 'ducks/model/figures';
import { selectors as wallsSelectors, actions as wallsActions } from 'ducks/model/walls';
import { selectors as pointsSelectors, actions as pointsActions } from 'ducks/model/points';
import { actions as areaTypeModalActions } from 'ducks/modal/areaTypeModal';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export function* removeFigure(figureId: string) {
  const figures: Immutable.Map<string, Figure> = figuresSelectors.getAllFigures(yield select());
  const walls: Immutable.Map<string, Wall> = wallsSelectors.getAllWalls(yield select());
  const points: Immutable.Map<string, Point> = pointsSelectors.getAllPoints(yield select());

  const nonSharedWallsIds = getNonSharedWalls(figures, figureId);
  const nonSharedPointsIds = getNonSharedPoints(walls, points, figures, figureId).map(p => p.pointId).toArray();

  const arcWallsIds = nonSharedWallsIds.filter(wallId => (walls.get(wallId)!).wallType === WallType.ARC);
  const arcPointsIds = arcWallsIds.map(wallId => walls.get(wallId)!.points[2]);

  // remove figure from all pages
  const pageWithFigureId: string = pagesSelectors.getPageIdWithObject(yield select(), figureId)!;
  yield put(pagesActions.removeObject(pageWithFigureId, figureId));

  const areaLabelPosition: Point = figuresSelectors.getAreaLabelPosition(yield select(), figureId)!;

  // remove figure itself
  yield put(figuresActions.remove(figureId));

  // remove non-shared walls
  yield all(nonSharedWallsIds.map(wallId => put(wallsActions.remove(wallId))));

  // remove non-shared points
  yield all(nonSharedPointsIds.map(pointId => put(pointsActions.remove(pointId))));

  // remove arc points
  yield all(arcPointsIds.map(pointId => put(pointsActions.remove(pointId))));

  // remove area label
  if (areaLabelPosition) {
    yield put(pointsActions.remove(areaLabelPosition.pointId));
  }

  // hide area type selection modal
  yield put(areaTypeModalActions.hide());
}
/* eslint-enable @typescript-eslint/explicit-function-return-type */
