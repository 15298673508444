import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import * as Immutable from 'immutable';
import { area as areaColors } from 'config/paletteConfig';

import { Group } from 'types/group';
import { actions as copyActions, getClipboard } from 'ducks/copy/copy';
import { actions as removeObjectsActions } from 'ducks/remove/removeObjects';
import { selectors as selectionSelectors } from 'ducks/selection/selection';

import { selectors as editModeSelectors } from 'ducks/editMode';
import { selectors as figureSelectors, actions as figuresActions } from 'ducks/model/figures';
import { actions as groupActions, selectors as groupSelectors } from 'ducks/groupObjects';

import useContextMenu from 'hooks/useContextMenu';
import usePortal from 'hooks/usePortal';

import { ClosedFigure } from 'types/figure';
import { StyledContextMenu, StyledContextMenuItem } from './ContextMenuStyles';
import { RootState } from '../../reducers/rootReducer';
import { isMac } from '../../helpers/browserDetect';

interface ContextMenuProps {
  readonly parentRef: string;
  readonly type: string;
}
interface StateProps {
  readonly selectedObjects: Immutable.List<string>;
  readonly isDuplicableSelected: boolean;
  readonly canRemoveSelectedObjects: boolean;
  readonly currentSelectedGroupId: string | undefined;
  readonly groups: Immutable.Map<string, Group>;
  readonly currentGroup: Group | undefined;
  readonly figuresIds: string[];
  readonly figures: Immutable.Map<string, ClosedFigure>;
}

interface ActionProps {
  readonly copy: typeof copyActions.copy;
  readonly paste: typeof copyActions.paste;
  readonly flipSelection: typeof copyActions.flip;
  readonly mirrorSelection: typeof copyActions.mirror;
  readonly removeSelectedObjects: () => void;
  readonly setGroup: typeof groupActions.setGroup;
  readonly modifyGroup: typeof groupActions.modifyGroup;
  readonly updateFigure: typeof figuresActions.update;
}

type Props = StateProps & ActionProps & ContextMenuProps

const ContextMenu = ({
  removeSelectedObjects,
  canRemoveSelectedObjects,
  isDuplicableSelected,
  copy,
  paste,
  flipSelection,
  mirrorSelection,
  parentRef,
  type,
  selectedObjects,
  setGroup,
  modifyGroup,
  currentSelectedGroupId,
  groups,
  currentGroup,
  updateFigure,
  figuresIds,
  figures,
}: Props): JSX.Element => {
  const { xPos, yPos, showMenu } = useContextMenu(parentRef, type);
  const target = usePortal('sketch-context-menu-root');

  const copySelection = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    copy();
  };

  const pasteSelection = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    paste();
  };

  const duplicate = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    copy();
    paste();
  };

  const flip = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    flipSelection();
  };

  const mirror = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    mirrorSelection();
  };

  const deleteSelection = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    removeSelectedObjects();
  };

  const groupSelectionAction = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    if (currentSelectedGroupId && groups.max() && currentGroup?.selectedObjects.size === selectedObjects.size) {
      modifyGroup();
    } else {
      setGroup(selectedObjects);
    }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    event.stopPropagation();
  };

  const colorFillAction = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();

    const selectedFiguresIds = selectedObjects.filter(objectId => figuresIds.includes(objectId));
    const selectedFigures = figures.valueSeq().filter(figure => selectedFiguresIds.includes(figure.figureId));

    selectedFigures.toArray().forEach(figure => {
      if (isSelectedFigureColorFilled()) {
        updateFigure(figure.figureId, { colorFill: areaColors.base });
      } else {
        // TODO: should update with color selected by user
        updateFigure(figure.figureId, { colorFill: '#cccccc' });
      }
    });
  };

  const selectedFiguresIds = selectedObjects.filter(objectId => figuresIds.includes(objectId));
  const selectedFigures = figures.valueSeq().filter(figure => selectedFiguresIds.includes(figure.figureId));

  const isSelectedFigureColorFilled = (): boolean => selectedFigures.toArray().some(figure => {
    // TODO: hardcoded check for now
    // should check against the color selected by user
    if (figure.colorFill === '#cccccc') return true;
    return false;
  });

  const isColorFilledText = isSelectedFigureColorFilled() ? 'Remove Area Shade' : 'Apply Area Shade';
  const shortCutKeyLabel = isMac ? 'Cmd' : 'Ctrl';

  const contextMenuList = [
    {
      title: 'Copy',
      disabled: isDuplicableSelected,
      onClick: copySelection,
      shortcut: `${shortCutKeyLabel} + C`,
      group: 1,
    },
    {
      title: 'Paste',
      disabled: getClipboard().figures?.length || getClipboard().positionedLabels?.length || getClipboard().positionedSymbols?.length,
      onClick: pasteSelection,
      shortcut: `${shortCutKeyLabel} + V`,
      group: 1,
    },
    {
      title: 'Duplicate',
      disabled: isDuplicableSelected,
      onClick: duplicate,
      shortcut: `${shortCutKeyLabel} + D`,
      group: 1,
    },
    {
      title: isColorFilledText,
      disabled: isDuplicableSelected,
      onClick: colorFillAction,
      shortcut: '-',
      // shortcut: `${shortCutKeyLabel} + D`,
      group: 1,
    },
    {
      title: 'Undo',
      disabled: false,
      onClick: null,
      shortcut: `${shortCutKeyLabel} + Z`,
      group: 2,

    },
    {
      title: 'Redo',
      disabled: false,
      onClick: null,
      shortcut: `${shortCutKeyLabel} + Y`,
      group: 2,
    },
    {
      title: currentSelectedGroupId && groups.size && currentGroup?.selectedObjects.size === selectedObjects.size ? 'Ungroup' : 'Group',
      disabled: selectedObjects.size > 1,
      onClick: groupSelectionAction,
      shortcut: '-',
      // shortcut: `${shortCutKeyLabel} + G`,
      group: 2,
    },
    {
      title: 'Flip',
      disabled: true,
      onClick: flip,
      shortcut: `${shortCutKeyLabel} + F`,
      group: 3,
    },
    {
      title: 'Mirror',
      disabled: true,
      onClick: mirror,
      shortcut: `${shortCutKeyLabel} + G`,
      group: 3,
    },
    {
      title: 'Select All',
      disabled: false,
      onClick: null,
      shortcut: `${shortCutKeyLabel} + A`,
      group: 4,
    },
    {
      title: 'Delete',
      disabled: canRemoveSelectedObjects,
      onClick: deleteSelection,
      shortcut: 'Backspace',
      group: 4,
    },
  ];

  return ReactDOM.createPortal(
    showMenu && (
      <StyledContextMenu left={xPos} top={yPos} onPointerDown={handleMenuClick}>
        {contextMenuList.map((contextMenuListItem: any) => (
          <StyledContextMenuItem key={contextMenuListItem.title} disabled={!contextMenuListItem.disabled} onClick={contextMenuListItem.onClick}>
            <div>{contextMenuListItem.title}</div>
            <div>{contextMenuListItem.shortcut}</div>
          </StyledContextMenuItem>
        ))}
      </StyledContextMenu>
    ), target,
  );
};

export default connect(
  (state: RootState): StateProps => {
    const groupId = editModeSelectors.getCurrentSelectedGroupId(state);
    return {
      isDuplicableSelected: selectionSelectors.areDuplicableSelected(state),
      canRemoveSelectedObjects: selectionSelectors.canRemoveSelectedObjects(state),
      selectedObjects: editModeSelectors.getSelectedObjects(state),
      currentSelectedGroupId: editModeSelectors.getCurrentSelectedGroupId(state),
      groups: groupSelectors.getAllGroups(state),
      currentGroup: groupSelectors.getGroupById(state, groupId || ''),
      figuresIds: figureSelectors.getFiguresIds(state),
      figures: figureSelectors.getAllClosedFigures(state),
    };
  },
  {
    copy: copyActions.copy,
    paste: copyActions.paste,
    flipSelection: copyActions.flip,
    mirrorSelection: copyActions.mirror,
    removeSelectedObjects: removeObjectsActions.removeSelectedObjects,
    setGroup: groupActions.setGroup,
    modifyGroup: groupActions.modifyGroup,
    updateFigure: figuresActions.update,
  },
)(ContextMenu);
