import * as Immutable from 'immutable';

import { CoordinatePoint } from 'types/point';
import { distance } from 'helpers/geometry';

/**
 * get closest (to specific point) point on the line-segment
 */
export const getClosestWallPoint = (p: CoordinatePoint, s1: CoordinatePoint, s2: CoordinatePoint): CoordinatePoint => {
  const d = distance(s1, s2);
  if (d === 0) {
    return s1;
  }
  let t = ((p.x - s1.x) * (s2.x - s1.x) + (p.y - s1.y) * (s2.y - s1.y)) / (d * d);
  t = Math.max(0, Math.min(1, t));
  return {
    x: s1.x + t * (s2.x - s1.x),
    y: s1.y + t * (s2.y - s1.y),
  };
};

/**
 * distance to wall (i.e. from point to segment)
 * https://stackoverflow.com/questions/563198/how-do-you-detect-where-two-line-segments-intersect
 */
export const distanceToWall = (wallPoints: Immutable.List<CoordinatePoint>, p: CoordinatePoint): number => {
  const s1 = wallPoints.get(0)!;
  const s2 = wallPoints.get(1)!;
  const pNearest = getClosestWallPoint(p, s1, s2);

  return distance(p, pNearest);
};
