import {
  all, takeLatest, select, put,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as Immutable from 'immutable';
import { createAction, ActionType } from 'typesafe-actions';

import { messages } from 'config/messages';
import { Point } from 'types/point';
import { distance } from 'helpers/geometry';
import { parseString } from 'helpers/draw/parseDrawInput';
import { getCenterPoint } from 'helpers/viewport';
import { actions as pointsActions } from 'ducks/model/points';
import { selectors as wallsSelectors } from 'ducks/model/walls';
import { selectors as modelSelectors } from 'ducks/model/model';
import { actions as settingsActions, selectors as settingsSelectors } from 'ducks/settings';
import { UsSize, feetToPixels, metersToPixels } from 'helpers/pixelsToDistance';
import { compose } from 'helpers/utils';

// Action Creators
export const actions = {
  resizeSketch: createAction(
    'resizeSketch/resizeSketch',
    (wallId: string, newSize: string) => ({ wallId, newSize }),
  )(),

  rescaleGrid: createAction(
    'rescaleGrid/rescaleGrid',
    (wallId: string, newSize: string) => ({ wallId, newSize }),
  )(),
};

export type Actions = ActionType<typeof actions>

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doResizeSketch(action: ReturnType<typeof actions.resizeSketch>) {
    const { wallId, newSize } = action.payload;

    const wallPoints: Immutable.List<Point> = wallsSelectors.getWallPoints(yield select(), wallId);
    const wallLengthInPixels = distance(wallPoints.get(0)!, wallPoints.get(1)!); // counting on wall having 2 points

    const uom = settingsSelectors.getUnitOfMeasure(yield select());
    const currentGridSize = settingsSelectors.getGridSizeInFeet(yield select());

    const newSizeInPixels = uom === 'meters' ? metersToPixels(+newSize, currentGridSize) : compose(
      ({ feet, inches }: UsSize) => feetToPixels(feet, inches, currentGridSize),
      parseString,
    )(newSize);

    const ratio = newSizeInPixels / wallLengthInPixels;
    if (ratio <= 0) {
      toast.error(messages.cannotResizeSketch);
      return;
    }

    // get all connected points with the wall
    const firstPoint = wallPoints.get(0)!;
    const points = modelSelectors.getConnectedAndInnerPoints(yield select(), firstPoint.pointId);

    const newPoints = points.toArray().map((point) => ({
      ...point,
      x: point.x * ratio,
      y: point.y * ratio,
    }), []);

    const oldCenter = getCenterPoint(points.toArray());
    const newCenter = getCenterPoint(newPoints);

    yield all(newPoints.map(point => put(pointsActions.update({
      ...point,
      x: point.x + (oldCenter.x - newCenter.x),
      y: point.y + (oldCenter.y - newCenter.y),
    }))));
  }

  function* doRescaleGrid(action: ReturnType<typeof actions.rescaleGrid>) {
    const { newSize } = action.payload;

    const currentGridSize = settingsSelectors.getGridSizeInFeet(yield select());
    yield put(settingsActions.setGridSize(currentGridSize * +newSize));
  }

  return function* saga() {
    yield all([
      takeLatest(actions.resizeSketch, doResizeSketch),
      takeLatest(actions.rescaleGrid, doRescaleGrid),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
