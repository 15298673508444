/** @jsx jsx */
/* eslint-disable no-nested-ternary,consistent-return */
import React, {
  useRef, useState, useLayoutEffect, ReactNode, useEffect,
} from 'react';
import ReactDOM from 'react-dom';
import posed from 'react-pose';
import { css, SerializedStyles, jsx } from '@emotion/react';
import styled from '@emotion/styled';

import { MetaDataLabel } from 'types/metaDataLabel';
import { Animation } from 'components/animations';
import { UnreachableCaseError } from 'helpers/UnreachableCaseError';
import { v4 as uuidv4 } from 'uuid';

const RIBBON_SIZE = 7;
const ANIM_OFFSET = 10;
const DELAY = 500;
const DURATION = 250;

type Orientation = 'left' | 'top' | 'right' | 'bottom';

interface TooltipProps {
  readonly children: ReactNode;
  readonly orientation: Orientation;
  readonly position: DOMRect;
}

interface TooltipOrientation {
  readonly orientation: Orientation;
}

interface TooltipContentProps {
  readonly title: string;
  readonly description?: string;
  readonly shortcut?: string;
}

interface MetaDataTooltipContentProps {
  readonly meta: MetaDataLabel;
}

interface SketchDataToolTipContentProps {
  readonly title: string;
}

export interface WithTooltipProps {
  readonly children?: ReactNode;
  readonly tooltip?: ReactNode;
  readonly tooltipPosition?: Orientation;
  readonly tooltipHide?: boolean;
  readonly tooltipShow?: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly [x: string]: any;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function getPositionStyles({ orientation, position }: TooltipProps) {
  switch (orientation) {
    case 'left':
      return {
        top: position.top + position.height / 2,
        left: position.right + RIBBON_SIZE,
      };
    case 'right':
      return {
        top: position.top + position.height / 2,
        right: window.innerWidth - position.left + RIBBON_SIZE,
      };
    case 'top':
      return {
        top: position.bottom + RIBBON_SIZE,
        left: position.left + position.width / 2,
      };
    case 'bottom':
      return {
        bottom: window.innerHeight - position.top + RIBBON_SIZE,
        left: position.left + position.width / 2,
      };
    default:
      throw new UnreachableCaseError(orientation);
  }
}

const tooltipAnimation = {
  exit: {
    opacity: 0,
    x: ({ orientation }: TooltipOrientation) => (orientation === 'left' ? -ANIM_OFFSET : orientation === 'right' ? ANIM_OFFSET : '-50%'),
    y: ({ orientation }: TooltipOrientation) => (orientation === 'top' ? -ANIM_OFFSET : orientation === 'bottom' ? ANIM_OFFSET : '-50%'),
  },
  enter: {
    opacity: 1,
    x: ({ orientation }: TooltipOrientation) => (orientation === 'left' || orientation === 'right' ? 0 : '-50%'),
    y: ({ orientation }: TooltipOrientation) => (orientation === 'top' || orientation === 'bottom' ? 0 : '-50%'),
    transition: {
      delay: DELAY,
      duration: DURATION,
    },
  },
};

const topArrowStyle = css`
  left: 50%;
  top: -${RIBBON_SIZE * 2}px;
  transform: translateX(-50%);
  border-bottom-color: #0e1431;
`;

const bottomArrowStyle = css`
  left: 50%;
  bottom: -${RIBBON_SIZE * 2}px;
  transform: translateX(-50%);
  border-top-color: #0e1431;
`;

const leftArrowStyle = css`
  left: -${RIBBON_SIZE * 2}px;
  top: 50%;
  transform: translateY(-50%);
  border-right-color: #0e1431;
`;

const rightArrowStyle = css`
  right: -${RIBBON_SIZE * 2}px;
  top: 50%;
  transform: translateY(-50%);
  border-left-color: #0e1431;
`;

const getArrowPositionStyles = ({ orientation }: TooltipProps): SerializedStyles => {
  switch (orientation) {
    case 'left':
      return leftArrowStyle;
    case 'right':
      return rightArrowStyle;
    case 'top':
      return topArrowStyle;
    case 'bottom':
      return bottomArrowStyle;
    default:
      throw new UnreachableCaseError(orientation);
  }
};

export const TooltipContent = ({ title, description, shortcut }: TooltipContentProps): JSX.Element => (
  <>
    <h5>
      {title}
      {shortcut && <span>{shortcut}</span>}
    </h5>
    {description && <p>{description}</p>}
  </>
);

export const MetaDataTooltipContent = ({ meta }: MetaDataTooltipContentProps): JSX.Element => (
  <>
    <h5>Meta Data</h5>
    {meta && Object.entries(meta).map((label) => (
      <div key={uuidv4()}>
        <h6 css={{ marginBottom: 0, marginTop: 4 }}>{label[0]}</h6>
        {label[1] ? (<p css={{ marginTop: 0 }}>{label[1]}</p>) : (<p css={{ marginTop: 0 }}>n/a</p>)}
      </div>
    ))}
  </>
);

export const SketchDataToolTipContent = ({ title }: SketchDataToolTipContentProps): JSX.Element => (
  <>
    <h5 css={{ marginBottom: 0, marginTop: 4 }}>{title}</h5>
  </>
);

export const TooltipPosed = posed.div(tooltipAnimation);

const TooltipStyled = styled(TooltipPosed) <TooltipProps>`
  position: fixed;
  max-width: 180px;
  padding: 20px;
  color: #fff;
  background-color: #0e1431;
  box-shadow: 0 3px 6px rgba(26, 36, 79, 0.2);
  pointer-events: none;
  ${getPositionStyles}

  &::after {
    content: '';
    position: absolute;
    display: block;
    border: ${RIBBON_SIZE}px solid transparent;
    ${getArrowPositionStyles}
  }

  h5 {
    margin: 0;
    font-size: 14px;

    span {
      float: right;
      padding-left: 1em;
    }
  }

  p {
    margin-bottom: 0;
    font-size: 10px;
  }
`;

const Tooltip: React.FC<TooltipProps & { show: boolean }> = ({ children, show, ...props }) => ReactDOM.createPortal(
  <Animation flipMove={false}>
    {show && (
      <TooltipStyled key="tooltip" {...props}>
        {children}
      </TooltipStyled>
    )}
  </Animation>,
  document.getElementById('tooltip-root') || document.body,
);

function useHoverWithPosition(hide?: boolean): [React.RefObject<HTMLElement>, boolean, DOMRect] {
  const [hover, setValue] = useState(false);
  const [position, setPosition] = useState();
  const ref = useRef<HTMLElement>(null);
  const { current } = ref;

  useLayoutEffect(() => {
    const node = ref.current;
    if (node) {
      const handleMouseOver = (): void => {
        setPosition(node.getBoundingClientRect());
        setValue(true);
      };
      const handleMouseOut = (): void => {
        node.addEventListener('mouseenter', handleMouseOver);
        setValue(false);
      };
      const handleMouseDown = (): void => {
        node.removeEventListener('mouseenter', handleMouseOver);
        setValue(false);
      };

      setPosition(node.getBoundingClientRect());
      node.addEventListener('mouseenter', handleMouseOver);
      node.addEventListener('mouseleave', handleMouseOut);
      node.addEventListener('mousedown', handleMouseDown);

      return () => {
        node.removeEventListener('mouseenter', handleMouseOver);
        node.removeEventListener('mouseleave', handleMouseOut);
        node.removeEventListener('mousedown', handleMouseDown);
      };
    }
  }, [current]);

  useEffect(() => {
    if (hide) setValue(false);
  }, [hide]);

  return [ref, hover, position];
}

export const withTooltip = <P extends WithTooltipProps>(Component: React.ComponentType<P>): React.ComponentType<WithTooltipProps & P> => ({
  children, tooltip, tooltipPosition = 'left', tooltipHide, tooltipShow, ...rest
}: WithTooltipProps) => {
  const [targetRef, isHovered, position] = useHoverWithPosition(tooltipHide);

  return (
    <Component ref={targetRef} {...(rest as P)}>
      {tooltip && (
        <Tooltip show={(isHovered || !!tooltipShow) && !tooltipHide} position={position} orientation={tooltipPosition}>
          {tooltip}
        </Tooltip>
      )}
      {children}
    </Component>
  );
};
