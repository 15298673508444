import * as Immutable from 'immutable';

import { RootState } from 'reducers/rootReducer';
import { PayloadAction } from 'types/payloadAction';
import { Wall, WallChange, CircleWall } from 'types/wall';
import { Point } from 'types/point';
import * as wallPointsHelper from 'helpers/model/wallPoints';
import { selectors as pointsSelectors } from 'ducks/model/points';
import { WallType } from 'types/wallType';

// Action Types
const NAME = 'walls';

export const ADD = `${NAME}/ADD`;
const ADD_CIRCLE = `${NAME}/ADD_CIRCLE`;
const UPDATE = `${NAME}/UPDATE`;
export const REMOVE = `${NAME}/REMOVE`;
const CLEAR = `${NAME}/CLEAR`;

export interface WallsState {
  readonly walls: Immutable.Map<string, Wall | CircleWall>;
}

// Initial State
const initialState: WallsState = {
  walls: Immutable.Map<string, Wall>(),
};

// Action Creators
export const actions = {
  add: (wall: Wall) => ({
    type: ADD,
    payload: wall,
  }),

  addCircle: (wall: CircleWall) => ({
    type: ADD_CIRCLE,
    payload: wall,
  }),

  update: (wallId: string, newValues: WallChange) => ({
    type: UPDATE,
    payload: { wallId, newValues },
  }),

  remove: (wallId: string) => ({
    type: REMOVE,
    payload: wallId,
  }),

  clear: () => ({
    type: CLEAR,
  }),
};

// Selectors
const getWallsState = (rootState: RootState): WallsState => rootState.walls;

const getAllWalls = (
  rootState: RootState,
): Immutable.Map<string, Wall | CircleWall> => getWallsState(rootState).walls;

const getWallsIds = (
  rootState: RootState,
): string[] => getAllWalls(rootState).keySeq().toArray();

const getWallById = (
  rootState: RootState,
  wallId: string,
): Wall | CircleWall => getAllWalls(rootState).get(wallId)!;

const getWallPoints = (rootState: RootState, wallId: string): Immutable.List<Point> => {
  const wall = getWallById(rootState, wallId);
  const points = pointsSelectors.getAllPoints(rootState);
  return wallPointsHelper.getWallPoints(points, wall);
};

const getArcWallByArcPointId = (rootState: RootState, pointId: string): Wall => {
  const walls = getAllWalls(rootState);
  return walls.find(wall => wall.points.length > 2 && wall.points[2] === pointId)!;
};

const getWallsConnectedToWall = (rootState: RootState, wallId: string): string[] => {
  const allWalls = getAllWalls(rootState);
  const mainWall: Wall = getWallById(rootState, wallId);
  return [
    mainWall.wallId,
    ...wallPointsHelper.getWallsWithPoint(allWalls, mainWall.points[0]),
    ...wallPointsHelper.getWallsWithPoint(allWalls, mainWall.points[1]),
  ];
};

const getWallsConnectedToPoint = (
  rootState: RootState, pointId: string,
): string[] => wallPointsHelper.getWallsWithPoint(getAllWalls(rootState), pointId);

export const selectors = {
  getAllWalls,
  getWallsIds,
  getWallById,
  getWallPoints,
  getArcWallByArcPointId,
  getWallsConnectedToWall,
  getWallsConnectedToPoint,
};

interface UpdateWallPayload {
  readonly wallId: string;
  readonly newValues: {
    readonly wallType: WallType;
    readonly points: string[];
  };
}

// Reducers
const addWallReducer = (state: WallsState, wall: Wall): WallsState => ({
  ...state,
  walls: state.walls.set(wall.wallId, wall),
});

const addCircleWallReducer = (state: WallsState, wall: CircleWall): WallsState => ({
  ...state,
  walls: state.walls.set(wall.wallId, wall),
});

const updateWallReducer = (state: WallsState, payload: UpdateWallPayload): WallsState => {
  const { wallId, newValues } = payload;
  const oldValues: Wall = state.walls.get(wallId)!;
  return {
    ...state,
    walls: state.walls.set(wallId, {
      ...oldValues,
      ...newValues,
    }),
  };
};

const removeWallReducer = (state: WallsState, wallId: string): WallsState => ({
  ...state,
  walls: state.walls.remove(wallId),
});

const clearWallReducer = (state: WallsState): WallsState => ({
  ...state,
  walls: Immutable.Map<string, Wall | CircleWall>(),
});

export const reducer = (state: WallsState = initialState, action: PayloadAction): WallsState => {
  switch (action.type) {
    case ADD:
      return addWallReducer(state, action.payload);

    case ADD_CIRCLE:
      return addCircleWallReducer(state, action.payload);

    case UPDATE:
      return updateWallReducer(state, action.payload);

    case REMOVE:
      return removeWallReducer(state, action.payload);

    case CLEAR:
      return clearWallReducer(state);

    default:
      return state;
  }
};
