import * as Immutable from 'immutable';
import {
  all, takeLatest, select, put, call,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { batchActions } from 'redux-batched-actions';
import { Action } from 'redux';

import { RootState } from 'reducers/rootReducer';
import { GeometryType } from 'types/geometryType';
import { PayloadAction } from 'types/payloadAction';
import { CoordinatePoint, Point } from 'types/point';
import { PositionedSymbol } from 'types/positionedSymbol';
import { PositionedLabel } from 'types/positionedLabel';
import { rotateObjectDeg, rotateObjectRad } from 'helpers/rotate/rotateObject';
import { UnreachableCaseError } from 'helpers/UnreachableCaseError';
import { getCenter } from 'helpers/geometry';
import { createSelectGroup } from 'helpers/createSelectGroup';
import { actions as editModeActions, selectors as editModeSelectors } from 'ducks/editMode';
import { selectors as modelSelectors } from 'ducks/model/model';
import { selectors as selectionSelectors } from 'ducks/selection/selection';
import {
  selectors as positionedLabelsSelectors,
  actions as positionedLabelsActions,
} from 'ducks/model/positionedLabels';
import {
  selectors as positionedSymbolsSelectors,
  actions as positionedSymbolsActions,
} from 'ducks/model/positionedSymbols';
import { selectors as figuresSelectors, actions as figureActions } from 'ducks/model/figures';
import { actions as pointsActions } from 'ducks/model/points';
import { Figure, isClosedFigure } from 'types/figure';
import { groupBy, compose, listToArray } from 'helpers/utils';

// Action Types
const NAME = 'rotateObjects';

const ROTATE = `${NAME}/ROTATE`;
const UPDATE_ROTATE = `${NAME}/UPDATE_ROTATE`;
export const END_ROTATE = `${NAME}/END_ROTATE`;
const SET_FIGURE_POINTS = `${NAME}/SET_FIGURE_POINTS`;
const SET_CENTER_POINT = `${NAME}/SET_CENTER_POINT`;

export interface RotateState {
  readonly startPoint: CoordinatePoint | null; // Point where user start to rotate / point of last rotation update
  readonly centerPoint: CoordinatePoint | null; // Point of rotation center
  readonly figurePoints: Immutable.List<Point> | null; // Cache for rotating figure, also serves as
  // a way to rotate only points that were inside figure on rotation start and were not caught during rotating
}

// Initial State
const initialState: RotateState = {
  startPoint: null,
  centerPoint: null,
  figurePoints: null,
};

const getObjectType = (obj: any) => {
  if (obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty('positionedSymbolId')) return 'SYMBOL';
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty('positionedLabelId')) return 'LABEL';
  }
  return obj.type;
};

const groupObjects = compose(
  groupBy(getObjectType),
  listToArray,
);

// Action Creators
export const actions = {
  rotate: (point: CoordinatePoint) => ({
    type: ROTATE,
    payload: point,
  }),
  updateRotate: (point: CoordinatePoint) => ({
    type: UPDATE_ROTATE,
    payload: point,
  }),
  endRotate: () => ({ type: END_ROTATE }),
  setFigurePoints: (figurePoints: Immutable.List<Point>) => ({
    type: SET_FIGURE_POINTS,
    payload: figurePoints,
  }),
  setCenterPoint: (centerPoint: CoordinatePoint | null) => ({
    type: SET_CENTER_POINT,
    payload: centerPoint,
  }),
};

// Selectors
const getRotateState = (rootState: RootState): RotateState => rootState.rotate;

const getStartPoint = (rootState: RootState): CoordinatePoint | null => getRotateState(rootState).startPoint;

const getCenterPoint = (rootState: RootState): CoordinatePoint | null => getRotateState(rootState).centerPoint;

const getFigurePoints = (rootState: RootState): Immutable.List<Point> | null => getRotateState(rootState).figurePoints;

export const selectors = {
  getStartPoint,
  getCenterPoint,
};

// Reducers
const startRotateReducer = (
  state: RotateState,
  centerPoint: CoordinatePoint,
  startPoint: CoordinatePoint,
): RotateState => ({
  ...state,
  centerPoint,
  startPoint,
});

const updateRotateReducer = (state: RotateState, startPoint: CoordinatePoint): RotateState => ({
  ...state,
  startPoint,
});

const endRotateReducer = (state: RotateState): RotateState => ({
  ...state,
  startPoint: null,
  centerPoint: null,
  figurePoints: null,
});

const setFigurePointsReducer = (state: RotateState, figurePoints: Immutable.List<Point>): RotateState => ({
  ...state,
  figurePoints,
});

const setCenterPointReducer = (state: RotateState, centerPoint: Point): RotateState => ({
  ...state,
  centerPoint,
});

export const reducer = (state: RotateState = initialState, action: PayloadAction): RotateState => {
  switch (action.type) {
    case getType(editModeActions.switchToRotating):
      return startRotateReducer(state, action.payload.center, action.payload.point);

    case UPDATE_ROTATE:
      return updateRotateReducer(state, action.payload);

    case END_ROTATE:
      return endRotateReducer(state);

    case SET_FIGURE_POINTS:
      return setFigurePointsReducer(state, action.payload);

    case SET_CENTER_POINT:
      return setCenterPointReducer(state, action.payload);

    default:
      return state;
  }
};

const updatePointRotation = (point: Point, rotationRad: number, center: CoordinatePoint) => {
  const s = Math.sin(rotationRad);
  const c = Math.cos(rotationRad);
  const x = point.x - center.x;
  const y = point.y - center.y;

  return {
    ...point,
    x: (x * c - y * s) + center.x,
    y: (x * s + y * c) + center.y,
  };
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doSingleObjectRotate(
    endPoint: CoordinatePoint,
    startPoint: CoordinatePoint,
    centerPoint: CoordinatePoint,
    rootState: RootState,
    selectedObjects: Immutable.List<string>,
  ) {
    if (selectedObjects.size !== 1) return;

    const d1 = { x: startPoint.x - centerPoint.x, y: startPoint.y - centerPoint.y };
    const d2 = { x: endPoint.x - centerPoint.x, y: endPoint.y - centerPoint.y };

    const rotationDeg = rotateObjectDeg(d2) - rotateObjectDeg(d1);
    const rotationRad = rotateObjectRad(d2) - rotateObjectRad(d1);
    const updateActions: Action[] = [];

    const objectId: string = selectedObjects.get(0)! as string;
    const objectType: GeometryType = modelSelectors.getGeometryType(rootState, objectId);

    switch (objectType) {
      case GeometryType.POSITIONED_LABEL: {
        const positionedLabel: PositionedLabel = positionedLabelsSelectors
          .getPositionedLabelById(rootState, objectId);
        updateActions.push(positionedLabelsActions.update({
          ...positionedLabel,
          rotation: positionedLabel.rotation! + rotationDeg,
        }));
        break;
      }

      case GeometryType.POINT: {
        const figure: Figure | undefined = figuresSelectors.getFigureByAreaLabelPosition(rootState, objectId);

        if (figure && isClosedFigure(figure) && figure!.positionedAreaLabel) {
          updateActions.push(figureActions.update(figure!.figureId, {
            ...figure,
            positionedAreaLabel: {
              ...figure.positionedAreaLabel,
              rotation: figure.positionedAreaLabel.rotation! + rotationDeg,
            },
          }));
        }
        break;
      }

      case GeometryType.POSITIONED_SYMBOL: {
        const positionedSymbol: PositionedSymbol = positionedSymbolsSelectors
          .getPositionedSymbolById(rootState, objectId);
        updateActions.push(positionedSymbolsActions.update({
          ...positionedSymbol,
          rotation: positionedSymbol.rotation! + rotationDeg,
        }));
        break;
      }

      case GeometryType.FIGURE: {
        let points = getFigurePoints(rootState);
        if (!points) {
          const figurePoints = figuresSelectors.getFigurePoints(rootState, objectId);
          points = modelSelectors.getConnectedAndInnerPoints(rootState, figurePoints.get(0)!.pointId);
        }

        const center = getCenter(points);

        const updatedPoints = points.map((point) => updatePointRotation(point, rotationRad, center));

        yield put(actions.setFigurePoints(updatedPoints));

        updatedPoints.forEach((point) => {
          updateActions.push(pointsActions.update(point));
        });

        break;
      }

      case GeometryType.WALL:
      case GeometryType.UNKNOWN:
        break;

      default: throw new UnreachableCaseError(objectType);
    }

    // eslint-disable-next-line consistent-return
    return updateActions;
  }

  function* doMultiSelectRotate(
    endPoint: CoordinatePoint,
    startPoint: CoordinatePoint,
    centerPoint: CoordinatePoint,
    rootState: RootState,
    selectedObjects: Immutable.List<string>
  ) {
    if (selectedObjects.size <= 1) return;

    const points: Immutable.List<Point> = selectionSelectors.getSelectedPoints(rootState);
    const updateActions: Action[] = [];

    let selectGroupBounds;
    let selectGroupPolygon;

    if (!centerPoint) {
      selectGroupBounds = createSelectGroup(points);
      const {
        topLeft, bottomLeft, topRight, bottomRight,
      } = selectGroupBounds;
      selectGroupPolygon = Immutable.List([topLeft, bottomLeft, topRight, bottomRight]);
      const selectGroupCenter = getCenter(selectGroupPolygon);

      yield put(actions.setCenterPoint(selectGroupCenter));
    }

    if (!startPoint || !centerPoint) {
      return;
    }

    const d1 = { x: startPoint.x - centerPoint.x, y: startPoint.y - centerPoint.y };
    const d2 = { x: endPoint.x - centerPoint.x, y: endPoint.y - centerPoint.y };

    const rotationRad = rotateObjectRad(d2) - rotateObjectRad(d1);

    const objects = selectedObjects.map(objectId => modelSelectors.getModelObject(rootState, objectId as string));

    const sortedObjects = groupObjects(objects);

    if (sortedObjects.BASE?.length) {
      for (let i = 0; i < sortedObjects.BASE?.length; i++) {
        const updatedPoints = points.map((point) => updatePointRotation(point, rotationRad, centerPoint));
        yield put(actions.setFigurePoints(updatedPoints));

        updatedPoints.forEach((point) => {
          updateActions.push(pointsActions.update(point));
        });
      }
    }

    if (sortedObjects.INTERIOR?.length) {
      for (let i = 0; i < sortedObjects.INTERIOR.length; i++) {
        const updatedPoints = points.map((point) => updatePointRotation(point, rotationRad, centerPoint));
        yield put(actions.setFigurePoints(updatedPoints));

        updatedPoints.forEach((point) => {
          updateActions.push(pointsActions.update(point));
        });
      }
    }

    if (sortedObjects.LABEL?.length) {
      // eslint-disable-next-line array-callback-return, consistent-return
      const updatedPoints = sortedObjects.LABEL.map((label: PositionedLabel) => {
        let point;

        if (label && 'pointId' in label) {
          point = modelSelectors.getModelObject(rootState, label.pointId);

          if (point && 'x' in point && 'y' in point) return updatePointRotation(point, rotationRad, centerPoint);
        }
      });

      updatedPoints.forEach((point: Point) => {
        if (point) updateActions.push(pointsActions.update(point));
      });
    }

    if (sortedObjects.SYMBOL?.length) {
      // eslint-disable-next-line array-callback-return, consistent-return
      const updatedPoints = sortedObjects.SYMBOL.map((symbol: PositionedSymbol) => {
        let point;

        if (symbol && 'pointId' in symbol) {
          point = modelSelectors.getModelObject(rootState, symbol.pointId);

          if (point && 'x' in point && 'y' in point) return updatePointRotation(point, rotationRad, centerPoint);
        }
      });

      updatedPoints.forEach((point: Point) => {
        if (point) updateActions.push(pointsActions.update(point));
      });
    }

    // eslint-disable-next-line consistent-return
    return updateActions;
  }

  function* handleRotate({ payload }: ReturnType<typeof actions.rotate>) {
    const rootState: RootState = yield select();
    const selectedObjects: Immutable.List<string> = editModeSelectors.getSelectedObjects(rootState);

    const endPoint: CoordinatePoint = payload;
    const startPoint: CoordinatePoint | null = yield selectors.getStartPoint(rootState);
    const centerPoint: CoordinatePoint | null = selectors.getCenterPoint(rootState);

    if (!startPoint || !centerPoint) return;

    /*
      This 'all' will return an array of actions from the doSingleObjectRotate and doMultiSelectRotate sagas.
      If anything goes wrong with the sagas(e.g. invalid args), they will return 'undefined'.
      We'll filter out these 'undefined' and, if any actions are left, we're good to continue on.
    */
    const rotationActions: any[] = yield all([
      call(doSingleObjectRotate, endPoint, startPoint, centerPoint, rootState, selectedObjects),
      call(doMultiSelectRotate, endPoint, startPoint, centerPoint, rootState, selectedObjects),
    ]);

    const filteredRotationActions = rotationActions.filter((action: Action) => action !== undefined)[0];

    if (filteredRotationActions.length) {
      yield put(batchActions([
        ...filteredRotationActions,
        actions.updateRotate(endPoint),
      ]));
    }
  }

  function* endRotate() {
    const selectedObjects: Immutable.List<string> = editModeSelectors.getSelectedObjects(yield select());
    yield put(batchActions([
      editModeActions.switchToSelected(selectedObjects.toArray()),
      actions.setCenterPoint(null),
    ]));
  }

  return function* saga() {
    yield all([
      takeLatest(ROTATE, handleRotate),
      takeLatest(END_ROTATE, endRotate),
    ]);
  };
};
/* eslint-disable @typescript-eslint/explicit-function-return-type */
