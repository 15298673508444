import {
  put, all, select, call,
} from 'redux-saga/effects';
import * as Immutable from 'immutable';

import { Wall } from 'types/wall';
import { Figure, isClosedFigure } from 'types/figure';
import { SelectableObjects } from 'types/selection';
import { findPath } from 'helpers/path/findPath';
import { removeSharedWall as removeSharedWallHelper } from 'helpers/remove/removeSharedWall';
import { getFiguresWithWall, isSharedWallForClosedFigures } from 'helpers/model/figureWalls';
import { getWallsWithPoint, getCommonPoint, getNonCommonPoint } from 'helpers/model/wallPoints';
import { removeFigure } from 'ducks/remove/removeFigure';
import { actions as pointsActions } from 'ducks/model/points';
import { selectors as modelSelectors } from 'ducks/model/model';
import { selectors as wallsSelectors, actions as wallsActions } from 'ducks/model/walls';
import { selectors as figuresSelectors, actions as figuresActions } from 'ducks/model/figures';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
function* removeSharedWall(wallId: string) {
  const selectableObjects: SelectableObjects = modelSelectors.getSelectableObjects(yield select());
  const result = removeSharedWallHelper(selectableObjects, wallId);

  yield all(result.updatedFigures.map(figure => put(figuresActions.update(
    figure.figureId, { walls: figure.walls },
  ))));
  yield all(result.removedFigures.map(figureId => call(removeFigure, figureId)));
}

// if figure was closed (open it)
function* removeClosedFigureWall(wall: Wall, figure: Figure, walls: Immutable.Map<string, Wall>) {
  const figureWalls = walls.filter((wallIt: Wall) => figure.walls.includes(wallIt.wallId));
  const path = findPath(figureWalls, wall.points[0], wall.points[1], [wall.wallId]);
  if (path.length) {
    const areaLabelPosition = figuresSelectors.getAreaLabelPosition(yield select(), figure.figureId);
    if (areaLabelPosition) {
      yield put(pointsActions.remove(areaLabelPosition.pointId));
    }

    yield put(figuresActions.openFigure(figure.figureId, {
      walls: path,
    }));
  }
}

// if figure was non-closed path
function* removeOpenFigureWall(wall: Wall, figure: Figure, walls: Immutable.Map<string, Wall>) {
  const wallIndex = figure.walls.findIndex(wId => wId === wall.wallId);
  if (wallIndex !== 0 && wallIndex !== figure.walls.length - 1) { // user try to remove wall in the middle of the path
    // let's extend previous wall
    const prevWallId = figure.walls[wallIndex - 1];
    const pointId = getCommonPoint(walls, prevWallId, wall.wallId);
    const newPointId = getNonCommonPoint(walls, prevWallId, wall.wallId);
    const wallsWithPoint = getWallsWithPoint(walls, pointId);
    yield all(wallsWithPoint.map((wId) => {
      const otherWall = walls.get(wId)!;
      const newPoints = otherWall.points.map(pId => (pId !== pointId) ? pId : newPointId);
      return put(wallsActions.update(otherWall.wallId, {
        points: newPoints,
      }));
    }));
  }

  yield put(figuresActions.removeWall(figure.figureId, wall.wallId));
  if (figure.walls.length <= 1) {
    yield call(removeFigure, figure.figureId);
  }
}

export function* removeWall(wallId: string) {
  const walls = wallsSelectors.getAllWalls(yield select());
  const wall: Wall = wallsSelectors.getWallById(yield select(), wallId);
  const figures: Immutable.Map<string, Figure> = figuresSelectors.getAllFigures(yield select());
  const figuresWithWallIds = getFiguresWithWall(figures, wallId);
  if (isSharedWallForClosedFigures(figures, figuresWithWallIds)) {
    yield call(removeSharedWall, wallId);
    return;
  }
  for (let i = 0; i < figuresWithWallIds.length; i++) {
    const figure = figures.get(figuresWithWallIds[i])!;
    if (isClosedFigure(figure)) {
      yield call(removeClosedFigureWall, wall, figure, walls);
    } else {
      yield call(removeOpenFigureWall, wall, figure, walls);
    }
  }

  yield put(wallsActions.remove(wallId));

  const updatedWalls = wallsSelectors.getAllWalls(yield select());
  const nonSharedPoints: string[] = wall.points.filter(
    pointId => getWallsWithPoint(updatedWalls, pointId).length === 0,
  );
  yield all(nonSharedPoints.map(pointId => put(pointsActions.remove(pointId))));
}
/* eslint-enable @typescript-eslint/explicit-function-return-type */
