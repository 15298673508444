/** @jsx jsx */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import * as Immutable from 'immutable';
import { css, jsx } from '@emotion/react';

import { colors } from 'config/paletteConfig';
import { RootState } from 'reducers/rootReducer';
import { Point, CoordinatePoint } from 'types/point';
import { Figure } from 'types/figure';
import { Wall, CircleWall } from 'types/wall';
import { AreaType } from 'types/areaType';
import { Precision } from 'types/precision';
import { getFigureAreaType, getGlaRowData } from 'helpers/label/areaTypeLabel';
import { selectors as areaTypesSelectors } from 'ducks/model/areaTypes';
import { selectors as figuresSelectors } from 'ducks/model/figures';
import { selectors as settingsSelectors } from 'ducks/settings';
import { ReactComponent as IconSlash } from 'assets/icons/slash.svg';
import { ReactComponent as IconCheck } from 'assets/icons/check.svg';

const subAreaFigureStyling = css({
  paddingLeft: '50px'
});

interface InputProps {
  readonly isGrouped?: boolean;
  readonly figure: Figure;
  readonly walls?: Immutable.Map<string, Wall | CircleWall>;
}

interface StateProps {
  readonly figurePoints?: Immutable.List<Point | CoordinatePoint>;
  readonly areaType?: AreaType;
  readonly precision: Precision;
  readonly unitOfMeasure: string;
  readonly gridSizeInFeet: number;
}

type Props = InputProps & StateProps;

export const EmptyRow = (): JSX.Element => (
  <tr>
    <td>–</td>
    <td>–</td>
    <td>–</td>
  </tr>
);

const GlaBreakdownRow: React.FC<Props> = ({
  isGrouped, figure, figurePoints, areaType, walls, precision, unitOfMeasure, gridSizeInFeet,
}) => {
  const rowData = getGlaRowData({
    figure, figurePoints, areaType, walls, precision, unitOfMeasure, gridSizeInFeet,
  });

  return (
    <Fragment>
      {rowData
        ? (
          <tr>
            <td>
              <div css={isGrouped ? subAreaFigureStyling : null}>
                {`${rowData.areaTypeText}`}
              </div>
            </td>
            <td>{rowData.areaSizeText}</td>
            <td>
              <Fragment>
                {(!rowData.isIncludedInGla) ? (
                  <IconSlash css={{ color: colors.red }} />
                ) : (
                  <IconCheck css={{ color: colors.green }} />
                )}
                {rowData.isGlaText}
              </Fragment>
            </td>
          </tr>
        ) : <EmptyRow />}
    </Fragment>
  );
};

export default connect(
  (state: RootState, { figure }: InputProps): StateProps => ({
    precision: settingsSelectors.getPrecision(state),
    unitOfMeasure: settingsSelectors.getUnitOfMeasure(state),
    gridSizeInFeet: settingsSelectors.getGridSizeInFeet(state),
    figurePoints: figuresSelectors.getFigurePointsForDraw(state, figure.figureId),
    areaType: areaTypesSelectors.getAreaTypeByMaybeId(state, getFigureAreaType(figure)),
  }),
)(GlaBreakdownRow);
