import * as Immutable from 'immutable';

import { CoordinatePoint } from 'types/point';

/**
 * find non-closed figure with first or last point
 */
export const findAlignedPoints = <T extends CoordinatePoint>(
  wallPointsList: Immutable.List<T>[], targetPoint: CoordinatePoint,
): T[] => {
  let alignedTop: T;
  let alignedLeft: T;
  let alignedBottom: T;
  let alignedRight: T;
  wallPointsList.forEach((wallPoints) => {
    wallPoints.forEach((point) => {
      if (point.x === targetPoint.x) {
        if (point.y < targetPoint.y && (!alignedTop || alignedTop.y < point.y)) {
          alignedTop = point;
        } else if (point.y > targetPoint.y && (!alignedBottom || alignedBottom.y > point.y)) {
          alignedBottom = point;
        }
      } else if (point.y === targetPoint.y) {
        if (point.x < targetPoint.x && (!alignedLeft || alignedLeft.x < point.x)) {
          alignedLeft = point;
        } else if (point.x > targetPoint.x && (!alignedRight || alignedRight.x > point.x)) {
          alignedRight = point;
        }
      }
    });
  });

  const alignedPoints = [alignedTop!, alignedRight!, alignedBottom!, alignedLeft!].filter(x => x);

  return alignedPoints;
};
