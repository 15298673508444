import * as Immutable from 'immutable';

import { geometryConfig } from 'config/geometryConfig';
import { Point, CoordinatePoint, isPoint } from 'types/point';
import { distance } from 'helpers/geometry';

/**
 * If point belongs to line or not
 * https://en.wikipedia.org/wiki/Distance_from_a_point_to_a_line
 * @param wallPoints points of the wall
 * @param p testing point
 * @returns true if points belongs to wall
 */
export const isPointToWallCollision = (wallPoints: Immutable.List<CoordinatePoint>, p: CoordinatePoint, zoomInPercent: number): boolean => {
  const extLineWidth = geometryConfig.getExteriorLineWidth(zoomInPercent);
  const p1 = wallPoints.get(0)!;
  const p2 = wallPoints.get(1)!;

  const maxX = Math.max(p1.x, p2.x) + extLineWidth;
  const minX = Math.min(p1.x, p2.x) - extLineWidth;
  if (p.x < minX || p.x > maxX) {
    return false;
  }

  const maxY = Math.max(p1.y, p2.y) + extLineWidth;
  const minY = Math.min(p1.y, p2.y) - extLineWidth;
  if (p.y < minY || p.y > maxY) {
    return false;
  }

  const length = distance(p1, p2);
  if (length === 0) {
    // infinite distance
    return false;
  }

  const dist = Math.abs((p2.y - p1.y) * p.x - (p2.x - p1.x) * p.y + p2.x * p1.y - p2.y * p1.x) / length;
  return dist <= extLineWidth;
};

/**
 * If two walls has collision or not
 * math: https://stackoverflow.com/questions/563198/how-do-you-detect-where-two-line-segments-intersect
 * src: https://github.com/pgkelley4/line-segments-intersect/blob/master/js/line-segments-intersect.js
 * @param points1 points of the 1-st wall
 * @param points2 points of the 2-nd wall
 * @returns true if points belongs to wall
 */
export const isWallToWallCollision = (
  points1: Immutable.List<CoordinatePoint>, points2: Immutable.List<CoordinatePoint>,
): boolean => {
  const a = points1.get(0)!;
  const b = points1.get(1)!;
  const c = points2.get(0)!;
  const d = points2.get(1)!;

  const denominator = (b.x - a.x) * (d.y - c.y) - (b.y - a.y) * (d.x - c.x);
  const numerator1 = (a.y - c.y) * (d.x - c.x) - (a.x - c.x) * (d.y - c.y);
  const numerator2 = (a.y - c.y) * (b.x - a.x) - (a.x - c.x) * (b.y - a.y);

  // Detect coincident lines
  if (denominator === 0) {
    if (numerator1 !== 0 || numerator2 !== 0) {
      // segments are parallel
      return false;
    }

    // well all points on the one line
    // we should just check segments intersection
    const rx = (c.x - a.x < 0 ? 1 : 0) + (c.x - b.x < 0 ? 1 : 0) + (d.x - a.x < 0 ? 1 : 0) + (d.x - b.x < 0 ? 1 : 0);

    if (rx === 0 || rx === 4) {
      return false;
    }

    const ry = (c.y - a.y < 0 ? 1 : 0) + (c.y - b.y < 0 ? 1 : 0) + (d.y - a.y < 0 ? 1 : 0) + (d.y - b.y < 0 ? 1 : 0);

    return !(ry === 0 || ry === 4);
  }

  const r = numerator1 / denominator;
  const s = numerator2 / denominator;

  return r >= 0 && r <= 1 && (s >= 0 && s <= 1);
};

/**
 * If two walls share the same point (at the end or at the begin of the wall)
 * @param points1 points of the 1-st wall
 * @param points2 points of the 2-nd wall
 * @returns true if one point is shared
 */
export const isCommonPoint = (
  points1: Immutable.List<Point | CoordinatePoint>, points2: Immutable.List<Point | CoordinatePoint>,
): boolean => {
  const a = points1.get(0)!;
  const b = points1.get(1)!;
  const c = points2.get(0)!;
  const d = points2.get(1)!;

  const aId = isPoint(a) ? a.pointId : undefined;
  const bId = isPoint(b) ? b.pointId : undefined;
  const cId = isPoint(c) ? c.pointId : undefined;
  const dId = isPoint(d) ? d.pointId : undefined;

  if (aId === cId || aId === dId) {
    return true;
  }

  return bId === cId || bId === dId;
};

export const isIdentical = (
  points1: Immutable.List<Point | CoordinatePoint>, points2: Immutable.List<Point | CoordinatePoint>,
): boolean => {
  const a = points1.get(0)!;
  const b = points1.get(1)!;
  const c = points2.get(0)!;
  const d = points2.get(1)!;

  const aId = isPoint(a) ? a.pointId : undefined;
  const bId = isPoint(b) ? b.pointId : undefined;
  const cId = isPoint(c) ? c.pointId : undefined;
  const dId = isPoint(d) ? d.pointId : undefined;

  if (aId === cId && bId === dId) {
    return true;
  }

  return !!(aId === dId && bId === cId);
};

/**
 * We need to check collisions for shared-point line too
 * some user can draw over old line
 * @param points1 points of the 1-st wall
 * @param points2 points of the 2-nd wall
 * @returns true if collision is more than one point
 */
export const isSharedPointWallsCollision = (
  points1: Immutable.List<CoordinatePoint>,
  points2: Immutable.List<CoordinatePoint>,
  zoomInPercent: number
): boolean => {
  const a = points1.get(0)!;
  const b = points1.get(1)!;
  const c = points2.get(0)!;
  const d = points2.get(1)!;

  return (isPointToWallCollision(points2, a, zoomInPercent) && isPointToWallCollision(points2, b, zoomInPercent))
    || (isPointToWallCollision(points1, c, zoomInPercent) && isPointToWallCollision(points1, d, zoomInPercent));
};
