import * as Immutable from 'immutable';
import {
  all, takeLatest, select, put, call,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';

import { RootState } from 'reducers/rootReducer';
import { GeometryType } from 'types/geometryType';
import { PayloadAction } from 'types/payloadAction';
import { Point, CoordinatePoint } from 'types/point';
import { PointType } from 'types/pointType';
import { Wall } from 'types/wall';
import { WallType } from 'types/wallType';
import { PositionedLabel } from 'types/positionedLabel';
import { PositionedSymbol } from 'types/positionedSymbol';
import { SelectableObjects } from 'types/selection';
import { PositionedAreaLabel } from 'types/positionedAreaLabel';
import { ClosedFigure } from 'types/figure';
import {
  moveAndSnapPoint, moveAndSnapWall, moveArcPoint, moveAndSnapFigure,
} from 'helpers/move/moveAndSnap';
import { movePolygon } from 'helpers/move/movePolygon';
import { movePoint } from 'helpers/move/movePoint';
import { getMoveByKey } from 'helpers/move/getMoveByKey';
import { canExtrudeWall, extrudeWall } from 'helpers/move/extrudeWall';
import { getCentroid } from 'helpers/geometry';
import { UnreachableCaseError } from 'helpers/UnreachableCaseError';
import { actions as editModeActions, selectors as editModeSelectors } from 'ducks/editMode';
import { selectors as modelSelectors } from 'ducks/model/model';
import { actions as figuresActions, selectors as figuresSelectors } from 'ducks/model/figures';
import { actions as wallsActions, selectors as wallsSelectors } from 'ducks/model/walls';
import { selectors as pointsSelectors, actions as pointsActions } from 'ducks/model/points';
import { selectors as positionedLabelsSelectors } from 'ducks/model/positionedLabels';
import { selectors as positionedSymbolsSelectors } from 'ducks/model/positionedSymbols';
import { selectors as selectionSelectors } from 'ducks/selection/selection';
import { selectors as settingsSelectors } from 'ducks/settings';

// Action Types
const NAME = 'moveObjects';

const START_MOVE = `${NAME}/START_MOVE`;
const UPDATE_MOVE = `${NAME}/UPDATE_MOVE`;
const END_MOVE = `${NAME}/END_MOVE`;
const MOVE_BY_KEYPAD = `${NAME}/MOVE_BY_KEYPAD`;
export const OBJECT_MOVED = `${NAME}/OBJECT_MOVED`;

// Action Creators
export const actions = {
  startMove: (point: CoordinatePoint) => ({
    type: START_MOVE,
    payload: point,
  }),

  updateMove: (point: CoordinatePoint) => ({
    type: UPDATE_MOVE,
    payload: point,
  }),

  endMove: (point: CoordinatePoint) => ({
    type: END_MOVE,
    payload: point,
  }),

  moveByKeypad: (key: string) => ({
    type: MOVE_BY_KEYPAD,
    payload: key,
  }),

  objectMoved: () => ({
    type: OBJECT_MOVED,
  }),
};

export interface MoveState {
  readonly startPoint: CoordinatePoint | null;
  readonly endPoint: CoordinatePoint | null;
  readonly hasMoved: boolean;
}

// Initial State
const initialState: MoveState = {
  startPoint: null,
  endPoint: null,
  hasMoved: false,
};

// Selectors
const getMoveState = (rootState: RootState): MoveState => rootState.move;

const getStartPoint = (rootState: RootState): CoordinatePoint | null => getMoveState(rootState).startPoint;

const getEndPoint = (rootState: RootState): CoordinatePoint | null => getMoveState(rootState).endPoint;

const hasMoved = (rootState: RootState): boolean => getMoveState(rootState).hasMoved;

export const selectors = {
  getStartPoint,
  getEndPoint,
  hasMoved,
};

// Reducers
const startMoveReducer = (state: MoveState, point: CoordinatePoint): MoveState => ({
  ...state,
  startPoint: point,
  endPoint: point,
  hasMoved: false,
});

const updateMoveReducer = (state: MoveState, point: CoordinatePoint, isMoveUpdate: boolean): MoveState => ({
  ...state,
  endPoint: point,
  hasMoved: state.hasMoved || isMoveUpdate,
});

export const reducer = (state: MoveState = initialState, action: PayloadAction): MoveState => {
  switch (action.type) {
    case START_MOVE:
    case getType(editModeActions.switchToMoving):
      return startMoveReducer(state, action.payload);

    case UPDATE_MOVE:
    case END_MOVE:
      return updateMoveReducer(state, action.payload, action.type === UPDATE_MOVE);

    default:
      return state;
  }
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export function* updateAreaLabel(figureId: string) {
  const areaLabel: PositionedAreaLabel | undefined = figuresSelectors.getAreaLabel(yield select(), figureId);
  if (!areaLabel || areaLabel.isMovedByUser) {
    return;
  }

  const figurePoints: Immutable.List<Point> = figuresSelectors.getFigurePoints(yield select(), figureId);

  const position = getCentroid(figurePoints);

  const point: Point = pointsSelectors.getPointById(yield select(), areaLabel.pointId);
  yield put(pointsActions.update({
    ...point,
    ...position,
  }));
}

// sagas
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createSagas = () => {
  function* updateAreaLabels(changedPoints: Immutable.List<Point>) {
    const changedFigures: string[] = selectionSelectors.getChangedFigures(yield select(), changedPoints);

    yield all(changedFigures.map(figureId => call(updateAreaLabel, figureId)));
  }

  function* moveArcPoints(changedPoints: Immutable.List<Point>) {
    const changedArcPoints: Immutable.List<Point> = selectionSelectors
      .getChangedArcPoints(yield select(), changedPoints);
    yield all(changedArcPoints.toArray().map(p => put(pointsActions.update(p))));
  }

  function* doMoveFigure(figureId: string, d: CoordinatePoint, isSnapping: boolean, alreadyMovedPoints: Set<string>): any {
    const figurePoints: Immutable.List<Point> = (yield select(
      figuresSelectors.getFigurePointsForDraw,
      figureId,
    )).filter((point: Point) => !alreadyMovedPoints.has(point?.pointId));

    figurePoints.forEach(point => alreadyMovedPoints.add(point?.pointId));

    const snapDivision: number = settingsSelectors.getPrecision(yield select());
    const changedPoints = isSnapping
      ? moveAndSnapFigure(figurePoints, d, snapDivision)
      : movePolygon(figurePoints, d.x, d.y);
    yield call(moveArcPoints, changedPoints);
    yield all(changedPoints.toArray().map(p => put(pointsActions.update(p))));

    // This bit is only necessary if the figure has an attached area label, i.e. it is not an interior wall
    const areaLabel: PositionedAreaLabel = figuresSelectors.getAreaLabel(yield select(), figureId)!;

    if (areaLabel) {
      const point: Point = pointsSelectors.getPointById(yield select(), areaLabel?.pointId);

      yield put(pointsActions.update({
        ...point,
        x: point.x + d.x,
        y: point.y + d.y,
      }));
    }
  }

  function* doMoveWall(wallId: string, d: CoordinatePoint, isSnapping: boolean, alreadyMovedPoints: Set<string>): any {
    const points: Immutable.List<Point> = (yield select(
      wallsSelectors.getWallPoints, wallId,
    )).filter((point: Point) => !alreadyMovedPoints.has(point.pointId));
    points.forEach(point => alreadyMovedPoints.add(point.pointId));

    const snapDivision = settingsSelectors.getPrecision(yield select());
    const snapped = isSnapping
      ? moveAndSnapWall(points, d, snapDivision)
      : movePolygon(points, d.x, d.y);
    yield call(moveArcPoints, snapped);
    yield all(snapped.map(p => put(pointsActions.update(p))).toArray());

    yield call(updateAreaLabels, snapped);
  }

  function* doMovePoint(pointId: string, d: CoordinatePoint, isSnapping: boolean, alreadyMovedPoints?: Set<string>) {
    if (alreadyMovedPoints && alreadyMovedPoints.has(pointId)) {
      return;
    }
    alreadyMovedPoints!.add(pointId);

    const point: Point = pointsSelectors.getPointById(yield select(), pointId);
    let moved: Point;

    if (point.pointType === PointType.ARC) {
      const wall: Wall = wallsSelectors.getArcWallByArcPointId(yield select(), pointId);
      const wallPoints: Immutable.List<Point> = wallsSelectors.getWallPoints(yield select(), wall.wallId);
      moved = moveArcPoint(wallPoints, d);
    } else {
      const snapDivision = settingsSelectors.getPrecision(yield select());
      moved = isSnapping
        ? moveAndSnapPoint(point, d, snapDivision)
        : movePoint(point, d);
    }

    yield call(moveArcPoints, Immutable.List<Point>([moved]));

    yield put(pointsActions.update(moved));

    if (point.pointType === PointType.LABEL) {
      const figure: ClosedFigure = figuresSelectors.getFigureByAreaLabelPosition(yield select(), point.pointId);
      const positionedAreaLabel = figure.positionedAreaLabel!;
      yield put(figuresActions.update(figure.figureId, {
        positionedAreaLabel: {
          ...positionedAreaLabel,
          isMovedByUser: true,
        },
      }));
    }
    yield call(updateAreaLabels, Immutable.List<Point>([moved]));
  }

  function* doMovePositionedLabel(positionedLabelId: string, d: CoordinatePoint, isSnapping: boolean) {
    const positionedLabel: PositionedLabel = positionedLabelsSelectors
      .getPositionedLabelById(yield select(), positionedLabelId);
    const point: Point = pointsSelectors.getPointById(yield select(), positionedLabel.pointId);
    const snapDivision = settingsSelectors.getPrecision(yield select());
    const snapped: Point = isSnapping
      ? moveAndSnapPoint(point, d, snapDivision)
      : movePoint(point, d);
    yield put(pointsActions.update(snapped));
  }

  function* doMovePositionedSymbol(positionedSymbolId: string, d: CoordinatePoint, isSnapping: boolean) {
    const positionedSymbol: PositionedSymbol = positionedSymbolsSelectors
      .getPositionedSymbolById(yield select(), positionedSymbolId);
    const point: Point = pointsSelectors.getPointById(yield select(), positionedSymbol.pointId);
    const snapDivision = settingsSelectors.getPrecision(yield select());
    const snapped: Point = isSnapping
      ? moveAndSnapPoint(point, d, snapDivision)
      : movePoint(point, d);
    yield put(pointsActions.update(snapped));
  }

  function* doMove(d: CoordinatePoint, defaultSnapping: boolean) {
    let isSnapping = defaultSnapping;
    const selectedObjects: Immutable.List<string> = editModeSelectors.getSelectedObjects(yield select());
    const hasMultiselected: boolean = editModeSelectors.hasMultiselected(yield select());
    const alreadyMovedPoints = new Set<string>();

    if (hasMultiselected) {
      isSnapping = false;
    }

    for (let i = 0; i < selectedObjects.size; i++) {
      const objectId = selectedObjects.get(i)!;
      const objectType: GeometryType = modelSelectors.getGeometryType(yield select(), objectId);
      switch (objectType) {
        case GeometryType.FIGURE:
          yield call(doMoveFigure, objectId, d, isSnapping, alreadyMovedPoints);
          break;

        case GeometryType.WALL: {
          const wall: Wall = wallsSelectors.getWallById(yield select(), objectId);
          if (wall.wallType === WallType.ARC) {
            isSnapping = false;
          }

          yield call(doMoveWall, objectId, d, isSnapping, alreadyMovedPoints);
          break;
        }

        case GeometryType.POINT: {
          const point: Point = pointsSelectors.getPointById(yield select(), objectId);
          if (point.pointType === PointType.LABEL) {
            isSnapping = false;
          }
          yield call(doMovePoint, objectId, d, isSnapping, alreadyMovedPoints);
          break;
        }

        case GeometryType.POSITIONED_LABEL:
          yield call(doMovePositionedLabel, objectId, d, isSnapping);
          break;

        case GeometryType.POSITIONED_SYMBOL:
          yield call(doMovePositionedSymbol, objectId, d, isSnapping);
          break;

        case GeometryType.UNKNOWN:
          break;

        default:
          throw new UnreachableCaseError(objectType);
      }
    }
    yield put(actions.objectMoved());
    yield put(editModeActions.switchToSelection());
    yield put(editModeActions.switchToSelected(selectedObjects.toArray()));
  }

  function* doEndMove({ payload }: PayloadAction) {
    const endPoint = payload as CoordinatePoint;
    const startPoint: CoordinatePoint = selectors.getStartPoint(yield select())!;
    const d = {
      x: endPoint.x - startPoint.x,
      y: endPoint.y - startPoint.y,
    };
    yield call(doMove, d, true);
  }

  function* doMoveByKeypad(action: PayloadAction) {
    const { payload } = action;
    const d: CoordinatePoint = getMoveByKey(payload);

    yield call(doMove, d, false);
  }

  function* doStartMove() {
    const selectedObjects: Immutable.List<string> = editModeSelectors.getSelectedObjects(yield select());
    if (selectedObjects.size !== 1) {
      return;
    }

    const objectId = selectedObjects.first() as string;
    const objectType: GeometryType = modelSelectors.getGeometryType(yield select(), objectId);
    if (objectType !== GeometryType.WALL) {
      return;
    }

    const selectableObjects: SelectableObjects = modelSelectors.getSelectableObjects(yield select());
    if (!canExtrudeWall(selectableObjects, objectId)) {
      return;
    }

    const result = extrudeWall(selectableObjects, objectId, [uuidv4(), uuidv4(), uuidv4(), uuidv4()]);
    yield all(result.updatedPoints.map(p => put(pointsActions.update(p))));
    yield all(result.updatedWalls.map(wall => put(wallsActions.add(wall))));
    yield all(result.updatedFigures.map(figure => (
      put(figuresActions.update(figure.figureId, { walls: figure.walls })))));
  }

  return function* saga() {
    yield all([
      takeLatest(END_MOVE, doEndMove),
      takeLatest(MOVE_BY_KEYPAD, doMoveByKeypad),
      takeLatest(editModeActions.switchToMoving, doStartMove),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
