import React, { useRef, useState, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import * as Immutable from 'immutable';

import { RootState } from 'reducers/rootReducer';
import { UnreachableCaseError } from 'helpers/UnreachableCaseError';
import { CoordinatePoint } from 'types/point';
import { Label } from 'types/label';
import { SketchSymbol } from 'types/sketchSymbol';
import { StampId } from 'types/stamp';
import { selectors as labelSelectors } from 'ducks/model/labels';
import { selectors as symbolSelectors } from 'ducks/model/symbols';

interface OwnProps extends StampId {
  cursorPosition: CoordinatePoint;
}

interface StateProps {
  readonly textLabels: Immutable.Map<string, Label>;
  readonly symbols: Immutable.Map<string, SketchSymbol>;
}

type Props = OwnProps & StateProps

const StampPreview: React.FC<Props> = ({
  cursorPosition: { x, y }, objectType, objectId, textLabels, symbols,
}) => {
  const targetRef = useRef<SVGImageElement & SVGTextElement>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (targetRef.current) {
      const { width: w, height: h } = targetRef.current.getBBox();
      if (Math.abs(w - width) >= 1) {
        setWidth(w);
      }
      if (Math.abs(h - height) >= 1) {
        setHeight(h);
      }
    }
  });

  switch (objectType) {
    case 'symbol': {
      const symbol = symbols.get(objectId);
      return (
        <image
          ref={targetRef}
          href={symbol?.data}
          x={x - width / 2}
          y={y - height / 2}
          width={44}
        />
      );
    }
    case 'label': {
      const label = textLabels.get(objectId);
      return (
        <text
          ref={targetRef}
          x={x - width / 2}
          y={y + height / 4}
        >
          {label?.text}
        </text>
      );
    }
    default: throw new UnreachableCaseError(objectType);
  }
};

export default connect(
  (state: RootState): StateProps => ({
    textLabels: labelSelectors.getTextLabels(state),
    symbols: symbolSelectors.getAllSymbols(state),
  }),
)(StampPreview);
