import * as Immutable from 'immutable';

import { Point } from 'types/point';

/**
 * move polygon to new position
 */
export const movePolygon = (
  polygon: Immutable.List<Point>, dx: number, dy: number,
): Immutable.List<Point> => polygon.map(p => ({
  ...p,
  x: p.x + dx,
  y: p.y + dy,
}));
