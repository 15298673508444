import {
  all, takeLatest, call, put,
} from 'redux-saga/effects';
import { createAction, getType, ActionType } from 'typesafe-actions';
import { toast } from 'react-toastify';

import { authConfig } from 'config/authConfig';
import { messages } from 'config/messages';
import { ApiAreaType } from 'types/api/apiAreaType';
import { LogLevel } from 'types/log';
import { mapAreaTypeFromApi } from 'helpers/mappers';
import log from 'helpers/log';
import { RootState } from 'reducers/rootReducer';
import { getAccessToken } from 'effects/auth';
import { actions as areaTypesActions } from 'ducks/model/areaTypes';
import * as ApiEffects from 'effects/api';

const NAME = 'areaTypePersistence';

export interface AreaTypePersistenceState {
  readonly isLoading: boolean;
}

// Initial State
const initialState: AreaTypePersistenceState = {
  isLoading: false,
};

// Action Creators
export const actions = {
  loadAreaTypes: createAction(`${NAME}/loadAreaTypes`)<void>(),
  setLoadingAreaTypes: createAction(`${NAME}/setLoadingAreaTypes`)<boolean>(),
};

export type Actions = ActionType<typeof actions>

// Selectors
const getState = (rootState: RootState): AreaTypePersistenceState => rootState[NAME];

const isLoading = (rootState: RootState): boolean => getState(rootState).isLoading;

export const selectors = {
  isLoading,
};

// Reducers
const setLoadingAreaTypesReducer = (
  state: AreaTypePersistenceState, loading: boolean,
): AreaTypePersistenceState => ({
  ...state,
  isLoading: loading,
});

export const reducer = (
  state: AreaTypePersistenceState = initialState, action: Actions,
): AreaTypePersistenceState => {
  switch (action.type) {
    case getType(actions.setLoadingAreaTypes):
      return setLoadingAreaTypesReducer(state, action.payload as boolean);

    default:
      return state;
  }
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doLoadAreaTypes() {
    if (authConfig.enabled) {
      yield put(actions.setLoadingAreaTypes(true));
      let response: ApiAreaType[] | null = null;
      try {
        const accessToken: string = yield call(getAccessToken);
        response = yield call(ApiEffects.getAreaTypes, accessToken);
      } catch (e) {
        toast.warning(messages.cannotLoadAreaTypes);
        log(LogLevel.error, e);
      }

      if (response) {
        yield put(areaTypesActions.set({
          clear: true,
          areaTypes: response.map(
            (area, index) => mapAreaTypeFromApi(area, index),
          ),
        }));
      }
      yield put(actions.setLoadingAreaTypes(false));
    }
  }

  return function* saga() {
    yield all([
      takeLatest(actions.loadAreaTypes, doLoadAreaTypes),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
