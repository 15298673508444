/** @jsx jsx */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as Immutable from 'immutable';
import { css, jsx } from '@emotion/react';

import { Figure } from 'types/figure';
import { Wall, CircleWall } from 'types/wall';

import { RootState } from 'reducers/rootReducer';
import { selectors as wallsSelectors } from 'ducks/model/walls';

import { ReactComponent as IconChevron } from 'assets/icons/chevron.svg';
import GlaBreakdownRow from './GlaBreakdownRow';

const subAreaContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const chevron = css`
  padding: 6px;
  cursor: pointer;
`;

const opened = css`
  transform: rotate(90deg);
`;

const buttonStyle = css`
  padding: 0;
  border: 0;
  background-color: transparent;
  transition: transform 0.2s ease;
`;

interface InputProps {
  readonly subAreaGrouping?: any;
}

interface StateProps {
  readonly walls: Immutable.Map<string, Wall | CircleWall>;
}

type Props = InputProps & StateProps;

const SubAreaBreakdownRow: React.FC<Props> = ({ subAreaGrouping, walls }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <tr key={subAreaGrouping[1].figureId}>
        <td>
          <div css={subAreaContainer}>
            <IconChevron css={[buttonStyle, chevron, isOpen ? opened : null]} onClick={() => setIsOpen(prevState => !prevState)} />
            <div>
              {`${subAreaGrouping[0]}`}
            </div>
          </div>
        </td>
        <td />
        <td />
      </tr>
      {isOpen && subAreaGrouping[1].map((figure: Figure) => (
        <GlaBreakdownRow key={figure.figureId} figure={figure} walls={walls} isGrouped />
      ))}
    </>
  );
};

export default connect(
  (state: RootState): StateProps => ({
    walls: wallsSelectors.getAllWalls(state),
  }),
)(SubAreaBreakdownRow);
