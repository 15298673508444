import * as Immutable from 'immutable';
import {
  all, takeLatest, select, put, call,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { RootState } from 'reducers/rootReducer';
import { GeometryType } from 'types/geometryType';
import { PayloadAction } from 'types/payloadAction';
import { Point, CoordinatePoint } from 'types/point';
import { PointType } from 'types/pointType';
import { Corner } from 'types/corner';
import { Resizable } from 'types/resizable';
import { Rotatable } from 'types/rotatable';
import { Dimensions } from 'types/dimensions';
import { PositionedLabel } from 'types/positionedLabel';
import { PositionedSymbol } from 'types/positionedSymbol';
import { resizeObject } from 'helpers/resize/resizeObject';
import { calculateFontScale } from 'helpers/calculateFontScale';
import { selectors as pointsSelectors, actions as pointsActions } from 'ducks/model/points';
import { actions as editModeActions, selectors as editModeSelectors } from 'ducks/editMode';
import { selectors as modelSelectors } from 'ducks/model/model';
import {
  selectors as positionedLabelsSelectors,
  actions as positionedLabelsActions,
} from 'ducks/model/positionedLabels';
import {
  selectors as positionedSymbolsSelectors,
  actions as positionedSymbolsActions,
} from 'ducks/model/positionedSymbols';
import {
  selectors as bluePrintImageSelectors,
  actions as bluePrintImageActions, BluePrintImageState,
} from 'ducks/bluePrintImage/bluePrintImage';
import { UnreachableCaseError } from 'helpers/UnreachableCaseError';

// Action Types
const NAME = 'resizeObjects';

const START_RESIZE = `${NAME}/START_RESIZE`;
const UPDATE_RESIZE = `${NAME}/UPDATE_RESIZE`;
const END_RESIZE = `${NAME}/END_RESIZE`;
export const OBJECT_RESIZED = `${NAME}/OBJECT_RESIZED`;

// Action Creators
export const actions = {
  startResize: (point: CoordinatePoint, corner: Corner) => ({
    type: START_RESIZE,
    payload: { point, corner },
  }),
  updateResize: (point: CoordinatePoint) => ({
    type: UPDATE_RESIZE,
    payload: point,
  }),
  endResize: (point: CoordinatePoint) => ({
    type: END_RESIZE,
    payload: point,
  }),
  objectResized: () => ({
    type: OBJECT_RESIZED,
  }),
};

export interface ResizeState {
  readonly startPoint: CoordinatePoint | null; // Point where user start to resize
  readonly endPoint: CoordinatePoint | null; // Point where user's cursor stays now
  readonly corner: Corner | null; // Corner from which the user is resizing
}

// Initial State
const initialState: ResizeState = {
  startPoint: null,
  endPoint: null,
  corner: null,
};

// Selectors
const getResizeState = (rootState: RootState): ResizeState => rootState.resize;

const getStartPoint = (rootState: RootState): CoordinatePoint | null => getResizeState(rootState).startPoint;

const getEndPoint = (rootState: RootState): CoordinatePoint | null => getResizeState(rootState).endPoint;

const getCorner = (rootState: RootState): Corner | null => getResizeState(rootState).corner;

export const selectors = {
  getStartPoint,
  getEndPoint,
  getCorner,
};

// Reducers
const startResizeReducer = (state: ResizeState, point: CoordinatePoint, corner: Corner): ResizeState => ({
  ...state,
  startPoint: point,
  endPoint: point,
  corner,
});

const updateResizeReducer = (state: ResizeState, endPoint: CoordinatePoint): ResizeState => ({
  ...state,
  endPoint,
});

export const reducer = (state: ResizeState = initialState, action: PayloadAction): ResizeState => {
  switch (action.type) {
    case getType(editModeActions.switchToResizing):
    case START_RESIZE:
      return startResizeReducer(state, action.payload.point, action.payload.corner);

    case UPDATE_RESIZE:
    case END_RESIZE:
      return updateResizeReducer(state, action.payload);

    default:
      return state;
  }
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doResizeObject(
    resizable: Resizable & Rotatable,
    d: CoordinatePoint,
    corner: Corner,
  ) {
    const point: Point = pointsSelectors.getPointById(yield select(), resizable.pointId);
    const { position, size } = resizeObject(resizable.size!, resizable.rotation!, point, d, corner);
    yield put(pointsActions.update({ pointId: resizable.pointId, ...position }));
    return size;
  }

  function* doResize(d: CoordinatePoint) {
    const selectedObjects: Immutable.List<string> = editModeSelectors.getSelectedObjects(yield select());
    if (d.x === 0 && d.y === 0) {
      yield put(editModeActions.switchToSelected(selectedObjects.toArray()));
      return;
    }
    const corner: Corner = selectors.getCorner(yield select())!;
    for (let i = 0; i < selectedObjects.size; i++) {
      const objectId = selectedObjects.get(i)!;
      const objectType: GeometryType = modelSelectors.getGeometryType(yield select(), objectId);
      switch (objectType) {
        case GeometryType.POSITIONED_LABEL: {
          const positionedLabel: PositionedLabel = positionedLabelsSelectors
            .getPositionedLabelById(yield select(), objectId);
          const labelSize: Dimensions = yield call(doResizeObject, positionedLabel, d, corner);
          const fontScale = calculateFontScale(labelSize, positionedLabel);
          yield put(positionedLabelsActions.resize(objectId, labelSize, fontScale));
          break;
        }

        case GeometryType.POSITIONED_SYMBOL: {
          const positionedSymbol: PositionedSymbol = positionedSymbolsSelectors
            .getPositionedSymbolById(yield select(), objectId);
          const symbolSize: Dimensions = yield call(doResizeObject, positionedSymbol, d, corner);
          yield put(positionedSymbolsActions.resize(objectId, symbolSize));
          break;
        }

        case GeometryType.POINT: {
          const point: Point = pointsSelectors.getPointById(yield select(), objectId);
          if (point.pointType === PointType.BLUE_PRINT_IMAGE) {
            const bluePrintImage: BluePrintImageState = bluePrintImageSelectors.getImage(yield select());
            const imageSize: Dimensions = yield call(doResizeObject, bluePrintImage, d, corner);
            yield put(bluePrintImageActions.resize(imageSize));
          }
          break;
        }

        case GeometryType.WALL:
        case GeometryType.FIGURE:
        case GeometryType.UNKNOWN:
          break;

        default: throw new UnreachableCaseError(objectType);
      }
    }
    yield put(actions.objectResized());
    yield put(editModeActions.switchToSelected(selectedObjects.toArray()));
  }

  function* doEndResize() {
    const startPoint: CoordinatePoint = selectors.getStartPoint(yield select())!;
    const endPoint: CoordinatePoint = selectors.getEndPoint(yield select())!;
    const d = { x: endPoint.x - startPoint.x, y: endPoint.y - startPoint.y };

    yield call(doResize, d);
  }

  return function* saga() {
    yield all([
      takeLatest(END_RESIZE, doEndResize),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
