import React, { useEffect, useState } from 'react';
import { InputLabel, Select, styled, OutlinedInput } from '@mui/material';

const CustomOutlinedInput = styled(OutlinedInput)(() => ({
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderBottom: '2px solid #D74A0E',
    backgroundColor: 'rgba(0,0,0,0.05)'
  },
}));

const CustomSelect = styled(Select)(() => ({
  padding: 0,
  borderRadius: 0,
  border: 'none',
  borderBottom: '1px solid rgba(0, 0, 0, 0.42)', // Specify the bottom border
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiNativeSelect-select': {
    padding: 0,
    marginBottom: 4,
  },
  '& .MuiInputBase-input': {
    padding: 0,
    marginBottom: 4,
  },
  '& .MuiOutlinedInput-input': {
    padding: 0,
    marginBottom: 4,
    paddingRight: '19px !important'
  },
  '& .MuiNativeSelect-icon': {
    right: 0,
  },
}));

const CompanyList = ({ body }: any): JSX.Element => {
  const [company, setCompany] = useState(body.defaultCompany);

  useEffect(() => {
    // do not set the company here
  }, [company]);

  const handleChange = (event: any) => {
    setCompany(event.target.value);
    // set the selected company in session storage here
    sessionStorage.setItem('selectedCompany', event.target.value);
  };

  // sort companies here..
  const companies = body.companies.sort((a: any, b: any) => (a.name <= b.name ? -1 : 1));

  // our default will be selected- if it is in the list of available, otherwise the first available is selected
  let defaultValue = companies.find((c: any) => c.companyId === body.defaultCompany)?.companyId;
  if (!defaultValue && companies.length > 0) {
    defaultValue = companies[0].companyId;
  }

  // set the selected company in session storage here if there isn't one already
  if (!sessionStorage.getItem('selectedCompany')) {
    setCompany(defaultValue);
    sessionStorage.setItem('selectedCompany', defaultValue);
  }

  return (
    <React.Fragment>
      <InputLabel id="company-select-label">Company</InputLabel>
      <CustomSelect
        native
        defaultValue={defaultValue}
        onChange={handleChange}
        input={<CustomOutlinedInput label="Company" />}
        inputProps={{
          name: 'company',
          id: 'company-native-simple',
        }}
      >
        {body && Array.isArray(companies) && companies.length > 0 && [...companies]
          .map((company: any) => (
            <option key={company.companyId} value={company.companyId}>
              {company.name}
            </option>
          ))}
      </CustomSelect>
    </React.Fragment>
  );
};

export default CompanyList;

