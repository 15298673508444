/** @jsx jsx */
import React, { Component, Fragment } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';

import { RootState } from 'reducers/rootReducer';
import {
  messages, cannotGoBackDialog, toolbarTopTextConfig as text, settingsTextConfig
} from 'config/messages';
import { sketchConfig } from 'config/sketchConfig';
import { isTouchDevice } from 'helpers/browserDetect';
import { logout, isDesktopApplication } from 'effects/auth';

import { actions as numpadModalActions, selectors as numpadModalSelectors } from 'ducks/modal/numpadModal';
import { selectors as glaBreakdownModalSelectors, actions as glaBreakdownModalActions } from 'ducks/modal/glaBreakdownModal';
import { selectors as settingsModalSelectors, actions as settingsModalActions } from 'ducks/modal/settingsModal';
import { selectors as sketchSearchModalSelectors, actions as sketchSearchModalActions } from 'ducks/modal/sketchSearchModal';
import { selectors as pageGroupsModalSelectors, actions as pageGroupsModalActions } from 'ducks/modal/pageGroupsModal';
import { selectors as printPreviewModalSelectors, actions as printPreviewModalActions } from 'ducks/modal/printPreviewModal';
import { actions as messageContainerActions, selectors as messageContainerSelectors, onSubmitEventType } from 'ducks/messageContainer';
import { actions as resizeSketchModalActions, selectors as resizeSketchModalSelectors } from 'ducks/modal/resizeSketchModal';
import { selectors as sidebarSelectors, actions as sidebarActions } from 'ducks/sidebar/sidebar';
import { selectors as figureSelectors, } from 'ducks/model/figures';
import { actions as historyActions, selectors as historySelectors } from 'ducks/history/history';
import { actions as editModeActions } from 'ducks/editMode';
import { actions as sketchPersistenceActions } from 'ducks/persistence/sketchPersistence';
import { actions as bluePrintImageActions } from 'ducks/bluePrintImage/bluePrintImage';
import { actions as inspectionActions } from 'ducks/inspection';

import { ReactComponent as ShowHideIconActive } from 'assets/icons/show-hide-vertical.svg';
import { ReactComponent as ShowHideIcon } from 'assets/icons/show-hide-horizontal.svg';
import { ReactComponent as IconUndo } from 'assets/icons/undo.svg';
import { ReactComponent as IconRedo } from 'assets/icons/redo.svg';
import { ReactComponent as IconPages } from 'assets/icons/pages-solid.svg';
import { ReactComponent as IconCalculator } from 'assets/icons/calculate-line.svg';
import { ReactComponent as IconUpload } from 'assets/icons/upload.svg';
import { ReactComponent as IconSave } from 'assets/icons/save.svg';
import { ReactComponent as IconLogout } from 'assets/icons/logout.svg';
import { ReactComponent as IconUploadImage } from 'assets/icons/image.svg';
import { ReactComponent as IconCog } from 'assets/icons/settings.svg';
import { ReactComponent as IconCogActive } from 'assets/icons/cog-active.svg';
import { ReactComponent as IconNumpad } from 'assets/icons/numpad.svg';
import { ReactComponent as IconNumpadActive } from 'assets/icons/numpad-active.svg';
import { ReactComponent as Hamburger } from 'assets/icons/hamburger.svg';

import { FromSketchMessageType } from 'types/sketch';
import { Figure } from 'types/figure';

import MobileMenu from 'components/MobileMenu/MobileMenu';
import { TooltipContent } from 'components/elements/Tooltip';

import AciHeader from 'figma/components/header/aciheader';
import { aciColors } from 'figma/styles/acistylecolors';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ToolbarButton, { ProtectedButton as ToolbarProtectedButton } from './ToolbarButton';

const TOOLTIP_POSITION = 'top';
const MENU_TOOLTIP_POSITION = 'left';
const MOBILE_MENU_TOOLTIP_POSITION = 'right';

const mediaQueries = css`
  color: white;
  fill: white;

  &:hover,
  &:focus {
    color: ${aciColors.grey900};
    fill: ${aciColors.grey900};
  }

  & {
    @media (max-width: 960px) {
      margin-left: 4px;
      margin-right: 4px;
    }
    @media (max-width: 840px) {
      margin-left: 2px;
      margin-right: 2px;
    }
    @media (max-height: 800px) {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
`;

const Button = styled(ToolbarButton)(mediaQueries);
const ProtectedButton = styled(ToolbarProtectedButton)(mediaQueries);

const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  color: #1a244f;
`;

const active = css`
  color: #0870fd;
  background-color: transparent;
`;

const Logo = styled.h1`
  margin: 0 10px;
  line-height: 50px;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  user-select: none;

  @media (min-width: 800px) {
    font-size: 20px;
  }
`;

const flexAlignCenter = css`
  margin-left: auto;
  margin-right: auto;
`;

const flexAlignLeft = css`
  margin-right: auto;
`;

const Separator = styled.div`
  width: 1px;
  border-left: 1px solid #e5e6ec;
  height: 40px;
`;

const hiddenInput = css({
  display: 'none',
});

interface StateProps {
  readonly figures: Immutable.Map<string, Figure>;
  readonly canUndo: boolean;
  readonly canRedo: boolean;
  readonly isShowingMobileMenu: boolean;
  readonly isShowingToolbarLeft: boolean;
  readonly isShowingGlaBreakdownModal: boolean;
  readonly isShowingSettingsModal: boolean;
  readonly isShowingSketchSearchModal: boolean;
  readonly isShowingPageGroupsModal: boolean;
  readonly isShowingPrintPreviewModal: boolean;
  readonly isShowingRescaleGridModal: boolean;
  readonly isShowingNumpadModal: boolean;
  readonly isShowingLabelSidebar: boolean;
  readonly isShowingSymbolSidebar: boolean;
  readonly isShowingPolygonSidebar: boolean;
  readonly highlightBack: boolean;
}

interface ActionProps {
  readonly saveSketchToApi: () => void;
  readonly loadSketch: () => void;
  readonly addImage: (imageUrl: string, width: number, height: number) => void;
  readonly showGlaBreakdownModal: () => void;
  readonly hideGlaBreakdownModal: () => void;
  readonly showNumpadModal: () => void;
  readonly hideNumpadModal: () => void;
  readonly showSketchSearchModal: () => void;
  readonly hideSketchSearchModal: () => void;
  readonly showSettingsModal: () => void;
  readonly hideSettingsModal: () => void;
  readonly showPageGroupsModal: () => void;
  readonly hidePageGroupsModal: () => void;
  readonly hidePrintPreviewModal: () => void;
  readonly showKnownLengthPromptModal: () => void;
  readonly toggleToolbarLeft: () => void;
  readonly toggleMobileMenu: () => void;
  readonly hideSidebar: () => void;
  readonly undo: () => void;
  readonly redo: () => void;
  readonly close: () => void;
  readonly clearHistory: () => void;
  readonly showMessage: (title: string, message: string | JSX.Element, onSubmit: onSubmitEventType, onSubmitText: string) => void;
  readonly toggleIsTracingFirstWall: typeof resizeSketchModalActions.toggleIsTracingFirstWall;
  readonly toInspectionMessage: typeof inspectionActions.toInspectionMessage;
  readonly saveSketchLocal: typeof inspectionActions.saveSketch;
  readonly setSketch: typeof sketchPersistenceActions.setSketch;
  readonly clearSelection: typeof editModeActions.clearSelection;
}

type Props = StateProps & ActionProps;

const beforeLogout = async () => {
  await sessionStorage.removeItem('userCompany');
  await localStorage.clear();
};

class ToolbarTop extends Component<Props> {
  private readonly inputOpenFileRef: React.RefObject<HTMLInputElement>;

  private menuRef: React.RefObject<HTMLElement>;

  public constructor(props: Props) {
    super(props);
    this.menuRef = React.createRef();
    this.inputOpenFileRef = React.createRef();
  }

  private toggleGlaBreakdownModal = (event: React.MouseEvent<HTMLElement>): void => {
    if (!isTouchDevice) event.preventDefault();
    const {
      isShowingGlaBreakdownModal,
      isShowingSketchSearchModal,
      isShowingSettingsModal,
      isShowingPageGroupsModal,
      isShowingPrintPreviewModal,
      isShowingNumpadModal,
      hideNumpadModal,
      hideGlaBreakdownModal,
      hideSketchSearchModal,
      hideSettingsModal,
      hidePageGroupsModal,
      hidePrintPreviewModal,
      showGlaBreakdownModal,
      hideSidebar,
    } = this.props;
    if (isShowingGlaBreakdownModal) {
      hideGlaBreakdownModal();
    } else {
      showGlaBreakdownModal();
      if (isShowingPageGroupsModal) hidePageGroupsModal();
      if (isShowingPrintPreviewModal) hidePrintPreviewModal();
      if (isShowingSettingsModal) hideSettingsModal();
      if (isShowingNumpadModal) hideNumpadModal();
      if (isShowingSketchSearchModal) hideSketchSearchModal();
    }
    hideSidebar();
  };

  private toggleOnScreenNumPad = (event: React.MouseEvent<HTMLElement>): void => {
    if (!isTouchDevice) event.preventDefault();
    const {
      isShowingGlaBreakdownModal,
      isShowingSettingsModal,
      isShowingSketchSearchModal,
      isShowingPageGroupsModal,
      isShowingPrintPreviewModal,
      isShowingNumpadModal,
      hideNumpadModal,
      hideGlaBreakdownModal,
      hideSketchSearchModal,
      hideSettingsModal,
      hidePageGroupsModal,
      hidePrintPreviewModal,
      showNumpadModal,
      hideSidebar
    } = this.props;
    if (isShowingNumpadModal) {
      hideNumpadModal();
    } else {
      showNumpadModal();
      if (isShowingGlaBreakdownModal) hideGlaBreakdownModal();
      if (isShowingPageGroupsModal) hidePageGroupsModal();
      if (isShowingPrintPreviewModal) hidePrintPreviewModal();
      if (isShowingSettingsModal) hideSettingsModal();
      if (isShowingSketchSearchModal) hideSketchSearchModal();
    }
    hideSidebar();
  };

  private sendNotification = (): void => {
    this.props.toInspectionMessage({ type: FromSketchMessageType.Ping });
  };

  private togglePageGroupsModal = (event: React.MouseEvent<HTMLElement>): void => {
    if (!isTouchDevice) event.preventDefault();
    const {
      isShowingPageGroupsModal,
      isShowingGlaBreakdownModal,
      isShowingSettingsModal,
      isShowingSketchSearchModal,
      isShowingPrintPreviewModal,
      isShowingNumpadModal,
      hideNumpadModal,
      hidePageGroupsModal,
      hideGlaBreakdownModal,
      hideSketchSearchModal,
      hideSettingsModal,
      hidePrintPreviewModal,
      showPageGroupsModal,
      hideSidebar,
      clearSelection
    } = this.props;
    if (isShowingPageGroupsModal) {
      hidePageGroupsModal();
    } else {
      showPageGroupsModal();
      if (isShowingGlaBreakdownModal) hideGlaBreakdownModal();
      if (isShowingPrintPreviewModal) hidePrintPreviewModal();
      if (isShowingSettingsModal) hideSettingsModal();
      if (isShowingNumpadModal) hideNumpadModal();
      if (isShowingSketchSearchModal) hideSketchSearchModal();
    }
    clearSelection();
    hideSidebar();
  };

  private toggleSettingsModal = (event: React.MouseEvent<HTMLElement>): void => {
    if (!isTouchDevice) event.preventDefault();
    const {
      isShowingSettingsModal,
      isShowingSketchSearchModal,
      isShowingPageGroupsModal,
      isShowingGlaBreakdownModal,
      isShowingPrintPreviewModal,
      isShowingNumpadModal,
      hideNumpadModal,
      hidePageGroupsModal,
      hideGlaBreakdownModal,
      hideSettingsModal,
      hideSketchSearchModal,
      hidePrintPreviewModal,
      showSettingsModal,
      hideSidebar
    } = this.props;
    if (isShowingSettingsModal) {
      hideSettingsModal();
    } else {
      showSettingsModal();
      if (isShowingGlaBreakdownModal) hideGlaBreakdownModal();
      if (isShowingPrintPreviewModal) hidePrintPreviewModal();
      if (isShowingPageGroupsModal) hidePageGroupsModal();
      if (isShowingNumpadModal) hideNumpadModal();
      if (isShowingSketchSearchModal) hideSketchSearchModal();
    }
    hideSidebar();
  };

  private toggleSketchSearchModal = (event: React.MouseEvent<HTMLElement>): void => {
    if (!isTouchDevice) event.preventDefault();
    const {
      isShowingSettingsModal,
      isShowingSketchSearchModal,
      isShowingPageGroupsModal,
      isShowingGlaBreakdownModal,
      isShowingPrintPreviewModal,
      isShowingNumpadModal,
      hideNumpadModal,
      hidePageGroupsModal,
      hideGlaBreakdownModal,
      hideSettingsModal,
      hideSketchSearchModal,
      hidePrintPreviewModal,
      showSketchSearchModal,
      hideSidebar,
      clearSelection
    } = this.props;
    if (isShowingSketchSearchModal) {
      hideSketchSearchModal();
    } else {
      showSketchSearchModal();
      if (isShowingGlaBreakdownModal) hideGlaBreakdownModal();
      if (isShowingPrintPreviewModal) hidePrintPreviewModal();
      if (isShowingPageGroupsModal) hidePageGroupsModal();
      if (isShowingNumpadModal) hideNumpadModal();
      if (isShowingSettingsModal) hideSettingsModal();
    }
    clearSelection();
    hideSidebar();
  };

  private logoutUser = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    if (!isTouchDevice) event.preventDefault();
    await logout({ beforeLogout });
  };

  private toggleToolbarLeft = (event: React.MouseEvent<HTMLElement>): void => {
    if (!isTouchDevice) event.preventDefault();
    const { toggleToolbarLeft, hideNumpadModal } = this.props;
    toggleToolbarLeft();
    hideNumpadModal();
    this.onClose();
  };

  private toggleMobileMenu = (event: any): void => {
    this.menuRef = event.currentTarget;
    const { toggleMobileMenu } = this.props;
    toggleMobileMenu();
    this.onClose();
  };

  private handleMobileMenuClick = (event: any, onClick: any): void => {
    const { toggleMobileMenu } = this.props;
    if (onClick) onClick();
    toggleMobileMenu();
    this.onClose();
  };

  private onClose = (): void => {
    const { close } = this.props;
    close();
  };

  private handleLocalSave = (): void => {
    const { setSketch, clearSelection } = this.props;
    clearSelection();
    setTimeout(setSketch, 1);
  };

  private undo = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    const { undo } = this.props;
    undo();
  };

  private redo = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    const { redo } = this.props;
    redo();
  };

  private toggleUploadImageModal = (event: React.MouseEvent<HTMLElement>): void => {
    if (!isTouchDevice) event.preventDefault();
    this.inputOpenFileRef.current!.click();
  };

  private handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files[0]) {
      const { addImage, showKnownLengthPromptModal, toggleIsTracingFirstWall } = this.props;
      const imageUrl = URL.createObjectURL(event.target.files[0]);

      const img = new Image();
      img.onload = () => {
        addImage(imageUrl, img.width, img.height);
        showKnownLengthPromptModal();
        toggleIsTracingFirstWall(true);
      };
      img.onerror = () => {
        toast.error(messages.cannotAddBluePrintImage);
      };
      img.src = imageUrl;
    }
  };

  private handleFileClick = (): void => {
    if (this.inputOpenFileRef.current !== null) {
      this.inputOpenFileRef.current.value = '';
    }
  };

  private renderMenuOptions = [
    {
      title: 'Pages',
      onClick: this.togglePageGroupsModal,
      icon: <IconPages />,
    },
    {
      title: 'Area Breakdown',
      onClick: this.toggleGlaBreakdownModal,
      icon: <IconCalculator />,
    },
    {
      title: 'Show Number Pad',
      onClick: this.toggleOnScreenNumPad,
      icon: <IconNumpadActive />,
    },
    {
      title: 'Trace Image',
      onClick: this.toggleUploadImageModal,
      icon: <IconUploadImage />,
    },
    {
      title: 'Settings',
      onClick: this.toggleSettingsModal,
      icon: <IconCog />,
    },
  ];

  public render(): JSX.Element {
    const {
      figures,
      canUndo,
      canRedo,
      isShowingPageGroupsModal,
      isShowingGlaBreakdownModal,
      isShowingSettingsModal,
      isShowingToolbarLeft,
      isShowingMobileMenu,
      isShowingNumpadModal,
      highlightBack,
    } = this.props;

    return (
      <div>
        <AciHeader>
          <Button
            css={{ minWidth: 45, color: 'white' }}
            iconSize={24}
            onClick={this.toggleToolbarLeft}
            tooltip={<h5>{text.toggleToolbarLeft}</h5>}
            tooltipPosition={MENU_TOOLTIP_POSITION}
            tooltipShow={highlightBack}
            active={isShowingToolbarLeft}
            icon={<ShowHideIcon />}
            iconActive={<ShowHideIconActive />}
          />

          <Section css={flexAlignLeft}>
            <Logo style={{ color: 'white' }}>
              SureStep
              <sup>&trade;&nbsp;</sup>
              sketch
            </Logo>
          </Section>

          <Section css={flexAlignCenter}>
            <Button
              iconSize={24}
              disabled={!canUndo}
              onClick={this.undo}
              tooltip={<h5>{text.undo}</h5>}
              tooltipPosition={TOOLTIP_POSITION}
              tooltipShow={highlightBack}
            >
              <IconUndo />
            </Button>
            <Button iconSize={24} disabled={!canRedo} onClick={this.redo} tooltip={<h5>{text.redo}</h5>} tooltipPosition={TOOLTIP_POSITION}>
              <IconRedo />
            </Button>
          </Section>
          {isTouchDevice ? (
            <Fragment>
              <Separator />
              <Button
                css={{ minWidth: 40, paddingLeft: 5 }}
                iconSize={18}
                onClick={this.toggleMobileMenu}
                tooltip={<h5>{text.toggleMobileMenu}</h5>}
                tooltipPosition={MOBILE_MENU_TOOLTIP_POSITION}
                tooltipShow={highlightBack}
                href={this.menuRef}
                icon={<Hamburger />}
              />
              <div id="sketch-mobile-menu-root">
                {isShowingMobileMenu && <MobileMenu menuList={this.renderMenuOptions} handleClose={this.handleMobileMenuClick} />}
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <Section>
                <Button
                  css={isShowingPageGroupsModal && active}
                  onClick={this.togglePageGroupsModal}
                  aria-label={text.pages}
                  iconSize={24}
                  touchLabel={text.pages}
                  tooltipPosition={TOOLTIP_POSITION}
                  tooltip={<TooltipContent title={text.pages} />}
                >
                  <IconPages />
                </Button>
                <Button
                  css={isShowingGlaBreakdownModal && active}
                  onClick={this.toggleGlaBreakdownModal}
                  aria-label={text.calculations}
                  iconSize={24}
                  touchLabel={text.touchLabels.calculations}
                  tooltipPosition={TOOLTIP_POSITION}
                  tooltip={<TooltipContent title={text.calculations} />}
                >
                  <IconCalculator />
                </Button>
                <Button
                  css={isShowingNumpadModal && active}
                  onClick={this.toggleOnScreenNumPad}
                  aria-label={text.numpad}
                  iconSize={24}
                  touchLabel={text.touchLabels.numpad}
                  tooltipPosition={TOOLTIP_POSITION}
                  tooltip={<TooltipContent title={text.numpad} />}
                >
                  <IconNumpad />
                </Button>
              </Section>
              <Separator />
              <Section>
                <Button
                  onClick={this.toggleUploadImageModal}
                  aria-label={text.addImage}
                  iconSize={24}
                  disabled={figures.size}
                  touchLabel={text.touchLabels.addImage}
                  tooltipPosition={TOOLTIP_POSITION}
                  tooltip={<TooltipContent title={text.addImage} />}
                >
                  <IconUploadImage />
                </Button>
                {sketchConfig.showLoadButton && (
                  <ProtectedButton
                    onClick={this.loadSketch}
                    iconSize={24}
                    touchLabel={text.upload}
                    tooltipPosition={TOOLTIP_POSITION}
                    tooltip={<TooltipContent title={text.upload} />}
                  >
                    <IconUpload />
                  </ProtectedButton>
                )}

                {isDesktopApplication() && (
                  <Button
                    onClick={this.handleLocalSave}
                    iconSize={24}
                    touchLabel={text.quickSave}
                    tooltipPosition={TOOLTIP_POSITION}
                    tooltip={<TooltipContent title={text.quickSave} />}
                  >
                    <IconSave />
                  </Button>
                )}

                <Button
                  onClick={this.toggleSketchSearchModal}
                  iconSize={24}
                  touchLabel={text.search}
                  tooltipPosition={TOOLTIP_POSITION}
                  tooltip={<TooltipContent title={text.search} />}
                >
                  <CloudUploadIcon />
                </Button>

                <Separator />

                <Button
                  css={isShowingSettingsModal && active}
                  onClick={this.toggleSettingsModal}
                  aria-label={settingsTextConfig.heading}
                  iconSize={24}
                  touchLabel={settingsTextConfig.heading}
                  tooltipPosition={TOOLTIP_POSITION}
                  tooltip={<TooltipContent title={settingsTextConfig.heading} />}
                >
                  {isShowingSettingsModal ? <IconCogActive /> : <IconCog />}
                </Button>

                {sketchConfig.showLogoutButton && (
                  <Button
                    onClick={this.logoutUser}
                    iconSize={24}
                    touchLabel={text.logout}
                    tooltipPosition={TOOLTIP_POSITION}
                    tooltip={<TooltipContent title={text.logout} />}
                    tooltipShow={highlightBack}
                  >
                    <IconLogout />
                  </Button>
                )}
              </Section>
            </Fragment>
          )}
          <input ref={this.inputOpenFileRef} type="file" css={hiddenInput} accept="image/*" onChange={this.handleFileChange} onClick={this.handleFileClick} />
        </AciHeader>
      </div>
    );
  }
}

export default connect(
  (state: RootState): StateProps => ({
    canUndo: historySelectors.canUndo(state),
    canRedo: historySelectors.canRedo(state),
    figures: figureSelectors.getAllFigures(state),
    isShowingToolbarLeft: sidebarSelectors.isShowingToolbarLeft(state),
    isShowingMobileMenu: sidebarSelectors.isShowingMobileMenu(state),
    isShowingGlaBreakdownModal: glaBreakdownModalSelectors.isShowing(state),
    isShowingSketchSearchModal: sketchSearchModalSelectors.isShowing(state),
    isShowingSettingsModal: settingsModalSelectors.isShowing(state),
    isShowingPageGroupsModal: pageGroupsModalSelectors.isShowing(state),
    isShowingPrintPreviewModal: printPreviewModalSelectors.isShowing(state),
    isShowingRescaleGridModal: resizeSketchModalSelectors.isRescaleShowing(state),
    isShowingNumpadModal: numpadModalSelectors.isShowing(state),
    isShowingLabelSidebar: sidebarSelectors.isShowingLabels(state),
    isShowingSymbolSidebar: sidebarSelectors.isShowingSymbols(state),
    isShowingPolygonSidebar: sidebarSelectors.isShowingPolygons(state),
    highlightBack: messageContainerSelectors.getMessage(state) === cannotGoBackDialog.text,
  }),
  (dispatch: Dispatch): ActionProps => ({
    saveSketchToApi: async () => {
      await dispatch(editModeActions.clearSelection()); // hack to update svg before trying to use svg element
      dispatch(sketchPersistenceActions.saveSketch());
    },
    ...bindActionCreators(
      {
        clearSelection: editModeActions.clearSelection,
        loadSketch: sketchPersistenceActions.loadSketch,
        setSketch: sketchPersistenceActions.setSketch,
        addImage: bluePrintImageActions.addImage,
        showNumpadModal: numpadModalActions.show,
        hideNumpadModal: numpadModalActions.hide,
        showGlaBreakdownModal: glaBreakdownModalActions.show,
        hideGlaBreakdownModal: glaBreakdownModalActions.hide,
        showSketchSearchModal: sketchSearchModalActions.show,
        hideSketchSearchModal: sketchSearchModalActions.hide,
        showSettingsModal: settingsModalActions.show,
        hideSettingsModal: settingsModalActions.hide,
        showPageGroupsModal: pageGroupsModalActions.show,
        hidePageGroupsModal: pageGroupsModalActions.hide,
        hidePrintPreviewModal: printPreviewModalActions.hide,
        hideSidebar: sidebarActions.hide,
        toggleToolbarLeft: sidebarActions.toggleToolbarLeft,
        toggleMobileMenu: sidebarActions.toggleMobileMenu,
        close: sidebarActions.hide,
        undo: historyActions.undo,
        redo: historyActions.redo,
        clearHistory: historyActions.clear,
        showMessage: messageContainerActions.show,
        toInspectionMessage: inspectionActions.toInspectionMessage,
        saveSketchLocal: inspectionActions.saveSketch,
        showKnownLengthPromptModal: resizeSketchModalActions.showKnownLengthPrompt,
        toggleIsTracingFirstWall: resizeSketchModalActions.toggleIsTracingFirstWall,
      },
      dispatch,
    ),
  }),
)(ToolbarTop);
