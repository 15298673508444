import React from 'react';
import { connect } from 'react-redux';
import * as Immutable from 'immutable';

import { RootState } from 'reducers/rootReducer';
import { SelectableObjects } from 'types/selection';
import { WallType } from 'types/wallType';
import { getWallsWithPoint, getWallPoints } from 'helpers/model/wallPoints';
import { selectors as modelSelectors } from 'ducks/model/model';
import { selectors as drawSelectors, DrawState } from 'ducks/draw/draw';
import WallsAngle from 'components/sketch/Wall/WallsAngle';
import { isPoint } from 'types/point';

interface StateProps {
  readonly selectableObjects: SelectableObjects;
  readonly drawState: DrawState;
}

type Props = StateProps;

const SelectedSegmentAngle = ({
  selectableObjects, drawState,
}: Props): JSX.Element | null => {
  const p2 = drawState.startPoint;
  const p3 = drawState.endPoint;

  const wallsIds = isPoint(p2) ? getWallsWithPoint(selectableObjects.walls, p2.pointId) : [];
  if (!wallsIds.length) {
    return null;
  }

  const wallId = wallsIds[0];
  const wall = selectableObjects.walls.get(wallId)!;
  if (wall.wallType === WallType.ARC) {
    return null;
  }

  const wallsPoints = getWallPoints(selectableObjects.points, wall);
  const endLine1 = wallsPoints.get(0)!;
  const endLine2 = wallsPoints.get(1)!;

  const p1 = isPoint(p2) && (endLine1.pointId === p2.pointId) ? endLine2 : endLine1;

  return (
    <>
      <WallsAngle
        wallsPoints={Immutable.List([p1, p2, p3])}
        figurePoints={Immutable.List([])}
      />
    </>
  );
};

export default connect((state: RootState): StateProps => ({
  selectableObjects: modelSelectors.getSelectableObjects(state),
  drawState: drawSelectors.getDrawState(state),
}))(SelectedSegmentAngle);
