import { RootState } from 'reducers/rootReducer';
import {
  call, put, select, all, takeLatest,
} from 'redux-saga/effects';
import { createAction, getType } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';
import * as Immutable from 'immutable';
import { filterBy, listToArray } from 'helpers/utils';

import { drawPositionedLabel } from 'ducks/draw/drawPositionedLabel';
import { drawLabel } from 'ducks/draw/drawLabel';
import { createSelectGroup } from 'helpers/createSelectGroup';
import { selectors as selectionSelectors } from 'ducks/selection/selection';
import { selectors as figuresSelectors } from 'ducks/model/figures';
import { actions as positionedLabelActions, selectors as positionedLabelsSelectors } from 'ducks/model/positionedLabels';
import { actions as editModeActions, selectors as editModeSelectors } from 'ducks/editMode';

import { PayloadAction } from 'types/payloadAction';
import { Group } from 'types/group';

// Actions
const NAME = 'group';

export interface GroupsState {
  readonly groups: Immutable.Map<string, Group>;
}

// Initial State
const initialState: GroupsState = {
  groups: Immutable.Map<string, Group>(),
};

// Action Creators
export const actions = {
  setGroup: createAction(`${NAME}/setGroup`)<any>(),

  addGroup: createAction(`${NAME}/addGroup`, (newGroup: Group) => (newGroup))<Group>(),

  updateGroup: createAction(`${NAME}/updateGroup`, (newGroup: Group) => (newGroup))<Group>(),

  removeGroup: createAction(`${NAME}/removeGroup`, (groupId: string | undefined) => (groupId))<string>(),

  modifyGroup: createAction(`${NAME}/modifyGroup`)<void>(),

  clear: createAction(`${NAME}/clear`)<void>(),
};

// Selectors
const getGroupsState = (rootState: RootState): GroupsState => rootState.groups;

const getAllGroups = (
  rootState: RootState,
): Immutable.Map<string, Group> => getGroupsState(rootState).groups;

const getGroupById = (rootState: RootState, groupId: string): Group | undefined => getAllGroups(rootState).get(groupId);

const getGroupIds = (
  rootState: RootState,
): string[] => getAllGroups(rootState).keySeq().toArray();

export const selectors = {
  getGroupsState,
  getAllGroups,
  getGroupById,
  getGroupIds,
};

// Reducers
const addGroupReducer = (state: GroupsState, newGroup: Group): GroupsState => ({
  ...state,
  groups: state.groups.set(newGroup.groupId, newGroup),
});

const updateGroupReducer = (state: GroupsState, payload: Group): GroupsState => {
  const { groupId } = payload;
  return {
    ...state,
    groups: state.groups.update(groupId),
  };
};

const removeGroupReducer = (state: GroupsState, groupId: string): GroupsState => {
  if (groupId) {
    return {
      ...state,
      groups: state.groups.remove(groupId),
    };
  }
  return state;
};

const clearGroupsReducer = (state: GroupsState): GroupsState => ({
  ...state,
  groups: Immutable.Map<string, Group>(),
});

export const reducer = (state: GroupsState = initialState, action: PayloadAction): GroupsState => {
  switch (action.type) {
    case getType(actions.addGroup):
      return addGroupReducer(state, action.payload);

    case getType(actions.updateGroup):
      return updateGroupReducer(state, action.payload);

    case getType(actions.removeGroup):
      return removeGroupReducer(state, action.payload);

    case getType(actions.clear):
      return clearGroupsReducer(state, action.payload);

    default:
      return state;
  }
};

// Sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doCreateGroup({ payload }: PayloadAction) {
    const rootState: RootState = yield select();
    const getFigures = filterBy((id: string) => !!figuresSelectors.getClosedFigureById(rootState, id));
    const getPositionedLabels = filterBy((id: string) => !!positionedLabelsSelectors.getPositionedLabelById(rootState, id));

    const selectedPoints: any = selectionSelectors.getSelectedPoints(rootState);
    const { topLeft } = createSelectGroup(selectedPoints);
    const labelPositionOffset = { x: topLeft.x + 40, y: topLeft.y - 40 };

    const groups = getGroupsState(rootState);

    // if current selection is part of a group, then update the group instead of creating a new one
    const groupId = editModeSelectors.getCurrentSelectedGroupId(rootState);
    const selectedObjects = editModeSelectors.getSelectedObjects(rootState);

    if (groupId) {
      const currentGroup = selectors.getGroupById(rootState, groupId);

      yield put(actions.updateGroup({
        groupId,
        selectedObjects,
        positionedLabelId: currentGroup?.positionedLabelId || uuidv4(),
        figures: getFigures(listToArray(payload)),
        positionedLabels: getPositionedLabels(listToArray(payload)),
      }));
      return;
    }

    const labelObj = {
      id: uuidv4(),
      text: `Group ${groups.groups.size + 1}`,
      labelCategory: 'groupLabel',
    };

    const labelId: string = yield call(drawLabel, labelObj);
    const positionedLabelId: string = yield call(drawPositionedLabel, labelId, labelPositionOffset);
    const newPayload = listToArray(payload);

    const newGroup = {
      groupId: uuidv4(),
      positionedLabelId,
      selectedObjects: payload.push(positionedLabelId),
      figures: getFigures(newPayload),
      positionedLabels: getPositionedLabels(newPayload).push(positionedLabelId),
    };

    yield put(editModeActions.selectObjects(payload.push(positionedLabelId)));
    yield put(editModeActions.setCurrentSelectedGroupId(newGroup.groupId));
    yield put(actions.addGroup(newGroup));
  }

  function* doRemoveGroup() {
    const rootState: RootState = yield select();
    const groupId = editModeSelectors.getCurrentSelectedGroupId(rootState);
    if (groupId) {
      const currentGroup = selectors.getGroupById(rootState, groupId);
      yield put(positionedLabelActions.remove(currentGroup?.positionedLabelId || ''));
      yield put(editModeActions.selectObjects([]));
      yield put(actions.removeGroup(groupId));
    }
  }

  return function* saga() {
    yield all([
      takeLatest(getType(actions.setGroup), doCreateGroup),
      takeLatest(getType(actions.modifyGroup), doRemoveGroup),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
