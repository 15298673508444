/** @jsx jsx */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Immutable from 'immutable';
import { css, jsx } from '@emotion/react';

import { RootState } from 'reducers/rootReducer';
import { Point } from 'types/point';
import { SizingInfo } from 'types/sizingInfo';
import { getPositioningWithoutRotation } from 'helpers/positioning';
import { selectors as bluePrintImageSelectors, BluePrintImageState } from 'ducks/bluePrintImage/bluePrintImage';
import { selectors as selectionSelectors } from 'ducks/selection/selection';
import { actions as editModeActions, selectors as editModeSelectors } from 'ducks/editMode';
import { MousePointToSVGPointFunction, selectors as viewportSelectors } from 'ducks/viewport';
import RemoveImageButton from 'components/sketch/BluePrintImage/RemoveImageButton';
import SelectedBorder from 'components/sketch/BluePrintImage/SelectedBorder';

export const BLUEPRINT_CONTAINER = 'blueprintContainer';

const style = css`
  cursor: pointer;
`;

// show the image under the grid
const imageStyle = css`
  z-index: -2;
`;

interface StateProps {
  readonly image: BluePrintImageState;
  readonly imagePosition: Point;
  readonly selectedObjects: Immutable.List<string>;
  readonly isMovingMode: boolean;
  readonly isResizingMode: boolean;
  readonly movedPoints: Immutable.List<Point>;
  readonly locked: boolean;
  readonly getMousePointToSvgPoint: MousePointToSVGPointFunction;
  readonly objectSizes: Immutable.Map<string, SizingInfo>;
}

interface ActionProps {
  readonly switchToSelected: typeof editModeActions.switchToSelected;
  readonly startMove: typeof editModeActions.switchToMoving;
}

type Props = StateProps & ActionProps;

class BluePrintImage extends Component<Props> {
  private handleMouseDown = (mouseEvent: React.PointerEvent<SVGGElement>): void => {
    const {
      selectedObjects, switchToSelected, imagePosition, locked, startMove, getMousePointToSvgPoint,
    } = this.props;
    if (locked) {
      return;
    }

    const isSelected = selectedObjects.includes(imagePosition.pointId);
    if (!isSelected) {
      switchToSelected([imagePosition.pointId]);
    } else {
      const point = getMousePointToSvgPoint(mouseEvent);
      startMove(point);
      mouseEvent.stopPropagation();
    }

    mouseEvent.stopPropagation();
  };

  public render(): JSX.Element | null {
    const {
      image, imagePosition, selectedObjects, movedPoints, objectSizes,
      isMovingMode, isResizingMode,
    } = this.props;
    if (!image || !imagePosition) {
      return null;
    }

    const isSelected = selectedObjects.includes(imagePosition.pointId);

    const { size, topLeftCorner } = getPositioningWithoutRotation(
      image.pointId, image, imagePosition,
      movedPoints, objectSizes,
      isMovingMode, isResizingMode,
    );

    return (
      <g
        id={BLUEPRINT_CONTAINER}
        css={style}
        onPointerDown={this.handleMouseDown}
      >
        <image
          xlinkHref={image.imageUrl}
          css={imageStyle}
          width={size.width}
          height={size.height}
          x={topLeftCorner.x}
          y={topLeftCorner.y}
          opacity={isSelected ? 0.5 : 0.25}
        />
        <SelectedBorder
          active={isSelected}
          x={topLeftCorner.x}
          y={topLeftCorner.y}
          width={size.width}
          height={size.height}
          size={12}
        />
        <RemoveImageButton
          x={topLeftCorner.x}
          y={topLeftCorner.y}
          width={size.width}
          height={size.height}
        />
      </g>
    );
  }
}

export default connect(
  (state: RootState): StateProps => ({
    isMovingMode: editModeSelectors.isMovingMode(state),
    isResizingMode: editModeSelectors.isResizingMode(state),
    selectedObjects: editModeSelectors.getSelectedObjects(state),
    movedPoints: selectionSelectors.getMovedSelectedPoints(state),
    image: bluePrintImageSelectors.getImage(state),
    imagePosition: bluePrintImageSelectors.getImagePosition(state),
    locked: bluePrintImageSelectors.isImageLocked(state),
    getMousePointToSvgPoint: viewportSelectors.getMousePointToSvgPoint(state),
    objectSizes: selectionSelectors.getResizedSelectedObjectsSizes(state),
  }),
  {
    switchToSelected: editModeActions.switchToSelected,
    startMove: editModeActions.switchToMoving,
  },
)(BluePrintImage);
