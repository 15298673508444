import {
  all, takeLatest, call, put,
} from 'redux-saga/effects';
import { createAction, getType, ActionType } from 'typesafe-actions';
import { toast } from 'react-toastify';

import { authConfig } from 'config/authConfig';
import { messages } from 'config/messages';
import { ApiSymbol } from 'types/api/apiSymbol';
import { ApiSymbolGroup } from 'types/api/apiSymbolGroup';
import { LogLevel } from 'types/log';
import {
  mapSymbolFromApi,
  mapSymbolGroupFromApi,
} from 'helpers/mappers';
import log from 'helpers/log';
import { RootState } from 'reducers/rootReducer';
import { getAccessToken } from 'effects/auth';
import { actions as symbolsActions } from 'ducks/model/symbols';
import { actions as symbolGroupsActions } from 'ducks/model/symbolGroups';
import * as ApiEffects from 'effects/api';

// Action Types
const NAME = 'labelPersistence';

export interface SymbolPersistenceState {
  readonly isLoading: boolean;
}

// Initial State
const initialState: SymbolPersistenceState = {
  isLoading: false,
};

// Action Creators
export const actions = {
  loadSymbols: createAction(`${NAME}/loadSymbols`)<void>(),
  loadSymbolGroups: createAction(`${NAME}/loadSymbolGroups`)<void>(),
  setLoadingSymbols: createAction(`${NAME}/setLoadingSymbols`)<boolean>(),
};

export type Actions = ActionType<typeof actions>

// Selectors
const getState = (rootState: RootState): SymbolPersistenceState => rootState[NAME];

const isLoading = (rootState: RootState): boolean => getState(rootState).isLoading;

export const selectors = {
  isLoading,
};

// Reducers
const setLoadingSymbolsReducer = (
  state: SymbolPersistenceState, loading: boolean,
): SymbolPersistenceState => ({
  ...state,
  isLoading: loading,
});

export const reducer = (
  state: SymbolPersistenceState = initialState, action: Actions,
): SymbolPersistenceState => {
  switch (action.type) {
    case getType(actions.setLoadingSymbols):
      return setLoadingSymbolsReducer(state, action.payload as boolean);

    default:
      return state;
  }
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doLoadSymbols(): any {
    if (authConfig.enabled || process.env.REACT_APP_USE_MOCKS) {
      yield put(actions.setLoadingSymbols(true));
      const accessToken = yield call(getAccessToken);
      let response: null | ApiSymbol[] = null;
      try {
        response = yield call(ApiEffects.getSymbols, accessToken);
      } catch (e) {
        toast.warning(messages.cannotLoadSymbols);
        log(LogLevel.error, e);
      }

      if (response !== null) {
        yield put(symbolsActions.set({
          clear: true,
          symbols: response.map(symbol => mapSymbolFromApi(symbol)),
        }));
      }
      yield put(actions.setLoadingSymbols(false));
    }
  }

  function* doLoadSymbolGroups(): any {
    if (authConfig.enabled || process.env.REACT_APP_USE_MOCKS) {
      yield put(actions.setLoadingSymbols(true));
      const accessToken = yield call(getAccessToken);
      let response: null | ApiSymbolGroup[] = null;
      try {
        response = yield call(ApiEffects.getSymbolGroups, accessToken);
      } catch (e) {
        toast.warning(messages.cannotLoadSymbolGroups);
        log(LogLevel.error, e);
      }
      if (response !== null) {
        yield put(symbolGroupsActions.set({
          clear: true,
          symbolGroups: response.map(symbolGroup => mapSymbolGroupFromApi(symbolGroup)),
        }));
      }
      yield put(actions.setLoadingSymbols(false));
    }
  }

  return function* saga() {
    yield all([
      takeLatest(actions.loadSymbols, doLoadSymbols),
      takeLatest(actions.loadSymbolGroups, doLoadSymbolGroups),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
