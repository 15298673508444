import React from 'react';
import { connect } from 'react-redux';
import * as Immutable from 'immutable';
import { RootState } from 'reducers/rootReducer';
import { Point } from 'types/point';
import { MeasurementLabelType } from 'types/measurementLabel';
import { mapMovedPoint } from 'helpers/move/mapMovedPoint';
import { selectors as editModeSelectors } from 'ducks/editMode';
import { selectors as selectionSelectors } from 'ducks/selection/selection';
import MeasurementLabel from './MeasurementLabel';
import Rotate from '../Rotate/Rotate';

interface InputProps {
  readonly measurementLabel: MeasurementLabelType;
  readonly fontSize?: number;
  readonly onClick: any;
}

interface StateProps {
  readonly isMovingMode?: boolean;
  readonly movedPoints?: Immutable.List<Point>;
}

type Props = InputProps & StateProps;

const WallLabel = ({ measurementLabel, isMovingMode, movedPoints, onClick, fontSize = 100 }: Props): JSX.Element => {
  const { text, position, rotation, baseline }: MeasurementLabelType = measurementLabel;
  const p = isMovingMode ? mapMovedPoint(movedPoints!, position) : position;

  return (
    <React.Fragment>
      <Rotate degrees={rotation} position={position}>
        <g onClick={onClick}>
          <MeasurementLabel text={text} position={p} rotation={0} baseline={baseline} size={fontSize} />
        </g>
      </Rotate>
    </React.Fragment>
  );
};

export default connect(
  (state: RootState): StateProps => ({
    isMovingMode: editModeSelectors.isMovingMode(state),
    movedPoints: selectionSelectors.getMovedSelectedPoints(state),
  }),
)(WallLabel);
