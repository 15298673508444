import React, { useRef } from 'react';
import { connect } from 'react-redux';
import * as Immutable from 'immutable';
import { area as areaColors } from 'config/paletteConfig';
import { v4 as uuidv4 } from 'uuid';

import { RootState } from 'reducers/rootReducer';
import { Figure, isClosedFigure } from 'types/figure';
import { Wall, CircleWall } from 'types/wall';
import { WallType } from 'types/wallType';
import { FigureType } from 'types/figureType';
import { Point, CoordinatePoint } from 'types/point';
import { mapMovedPoint } from 'helpers/move/mapMovedPoint';
import { createAreaTypeLabel, getFigureGlaType } from 'helpers/label/areaTypeLabel';
import { polygonArea } from 'helpers/polygonArea';
import { circleArea, getCentroid } from 'helpers/geometry';
import { DEGREES_PER_RADIAN } from 'helpers/rotation';
import { selectors as editModeSelectors } from 'ducks/editMode';
import { selectors as figuresSelectors } from 'ducks/model/figures';
import { selectors as selectionSelectors } from 'ducks/selection/selection';
import SketchLine from 'components/sketch/SketchLine/SketchLine';
import AreaTypeLabel from 'components/sketch/AreaTypeLabel/AreaTypeLabel';
import { PositionedAreaLabel } from 'types/positionedAreaLabel';
import { GlaType } from 'types/glaType';
import ContextMenu from 'components/ContextMenu/ContextMenu';
import RotateHandle from '../Rotate/RotateHandle';
import Rotate from '../Rotate/Rotate';

const getHandleRotation = (center: CoordinatePoint, lowerBottom: CoordinatePoint): number => {
  const rotation = Math.atan2(lowerBottom.y - center.y, lowerBottom.x - center.x) * DEGREES_PER_RADIAN;
  return rotation;
};

interface InputProps {
  readonly figureId: string;
}

interface StateProps {
  readonly figure: Figure;
  readonly figureWalls: Array<Wall | CircleWall>;
  readonly polygon: Immutable.List<Point>;
  readonly areaLabelPosition?: Point;
  readonly lowerBottomId?: string;
}

type Props = InputProps & StateProps;

const FigureComponent = (props: Props): JSX.Element | null => {
  const {
    figure, figureWalls, polygon, areaLabelPosition, lowerBottomId,
  } = props;
  const figureRef = useRef(null);
  let glaType: GlaType | undefined;
  let positionedAreaLabel: PositionedAreaLabel | undefined;
  if (isClosedFigure(figure) && figure.areaTypeId && areaLabelPosition) {
    glaType = getFigureGlaType(figure);
    positionedAreaLabel = glaType && createAreaTypeLabel(areaLabelPosition);
  }

  const center = polygon?.size ? getCentroid(polygon) : false;
  const lowerBottom = polygon.find((point: any) => point.pointId === lowerBottomId);

  const calculatedCircleArea = figureWalls[0]?.wallType === WallType.CIRCLE && circleArea((figureWalls[0] as CircleWall).radius);
  const calculatedPolygonArea = (polygon && !calculatedCircleArea) && polygonArea(polygon) || 0;

  const figureUuid = uuidv4();
  return (
    <g ref={figureRef}>
      {figureRef && (<ContextMenu type={`figure${figureUuid}`} parentRef={figureRef} />)}
      {calculatedCircleArea
        ? (
          <circle
            fill={figure.colorFill ? figure.colorFill : 'rgba(247,250,255,0.75)'}
            cx={polygon.get(0)?.x}
            cy={polygon.get(0)?.y}
            r={(figureWalls[0] as CircleWall).radius}
          />
        )
        : (
          <SketchLine
            colorFill={figure.colorFill ? figure.colorFill : areaColors.base}
            line={polygon}
            figureId={figure.figureId}
            isFigureClosed={isClosedFigure(figure)}
            isTransparent={figure.type === FigureType.INTERIOR}
            glaType={glaType}
          />
        )}
      {positionedAreaLabel && (
        <AreaTypeLabel
          figureId={figure.figureId}
          positionedAreaLabel={positionedAreaLabel}
          area={calculatedCircleArea || calculatedPolygonArea}
        />
      )}
      {lowerBottom && !calculatedCircleArea && center && (
        <Rotate position={lowerBottom} degrees={getHandleRotation(center, lowerBottom)}>
          <RotateHandle
            center={center}
            position={{
              x: lowerBottom.x + 5,
              y: lowerBottom.y + 5,
            }}
          />
        </Rotate>
      )}
    </g>
  );
};

export default connect(() => {
  let lowerBottomId: string | undefined;
  return (state: RootState, { figureId }: InputProps): StateProps => {
    const figure = figuresSelectors.getFigureById(state, figureId);
    const figureWalls = figuresSelectors.getFigureWalls(state, figureId);
    const hasMultiselected = editModeSelectors.hasMultiselected(state);
    const selectedObjects = editModeSelectors.getSelectedObjects(state);
    const selected = !!(selectedObjects && selectedObjects.includes(figureId));
    const figurePoints = figuresSelectors.getFigurePointsForDraw(state, figure.figureId);
    const movedPoints = selectionSelectors.getMovedSelectedPoints(state);
    const isMovingMode = editModeSelectors.isMovingMode(state);
    const polygon = figurePoints.map(
      p => (isMovingMode ? mapMovedPoint(movedPoints, p) : p),
    );
    if (!selected || hasMultiselected || !isClosedFigure(figure)) {
      lowerBottomId = undefined;
    } else if (!lowerBottomId) {
      let lowerBottom = polygon.get(0)!;
      polygon.forEach((point) => {
        if (lowerBottom.x + lowerBottom.y < point.x + point.y) {
          lowerBottom = point;
        }
      });
      lowerBottomId = lowerBottom.pointId;
    }
    return ({
      figure,
      figureWalls,
      polygon,
      areaLabelPosition: figuresSelectors.getAreaLabelPosition(state, figure.figureId),
      lowerBottomId,
    });
  };
})(FigureComponent);
