import React, { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { CoordinatePoint, isPoint, Point } from 'types/point';
import { actions as drawActions, selectors as drawSelectors } from 'ducks/draw/draw';
import { actions as editModeActions, selectors as editModeSelectors } from 'ducks/editMode';
import { actions as moveObjectsActions } from 'ducks/moveObjects';
import { actions as removeObjectsActions } from 'ducks/remove/removeObjects';
import { selectors as selectionSelectors } from 'ducks/selection/selection';
import { MousePointToSVGPointFunction, selectors as viewportSelectors } from 'ducks/viewport';
import { mapMovedPoint } from 'helpers/move/mapMovedPoint';
import { SelectableObjects, CollidedObject } from 'types/selection';
import { getCollidedObjectWithPoint } from 'helpers/collision/getCollidedObject';
import { selectors as modelSelectors } from 'ducks/model/model';
import { GeometryType } from 'types/geometryType';
import HandleImage from 'assets/point-drag-handle.svg';

interface StateProps {
  readonly drawStartPoint: CoordinatePoint | Point;
  readonly movedPoints: Immutable.List<Point>;
  readonly isMovingMode: boolean;
  readonly getMousePointToSvgPoint: MousePointToSVGPointFunction;
  readonly selectedFigures: string[];
  readonly selectableObjects: SelectableObjects;
  readonly zoomInPercent: number;
}

interface ActionProps {
  readonly resetDrawPoints: typeof drawActions.resetDrawPoints;
  readonly setAllDrawPoints: typeof drawActions.setAllDrawPoints;
  readonly startAdjustingDrawPoint: typeof editModeActions.startAdjustingDrawPoint;
  readonly endAdjustingDrawPoint: typeof editModeActions.endAdjustingDrawPoint;
  readonly switchToDrawing: typeof editModeActions.switchToDrawing;
  readonly switchToMoving: typeof editModeActions.switchToMoving;
  readonly switchToSelected: typeof editModeActions.switchToSelected;
  readonly endMove: typeof moveObjectsActions.endMove;
  readonly updateMove: typeof moveObjectsActions.updateMove;
  readonly updateLine: (point: CoordinatePoint) => void;
  readonly endLine: () => void;
  readonly removeSelectedObjects: () => void;
}

type Props = StateProps & ActionProps;

const handleOffset = {
  x: -26,
  y: 50,
};

class PointDragHandle extends Component<Props> {
  private handleTouchStart = (event: React.TouchEvent<SVGElement>): void => {
    const {
      drawStartPoint,
      startAdjustingDrawPoint,
      switchToMoving,
      switchToSelected,
      getMousePointToSvgPoint
    } = this.props;

    event.stopPropagation();
    event.preventDefault();

    // if this is not a single touch
    if (event.touches.length !== 1) {
      return;
    }

    const point = getMousePointToSvgPoint({ clientX: event.touches[0].clientX, clientY: event.touches[0].clientY });
    // if we don't have a point
    if (!point) {
      return;
    }

    if (isPoint(drawStartPoint)) {
      switchToSelected([(drawStartPoint as Point).pointId]);
      switchToMoving(point);
    }

    startAdjustingDrawPoint();
  };

  private handleTouchEnd = (event: React.TouchEvent<SVGElement>): void => {
    const {
      drawStartPoint,
      endMove,
      endAdjustingDrawPoint,
      switchToDrawing,
      resetDrawPoints,
      getMousePointToSvgPoint,
      selectableObjects,
      selectedFigures,
      isMovingMode,
      movedPoints,
      updateLine,
      endLine,
      switchToSelected,
      removeSelectedObjects,
      zoomInPercent
    } = this.props;

    event.stopPropagation();
    event.preventDefault();

    // if this is not a single touch
    if (event.changedTouches.length !== 1) {
      return;
    }

    const point = getMousePointToSvgPoint({ clientX: event.changedTouches[0].clientX, clientY: event.changedTouches[0].clientY });
    // if we don't have a point
    if (!point) {
      return;
    }

    if (isPoint(drawStartPoint)) {
      // if we are here- get the selected point (this is relative to the handle graphic)
      let relativePoint: CoordinatePoint = { ...drawStartPoint };
      if (isMovingMode) {
        relativePoint = mapMovedPoint(movedPoints, relativePoint);
      }

      // if we have a point
      if (relativePoint) {
        // look for a collision
        const collidedObject: CollidedObject = getCollidedObjectWithPoint(selectableObjects, relativePoint, [], zoomInPercent, selectedFigures);
        // if we have a collision and it's a point..
        if (collidedObject && collidedObject.objectType === GeometryType.POINT) {
          // this will create the new area
          updateLine(relativePoint);
          endLine();

          // drawStartPoint here is not the point the line starts but the
          // last place the point was displayed- this is not a point that
          // should remain on the line..
          switchToSelected([(drawStartPoint as Point).pointId]);
          removeSelectedObjects();
          return;
        }
      }

      endMove(point);
      switchToDrawing();
      resetDrawPoints();
    }

    endAdjustingDrawPoint();
  };

  private handleTouchMove = (event: React.TouchEvent<SVGElement>): void => {
    const {
      drawStartPoint,
      setAllDrawPoints,
      updateMove,
      getMousePointToSvgPoint
    } = this.props;

    event.stopPropagation();
    event.preventDefault();

    // if this is not a single touch
    if (event.touches.length !== 1) {
      return;
    }

    const point = getMousePointToSvgPoint({ clientX: event.touches[0].clientX, clientY: event.touches[0].clientY });
    // if we don't have a point
    if (!point) {
      return;
    }

    if (isPoint(drawStartPoint)) {
      updateMove(point);
    } else {
      const newDrawPoint = {
        x: point.x,
        y: point.y - handleOffset.y,
      };
      setAllDrawPoints(newDrawPoint);
    }
  };

  public render = (): JSX.Element | null => {
    const { drawStartPoint, movedPoints, isMovingMode } = this.props;

    let p: CoordinatePoint = { ...drawStartPoint };
    if (isMovingMode) {
      p = mapMovedPoint(movedPoints, p);
    }

    return (
      <g>
        <line
          x1={p.x}
          y1={p.y}
          x2={p.x}
          y2={p.y + handleOffset.y}
          style={{
            strokeWidth: 2,
            stroke: 'black',
          }}
        />
        <image
          // I am increasing the size of this graphic slightly because it's difficult to touch
          width={62}
          height={62}
          x={p.x + handleOffset.x - 5}
          y={p.y + handleOffset.y + 5}
          onTouchStart={this.handleTouchStart}
          onTouchMove={this.handleTouchMove}
          onTouchEnd={this.handleTouchEnd}
          href={HandleImage}
        />
      </g>
    );
  }
}

export default connect(
  (state: RootState): StateProps => ({
    drawStartPoint: drawSelectors.getStartPoint(state),
    isMovingMode: editModeSelectors.isMovingMode(state),
    movedPoints: selectionSelectors.getMovedSelectedPoints(state),
    getMousePointToSvgPoint: viewportSelectors.getMousePointToSvgPoint(state),
    selectedFigures: selectionSelectors.getSelectedFigures(state).map((selectedFigure) => selectedFigure.figureId),
    selectableObjects: modelSelectors.getSelectableObjects(state),
    zoomInPercent: viewportSelectors.getZoomInPercent(state)
  }),
  (dispatch: Dispatch) => bindActionCreators(
    {
      resetDrawPoints: drawActions.resetDrawPoints,
      setAllDrawPoints: drawActions.setAllDrawPoints,
      startAdjustingDrawPoint: editModeActions.startAdjustingDrawPoint,
      endAdjustingDrawPoint: editModeActions.endAdjustingDrawPoint,
      switchToDrawing: editModeActions.switchToDrawing,
      switchToMoving: editModeActions.switchToMoving,
      switchToSelected: editModeActions.switchToSelected,
      updateMove: moveObjectsActions.updateMove,
      endMove: moveObjectsActions.endMove,
      updateLine: drawActions.setEndPoint,
      endLine: drawActions.finishSegment,
      removeSelectedObjects: removeObjectsActions.removeSelectedObjects
    },
    dispatch,
  ),
)(PointDragHandle);
