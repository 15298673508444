/** @jsxFrag */
import React from 'react';
import styled from '@emotion/styled';
import * as Immutable from 'immutable';
import { css } from '@emotion/react';

import { lengthLabels as colors } from 'config/paletteConfig';
import { Point, CoordinatePoint } from 'types/point';
import { getAngleArc } from 'helpers/angle/getAngleArc';
import { connect } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { selectors as viewPortSelectors } from 'ducks/viewport';
import { arcAngleStyles } from './styles';

interface StateProps {
  readonly zoomInPercent: number;
}

interface InputProps {
  readonly wallsPoints: Immutable.List<Point | CoordinatePoint>;
  readonly figurePoints: Immutable.List<Point | CoordinatePoint>;
}

type Props = InputProps & StateProps;


const style = css({
  fill: `${colors.base}`,
  textAnchor: 'middle',
  dominantBaseline: 'middle',
  pointerEvents: 'none'
});

const WallsAngle = ({
  wallsPoints, figurePoints, zoomInPercent
}: Props): JSX.Element | null => {
  const arc = getAngleArc(wallsPoints, figurePoints);
  if (!arc) {
    return null;
  }

  const Path = styled.path(arcAngleStyles.get(zoomInPercent));

  return (
    <>
      <Path d={arc.pathData} />
      <text
        css={style}
        x={arc.labelPosition.x}
        y={arc.labelPosition.y}
      >
        {`${arc.angle}°`}
      </text>
    </>
  );
};

export default connect((state: RootState): StateProps => ({
  zoomInPercent: viewPortSelectors.getZoomInPercent(state),
}))(WallsAngle);
