import * as Immutable from 'immutable';

import { RootState } from 'reducers/rootReducer';
import { GeometryType } from 'types/geometryType';
import { CoordinatePoint, Point } from 'types/point';
import { PointType } from 'types/pointType';
import { Wall } from 'types/wall';
import { WallType } from 'types/wallType';
import { Corner } from 'types/corner';
import { Resizable } from 'types/resizable';
import { Rotatable } from 'types/rotatable';
import { SizingInfo } from 'types/sizingInfo';
import { isClosedFigure, Figure } from 'types/figure';
import { PositionedSymbol } from 'types/positionedSymbol';
import { PositionedLabel } from 'types/positionedLabel';
import {
  moveAndSnapFigure, moveAndSnapPoint, moveAndSnapWall, moveArcPoint, moveArcPointByCorner,
} from 'helpers/move/moveAndSnap';
import { movePoint } from 'helpers/move/movePoint';
import { getWallsWithPoint } from 'helpers/model/wallPoints';
import { getFiguresWithPoint } from 'helpers/model/figurePoints';
import { resizeObject } from 'helpers/resize/resizeObject';
import { calculateFontScale } from 'helpers/calculateFontScale';
import { movePolygon } from 'helpers/move/movePolygon';
import { selectors as editModeSelectors } from 'ducks/editMode';
import { selectors as modelSelectors } from 'ducks/model/model';
import { selectors as moveObjectsSelectors } from 'ducks/moveObjects';
import { selectors as resizeObjectsSelectors } from 'ducks/resizeObjects';
import { selectors as figuresSelectors } from 'ducks/model/figures';
import { selectors as wallsSelectors } from 'ducks/model/walls';
import { selectors as pointsSelectors } from 'ducks/model/points';
import { selectors as positionedLabelsSelectors } from 'ducks/model/positionedLabels';
import { selectors as positionedSymbolsSelectors } from 'ducks/model/positionedSymbols';
import { selectors as bluePrintImageSelectors } from 'ducks/bluePrintImage/bluePrintImage';
import { selectors as settingsSelectors } from 'ducks/settings';
import { UnreachableCaseError } from 'helpers/UnreachableCaseError';

export interface SelectedLine {
  readonly wall: Wall;
  readonly points: Immutable.List<Point>;
}

export interface DuplicableObjects {
  readonly figures: Figure[];
  readonly positionedSymbols: PositionedSymbol[];
  readonly positionedLabels: PositionedLabel[];
}

const getObjectPoints = (objectId: string, objectType: GeometryType, rootState: RootState): Immutable.List<Point> => {
  switch (objectType) {
    case GeometryType.FIGURE: {
      const figure = figuresSelectors.getFigureById(rootState, objectId);
      let points = figuresSelectors.getFigurePoints(rootState, objectId);
      const areaLabel = isClosedFigure(figure) && figure.positionedAreaLabel;
      if (areaLabel) {
        points = points.push(pointsSelectors.getPointById(rootState, areaLabel.pointId));
      }
      return points;
    }

    case GeometryType.WALL: {
      return wallsSelectors.getWallPoints(rootState, objectId);
    }

    case GeometryType.POINT: {
      return Immutable.List<Point>([pointsSelectors.getPointById(rootState, objectId)]);
    }

    case GeometryType.POSITIONED_LABEL: {
      const positionedLabel = positionedLabelsSelectors.getPositionedLabelById(rootState, objectId);
      return Immutable.List<Point>([pointsSelectors.getPointById(rootState, positionedLabel!.pointId)]);
    }

    case GeometryType.POSITIONED_SYMBOL: {
      const positionedSymbol = positionedSymbolsSelectors.getPositionedSymbolById(rootState, objectId);
      return Immutable.List<Point>([pointsSelectors.getPointById(rootState, positionedSymbol!.pointId)]);
    }

    case GeometryType.UNKNOWN:
      return Immutable.List<Point>();

    default:
      throw new UnreachableCaseError(objectType);
  }
};

/**
 * get all selected points i.e.
 * for figure it will be all figure's points and label position
 * for wall it will walls points
 * for point it will be point itself
 */
const getSelectedPoints = (rootState: RootState): Immutable.List<Point> => {
  const selectedObjects: Immutable.List<string> = editModeSelectors.getSelectedObjects(rootState);

  return selectedObjects.reduce((points: Immutable.List<Point>, objectId: string) => {
    const objectType = modelSelectors.getGeometryType(rootState, objectId);
    return points.concat(getObjectPoints(objectId, objectType, rootState));
  }, Immutable.List<Point>());
};

const getChangedArcPoints = (rootState: RootState, points: Immutable.List<Point>): Immutable.List<Point> => {
  let changedArcPoints: Immutable.List<Point> = Immutable.List<Point>();
  const walls = wallsSelectors.getAllWalls(rootState);
  points.forEach((point) => {
    const allWalls = getWallsWithPoint(walls, point.pointId);
    allWalls.forEach((wallId) => {
      const wall = walls.get(wallId)!;
      if (wall.wallType === WallType.ARC) {
        const wallPoints = wallsSelectors.getWallPoints(rootState, wallId);
        const changedWallPoints = wallPoints.map((p) => {
          const pChanged = points.find((pIt) => pIt.pointId === p.pointId);
          if (pChanged) {
            return pChanged;
          }
          return p;
        });
        const arcPoint = moveArcPointByCorner(wallPoints, changedWallPoints);
        const arcPointChanged = changedArcPoints.find((pIt) => pIt.pointId === arcPoint.pointId);
        if (!arcPointChanged) {
          changedArcPoints = changedArcPoints.push(arcPoint);
        }
      }
    });
  });
  return changedArcPoints;
};

const getChangedFigures = (rootState: RootState, points: Immutable.List<Point>): string[] => {
  const changedFigures: string[] = [];
  const walls = wallsSelectors.getAllWalls(rootState);
  const figures = figuresSelectors.getAllFigures(rootState);
  points.forEach((point) => {
    const allFigures = getFiguresWithPoint(walls, figures, point.pointId);
    allFigures.forEach((figureId) => {
      if (!changedFigures.includes(figureId)) {
        changedFigures.push(figureId);
      }
    });
  });
  return changedFigures;
};

const getChangedFiguresLabels = (rootState: RootState, points: Immutable.List<Point>): Immutable.List<Point> => {
  const changedFigures: string[] = getChangedFigures(rootState, points);
  let labelPoints: Immutable.List<Point> = Immutable.List<Point>();
  changedFigures.forEach((figureId) => {
    const areaLabel = figuresSelectors.getAreaLabel(rootState, figureId);
    if (areaLabel && !areaLabel.isMovedByUser) {
      const point = pointsSelectors.getPointById(rootState, areaLabel.pointId);
      labelPoints = labelPoints.push(point);
    }
  });

  return labelPoints;
};

/**
 * get new coordinates for moved points
 */
const getMovedSelectedPoints = (rootState: RootState): Immutable.List<Point> => {
  if (!editModeSelectors.isMovingMode(rootState)) {
    return Immutable.List<Point>();
  }

  const startPoint = moveObjectsSelectors.getStartPoint(rootState)!;
  const endPoint = moveObjectsSelectors.getEndPoint(rootState)!;
  const d = {
    x: endPoint.x - startPoint.x,
    y: endPoint.y - startPoint.y,
  };

  const selectedObjects = editModeSelectors.getSelectedObjects(rootState);
  const hasMultiselected = editModeSelectors.hasMultiselected(rootState);
  const movePointFunction = hasMultiselected ? movePoint : moveAndSnapPoint;
  const movedPoints = selectedObjects.reduce((points: Immutable.List<Point>, objectId: string) => {
    const objectType = modelSelectors.getGeometryType(rootState, objectId);
    const objectPoints = getObjectPoints(objectId, objectType, rootState);
    const snapDivision = settingsSelectors.getPrecision(rootState);

    switch (objectType) {
      case GeometryType.FIGURE:
        if (hasMultiselected) {
          return points.concat(movePolygon(objectPoints, d.x, d.y));
        }
        return points.concat(moveAndSnapFigure(objectPoints, d, snapDivision));

      case GeometryType.WALL: {
        const wall = wallsSelectors.getWallById(rootState, objectId);
        const movedWallPoints = wall.wallType === WallType.ARC || hasMultiselected
          ? movePolygon(objectPoints, d.x, d.y)
          : moveAndSnapWall(objectPoints, d, snapDivision);
        return points.concat(movedWallPoints);
      }

      case GeometryType.POINT: {
        const point = pointsSelectors.getPointById(rootState, objectId);
        if (point.pointType === PointType.ARC) {
          const wall = wallsSelectors.getArcWallByArcPointId(rootState, objectId);
          const wallPoints = wallsSelectors.getWallPoints(rootState, wall.wallId);
          return points.push(moveArcPoint(wallPoints, d));
        }
        const movedPoint: Point = movePointFunction(point, d, snapDivision);
        return points.push(movedPoint);
      }

      case GeometryType.POSITIONED_LABEL: {
        const movedPoint: Point = movePointFunction(objectPoints.first(), d, snapDivision);
        return points.push(movedPoint);
      }

      case GeometryType.POSITIONED_SYMBOL: {
        const movedPoint: Point = movePointFunction(objectPoints.first(), d, snapDivision);
        return points.push(movedPoint);
      }

      case GeometryType.UNKNOWN:
        return points;

      default:
        throw new UnreachableCaseError(objectType);
    }
  }, Immutable.List<Point>());

  const arcChangedPoints = getChangedArcPoints(rootState, movedPoints);
  return movedPoints.concat(arcChangedPoints);
};

const resizeSelectedObject = (rootState: RootState, resizable: Resizable & Rotatable, d: CoordinatePoint, corner: Corner): SizingInfo => {
  const point = pointsSelectors.getPointById(rootState, resizable.pointId);
  const { x, y } = point;
  return resizeObject(resizable.size!, resizable.rotation!, { x, y }, d, corner);
};

/**
 * get new bounding boxes for resized objects
 */
const getResizedSelectedObjectsSizes = (rootState: RootState): Immutable.Map<string, SizingInfo> => {
  let result = Immutable.Map<string, SizingInfo>();

  if (!editModeSelectors.isResizingMode(rootState)) {
    return result;
  }

  const startPoint = resizeObjectsSelectors.getStartPoint(rootState);
  const endPoint = resizeObjectsSelectors.getEndPoint(rootState);
  const corner = resizeObjectsSelectors.getCorner(rootState);

  if (startPoint === null || endPoint === null || corner === null) {
    return result;
  }

  const d = {
    x: endPoint.x - startPoint.x,
    y: endPoint.y - startPoint.y,
  };

  const selectedObjects: Immutable.List<string> = editModeSelectors.getSelectedObjects(rootState);
  selectedObjects.forEach((objectId) => {
    const objectType = modelSelectors.getGeometryType(rootState, objectId);
    switch (objectType) {
      case GeometryType.POSITIONED_LABEL: {
        const positionedLabel = positionedLabelsSelectors.getPositionedLabelById(rootState, objectId)!;
        const sizingInfo = resizeSelectedObject(rootState, positionedLabel, d, corner);
        sizingInfo.fontScale = calculateFontScale(sizingInfo.size, positionedLabel);
        result = result.set(objectId, sizingInfo);
        break;
      }

      case GeometryType.POSITIONED_SYMBOL: {
        const positionedSymbol = positionedSymbolsSelectors.getPositionedSymbolById(rootState, objectId)!;
        result = result.set(objectId, resizeSelectedObject(rootState, positionedSymbol, d, corner));
        break;
      }

      case GeometryType.POINT: {
        const point: Point = pointsSelectors.getPointById(rootState, objectId);
        if (point.pointType === PointType.BLUE_PRINT_IMAGE) {
          const bluePrintImage = bluePrintImageSelectors.getImage(rootState);
          result = result.set(objectId, resizeSelectedObject(rootState, bluePrintImage, d, corner));
        }
        break;
      }

      case GeometryType.WALL:
      case GeometryType.FIGURE:
      case GeometryType.UNKNOWN:
        break;

      default:
        throw new UnreachableCaseError(objectType);
    }
  });
  return result;
};

// Selectors
/**
 * Check if selected objects can be removed
 */
const canRemoveSelectedObjects = (rootState: RootState): boolean => {
  const selectedObjects: Immutable.List<string> = editModeSelectors.getSelectedObjects(rootState);
  if (!selectedObjects.size) {
    return false;
  }

  const walls = wallsSelectors.getAllWalls(rootState);

  const nonRemovableObject = selectedObjects.find((objectId) => {
    const objectType: GeometryType = modelSelectors.getGeometryType(rootState, objectId);
    if (objectType === GeometryType.POINT) {
      if (pointsSelectors.getPointById(rootState, objectId).pointType === PointType.LABEL) {
        return true;
      }

      const wallsWithPoint = getWallsWithPoint(walls, objectId);
      if (wallsWithPoint.length > 2) {
        return true;
      }
    }
    return false;
  });

  return !nonRemovableObject;
};

/**
 * Check if user can change line to curve
 */
const isLineWallSelected = (rootState: RootState): boolean => {
  const selectedObjects: Immutable.List<string> = editModeSelectors.getSelectedObjects(rootState);
  if (selectedObjects.size !== 1) {
    return false;
  }

  const objectId = selectedObjects.get(0)!;
  const objectType = modelSelectors.getGeometryType(rootState, objectId);
  if (objectType !== GeometryType.WALL) {
    return false;
  }
  const walls = wallsSelectors.getAllWalls(rootState);
  const wall = walls.get(objectId)!;

  return wall.wallType === WallType.LINE;
};

/**
 * Return line with points if selected
 */
const getSelectedLine = (rootState: RootState): SelectedLine | undefined => {
  if (!isLineWallSelected(rootState)) {
    return undefined;
  }

  const selectedObjects: Immutable.List<string> = editModeSelectors.getSelectedObjects(rootState);
  const wallId = selectedObjects.get(0)!;
  const wall = wallsSelectors.getWallById(rootState, wallId);
  const points = wallsSelectors.getWallPoints(rootState, wallId);

  return {
    wall,
    points,
  };
};

const getSelectedPositionedSymbols = (rootState: RootState): PositionedSymbol[] => {
  const selectedObjects = editModeSelectors.getSelectedObjects(rootState);
  return positionedSymbolsSelectors
    .getAllPositionedSymbols(rootState)
    .valueSeq()
    .toArray()
    .filter((symbol) => selectedObjects.contains(symbol.positionedSymbolId));
};

const getSelectedPositionedLabels = (rootState: RootState): PositionedLabel[] => {
  const selectedObjects = editModeSelectors.getSelectedObjects(rootState);
  return positionedLabelsSelectors
    .getAllPositionedLabels(rootState)
    .valueSeq()
    .toArray()
    .filter((label) => selectedObjects.contains(label.positionedLabelId));
};

const getSelectedFigures = (rootState: RootState): Figure[] => {
  const selectedObjects = editModeSelectors.getSelectedObjects(rootState);
  return [
    ...figuresSelectors
      .getAllClosedFigures(rootState)
      .valueSeq()
      .toArray()
      .filter((figure) => selectedObjects.contains(figure.figureId)),
    ...figuresSelectors
      .getInteriorFigures(rootState)
      .valueSeq()
      .toArray()
      .filter((figure) => selectedObjects.contains(figure.figureId))];
};

const getSelectedDuplicableObjects = (rootState: RootState): DuplicableObjects => {
  const positionedSymbols = getSelectedPositionedSymbols(rootState);
  const positionedLabels = getSelectedPositionedLabels(rootState);
  const figures = getSelectedFigures(rootState);

  return {
    figures,
    positionedSymbols,
    positionedLabels,
  };
};

const areDuplicableSelected = (rootState: RootState): boolean => {
  const duplicableObjects = getSelectedDuplicableObjects(rootState);
  return duplicableObjects.positionedSymbols.length > 0 || duplicableObjects.positionedLabels.length > 0 || duplicableObjects.figures.length > 0;
};

export const selectors = {
  getSelectedPoints,
  getSelectedLine,
  getMovedSelectedPoints,
  getResizedSelectedObjectsSizes,
  canRemoveSelectedObjects,
  isLineWallSelected,
  getChangedArcPoints,
  getChangedFigures,
  getChangedFiguresLabels,
  getSelectedDuplicableObjects,
  areDuplicableSelected,
  getSelectedFigures,
};
