import { put, select } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { Page } from 'types/page';
import { Point, CoordinatePoint, isPoint } from 'types/point';
import { PositionedLabel } from 'types/positionedLabel';
import { actions as pointsActions } from 'ducks/model/points';
import { actions as positionedLabelsActions } from 'ducks/model/positionedLabels';
import { selectors as pagesSelectors, actions as pagesActions } from 'ducks/model/pages';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export function* drawPositionedLabel(labelId: string, point: Point | CoordinatePoint) {
  const pointId = isPoint(point) ? point.pointId : uuidv4();
  const positionedLabelId = uuidv4();
  const page: Page = yield select(pagesSelectors.getCurrentPage);

  if (!isPoint(point)) {
    yield put(pointsActions.add({ pointId, ...point }));
  }
  const positionedLabel: PositionedLabel = {
    positionedLabelId,
    labelId,
    pointId,
    rotation: 0,
    fontScale: 100,
  };
  yield put(positionedLabelsActions.add(positionedLabel));
  yield put(pagesActions.upsertObject(page.pageId, positionedLabelId, positionedLabel));

  return positionedLabelId;
}
/* eslint-enable @typescript-eslint/explicit-function-return-type */
