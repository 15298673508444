import * as Immutable from 'immutable';

import { Figure, isClosedFigure } from 'types/figure';
import { CircleWall, Wall } from 'types/wall';
import { Point, CoordinatePoint } from 'types/point';
import { GlaType } from 'types/glaType';
import { getFigurePointsForDraw } from 'helpers/model/figurePoints';
import { PointType } from 'types/pointType';
import {
  compose, listToArray, mapBy, addTo, subtractFrom, divideBy, multiplyBy
} from './utils';
import { circleArea, distance, slope } from './geometry';
import { getCurveByArcPoint } from './curve/getArcPoint';

/**
 * Calculate polygon area
 * it is copied from https://www.mathopenref.com/coordpolygonarea2.html
 */

export const polygonArea = (polygon: Immutable.List<CoordinatePoint>): number => {
  if (polygon.size < 3) {
    return 0;
  }

  const points = compose(
    listToArray,
  )(polygon);

  const area = points.reduce((acc: number, { x, y, pointType }: Point, idx: number, pts: Point[]) => {
    const p2 = pts[idx - 1] || pts[pts.length - 1];

    if (pointType === PointType.ARC) {
      const p3 = pts[idx + 1] || pts[0];

      const linearArea = compose(
        (sum: number) => sum + (x + p3.x) * (y - p3.y),
      )((p2.x + x) * (p2.y - y));
      const areaSansArcPoint = (p2.x + p3.x) * (p2.y - p3.y);

      const sign = compose(
        Math.sign,
        addTo((areaSansArcPoint * -1)),
      )(linearArea);

      return compose(
        addTo(acc + areaSansArcPoint),
        multiplyBy(sign * 2)
      )(getArcSegmentArea(p2, p3, { x, y }));
    }
    if (p2.pointType === PointType.ARC) {
      return acc;
    }
    return acc + (p2.x + x) * (p2.y - y);
  }, 0);

  return compose(
    Math.abs,
    divideBy(2)
  )(area);
};

export const getArcSegmentArea = (left: CoordinatePoint, right: CoordinatePoint, arcPoint: CoordinatePoint): number => {
  const { center, arcHeight, r } = getCurveByArcPoint(left, right, arcPoint);

  const angle = compose(
    (slopes: number[]) => slopes.reduce((acc: number, item: number) => acc - item),
    mapBy(slope(center)),
  )([left, right]);

  const segmentAreas = compose(
    (area: number) => [subtractFrom(circleArea(r))(area), area],
    Math.abs,
    ({ r, angle }: Record<string, number>) => r ** 2 / 2 * (angle - Math.sin(angle)),
  )({ r, angle });

  return Math.abs(arcHeight) * 2 > distance(left, right)
    ? Math.max(...segmentAreas)
    : Math.min(...segmentAreas);
};

export const calculateTotalArea = (
  figures: Immutable.Map<string, Figure>,
  points: Immutable.Map<string, Point>,
  walls: Immutable.Map<string, Wall>,
): number => figures.valueSeq().reduce(
  (totalArea: number, figure: Figure) => {
    if (!isClosedFigure(figure)) {
      return totalArea;
    }

    const figureArea = compose(
      ({ radius }: CircleWall) => radius ? circleArea(radius) : polygonArea(getFigurePointsForDraw(walls, points, figure)),
      (wallId: string) => walls.get(wallId),
    )(figure.walls[0]);

    return figure.glaType === GlaType.NEGATIVE ? totalArea - figureArea : totalArea + figureArea;
  },
  0,
);

export const calculateTotalGlaArea = (
  figures: Immutable.Map<string, Figure>,
  points: Immutable.Map<string, Point>,
  walls: Immutable.Map<string, Wall>,
  includeGlaMultipler?: boolean | false,
): number => figures.valueSeq().reduce(
  (totalArea: number, figure: Figure) => {
    if (!isClosedFigure(figure)) {
      return totalArea;
    }

    const figureArea = compose(
      (area: number) => area * (includeGlaMultipler ? figure?.glaMultiplier || 1 : 1),
      ({ radius }: CircleWall) => radius ? circleArea(radius) : polygonArea(getFigurePointsForDraw(walls, points, figure)),
      (wallId: string) => walls.get(wallId),
    )(figure.walls[0]);

    switch (figure.glaType) {
      case GlaType.GLA:
        return totalArea + figureArea;
      case GlaType.NEGATIVE:
        return totalArea - figureArea;
      case GlaType.NON_GLA:
      default:
        return totalArea;
    }
  },
  0,
);

export const calculateTotalGbaArea = (
  figures: Immutable.Map<string, Figure>,
  points: Immutable.Map<string, Point>,
  walls: Immutable.Map<string, Wall>,
): number => figures.valueSeq().reduce(
  (totalArea: number, figure: Figure) => {
    if (!isClosedFigure(figure)) {
      return totalArea;
    }

    const figureArea = compose(
      (area: number) => area * (figure?.glaMultiplier || 1),
      ({ radius }: CircleWall) => radius ? circleArea(radius) : polygonArea(getFigurePointsForDraw(walls, points, figure)),
      (wallId: string) => walls.get(wallId),
    )(figure.walls[0]);

    // eslint-disable-next-line camelcase
    return figure?.meta?.include_in_gba ? totalArea + figureArea : totalArea;
  },
  0,
);
