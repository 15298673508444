import * as Immutable from 'immutable';
import {
  all, takeLatest, select, call, put,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { toast } from 'react-toastify';
import { toastConfig } from 'config/toastConfig';
import { RootState } from 'reducers/rootReducer';
import { messages } from 'config/messages';
import { gridConfig } from 'config/gridConfig';
import { geometryConfig } from 'config/geometryConfig';
import { PayloadAction } from 'types/payloadAction';
import { Point, CoordinatePoint } from 'types/point';
import { findAlignedPoints } from 'helpers/draw/findAlignedPoints';
import { getSnappedToGrid } from 'helpers/snap/snapToGrid';
import { isTooShort, getWallsWithPoint } from 'helpers/model/wallPoints';
import { DEGREES_PER_RADIAN, RADIANS_PER_DEGREE } from 'helpers/rotation';
import {
  addWallToFigure, startDrawFigure, addPreshapeWallsToFigure, addCircleWallToFigure,
} from 'ducks/draw/drawFigure';
import { actions as viewportActions, selectors as viewportSelectors } from 'ducks/viewport';
import { drawPositionedLabel } from 'ducks/draw/drawPositionedLabel';
import { drawPositionedSymbol } from 'ducks/draw/drawPositionedSymbol';
import { selectors as numpadModalSelectors } from 'ducks/modal/numpadModal';
import { selectors as pointsSelectors } from 'ducks/model/points';
import { selectors as wallsSelectors } from 'ducks/model/walls';
import { actions as settingsActions } from 'ducks/settings';
import { actions as editModeActions } from 'ducks/editMode';
import { distance } from 'helpers/geometry';
import { v4 as uuidv4 } from 'uuid';
import { compose } from 'helpers/utils';


const NAME = 'draw';
const SET_SEGMENT_START_POINT = `${NAME}/SET_SEGMENT_START_POINT`;
const SET_START_POINT = `${NAME}/SET_START_POINT`;
const SET_PREVIOUS_START_POINT = `${NAME}/SET_PREVIOUS_START_POINT`;
export const SET_START_POINT_WITHOUT_SNAP = `${NAME}/SET_START_POINT_WITHOUT_SNAP`;
const SET_DRAW_OBJECT = `${NAME}/SET_DRAW_OBJECT`;
const SET_ALIGNED_POINTS = `${NAME}/SET_ALIGNED_POINTS`;
const DRAW_POSITIONED_LABEL = `${NAME}/DRAW_POSITIONED_LABEL`;
const DRAW_POSITIONED_SYMBOL = `${NAME}/DRAW_POSITIONED_SYMBOL`;
const AUTO_FINISH_SEGMENT = `${NAME}/AUTO_FINISH_SEGMENT`;
const TOGGLE_DRAWING_INTERIOR_WALLS = `${NAME}/TOGGLE_DRAWING_INTERIOR_WALLS`;
const TOGGLE_DRAWING_PRESHAPES = `${NAME}/TOGGLE_DRAWING_PRESHAPES`;
const TOGGLE_DRAWING_STRAIGHT_WALLS = `${NAME}/TOGGLE_DRAWING_STRAIGHT_WALLS`;
const SET_ALL_DRAW_POINTS = `${NAME}/SET_ALL_DRAW_POINTS`;
const RESET_DRAW_POINTS = `${NAME}/RESET_DRAW_POINTS`;
export const START_DRAW = `${NAME}/START_DRAW`;
export const START_DRAW_WITHOUT_SNAP = `${NAME}/START_DRAW_WITHOUT_SNAP`;
export const START_PRESHAPE_DRAW = `${NAME}/START_PRESHAPE_DRAW`;
export const FINISH_SEGMENT = `${NAME}/FINISH_SEGMENT`;
export const FINISH_SHAPE = `${NAME}/FINISH_SHAPE`;
export const SET_END_POINT_WITHOUT_SNAP = `${NAME}/SET_END_POINT_WITHOUT_SNAP`;
export const SET_END_POINT = `${NAME}/SET_END_POINT`;
export const SET_PRESHAPE_POINTS = `${NAME}/SET_PRESHAPE_POINTS`;
export const SET_PRESHAPE_DEFAULTS = `${NAME}/SET_PRESHAPE_DEFAULTS`;

export const degreesToRadians = (degrees: number): number => degrees * RADIANS_PER_DEGREE;
export const radiansToDegrees = (radians: number): number => radians * DEGREES_PER_RADIAN;

const calculateAngle = compose(
  Math.asin,
  ([hypot, dy]: any): number => dy / hypot,
  ({ dx, dy }: any) => [Math.hypot(dx, dy), dy],
);

const polygonPoints = ({
  cx = 100,
  cy = 100,
  radius = 100,
  rotation = 45,
  sides = 4,
  dx = 100,
  dy = 100,
  maintainAspect = true,
}): Point[] => new Array(sides).fill({}).reduce(
  (acc, _, i) => compose(
    (): any => acc,
    (coords: Point) => acc.push(coords),
    (radians: number) => ({
      x: cx + radius * Math.cos(radians),
      y: cy + radius * Math.sin(radians),
    }),
    degreesToRadians,
    (degrees: number): number => rotation - degrees,
    // eslint-disable-next-line no-nested-ternary
    (degrees: number): number => degrees + (maintainAspect || sides !== 4
      ? 0
      : [90, 270].includes(degrees)
        ? (90 - radiansToDegrees(calculateAngle({ dx, dy })))
        : radiansToDegrees(calculateAngle({ dx, dy }))),
  )((360 / sides) * (i + 1)),
  [],
);

interface EndPointWithoutSnapping {
  endPoint: Point;
  center: boolean;
}

// Action Creators
export const actions = {
  startDraw: (startPoint: CoordinatePoint) => ({
    type: START_DRAW,
    payload: startPoint,
  }),

  startDrawWithoutSnap: (startPoint: CoordinatePoint) => ({
    type: START_DRAW_WITHOUT_SNAP,
    payload: startPoint,
  }),

  startPreshapeDraw: (startPoint: CoordinatePoint) => ({
    type: START_DRAW, // START_PRESHAPE_DRAW,
    payload: startPoint,
  }),

  finishSegment: () => ({
    type: FINISH_SEGMENT,
  }),

  finishShape: () => ({
    type: FINISH_SHAPE,
  }),

  setSegmentStartPoint: (segmentStartPoint: CoordinatePoint) => ({
    type: SET_SEGMENT_START_POINT,
    payload: segmentStartPoint,
  }),

  setStartPoint: (startPoint: CoordinatePoint) => ({
    type: SET_START_POINT,
    payload: startPoint,
  }),

  setPreviousStartPoint: (previousStartPoint: Point) => ({
    type: SET_PREVIOUS_START_POINT,
    payload: previousStartPoint,
  }),

  setStartPointWithoutSnapping: (startPoint: CoordinatePoint) => ({
    type: SET_START_POINT_WITHOUT_SNAP,
    payload: startPoint,
  }),

  setDrawObject: (objectId: string) => ({
    type: SET_DRAW_OBJECT,
    payload: objectId,
  }),

  setEndPoint: (toPoint: CoordinatePoint) => ({
    type: SET_END_POINT,
    payload: toPoint,
  }),

  setPreshapePoints: (toPoint: CoordinatePoint) => ({
    type: SET_PRESHAPE_POINTS,
    payload: toPoint,
  }),

  setPreshapeDefaults: ({ sides, rotation, maintainAspectRatio }: any) => ({
    type: SET_PRESHAPE_DEFAULTS,
    payload: { sides, rotation, maintainAspectRatio },
  }),

  setAllDrawPoints: (point: CoordinatePoint) => ({
    type: SET_ALL_DRAW_POINTS,
    payload: point,
  }),

  setAlignedPoints: (alignedPoints: Point[]) => ({
    type: SET_ALIGNED_POINTS,
    payload: alignedPoints,
  }),

  setEndPointWithoutSnapping: (endPoint: CoordinatePoint, center: boolean) => ({
    type: SET_END_POINT_WITHOUT_SNAP,
    payload: { endPoint, center },
  }),

  drawPositionedLabel: (labelId: string, at: Point | CoordinatePoint) => ({
    type: DRAW_POSITIONED_LABEL,
    payload: { labelId, at },
  }),

  drawPositionedSymbol: (symbolId: string, at: Point | CoordinatePoint) => ({
    type: DRAW_POSITIONED_SYMBOL,
    payload: { symbolId, at },
  }),

  autoFinishSegment: () => ({
    type: AUTO_FINISH_SEGMENT,
  }),

  toggleDrawingInteriorWalls: (payload: boolean) => ({
    type: TOGGLE_DRAWING_INTERIOR_WALLS,
    payload,
  }),

  toggleDrawingPreshapes: (payload: boolean) => ({
    type: TOGGLE_DRAWING_PRESHAPES,
    payload,
  }),

  setDrawingPreshapeSides: (payload: number) => ({
    type: SET_PRESHAPE_POINTS,
    payload,
  }),

  toggleDrawingStraightWalls: (payload: boolean) => ({
    type: TOGGLE_DRAWING_STRAIGHT_WALLS,
    payload,
  }),

  resetDrawPoints: () => ({
    type: RESET_DRAW_POINTS,
  }),
};

export interface DrawState {
  readonly segmentStartPoint: Point | CoordinatePoint;
  readonly startPoint: Point | CoordinatePoint;
  readonly endPoint: Point | CoordinatePoint;
  readonly previousStartPoint: Point | CoordinatePoint;
  readonly drawObjectId: string;
  readonly drawingInteriorWalls: boolean;
  readonly drawingStraightWalls: boolean;
  readonly drawingPreshapes: boolean;
  readonly alignedPoints: Point[];
  readonly snapDivision: number;
  readonly preshapePoints?: Point[];
  readonly preshapeInitialRotation?: number;
  readonly preshapeMaintainAspectRatio?: boolean | true;
}

interface DrawPositionedLabelPayload {
  readonly labelId: string;
  readonly at: Point | CoordinatePoint;
}

interface DrawPositionedSymbolPayload {
  readonly symbolId: string;
  readonly at: Point | CoordinatePoint;
}

// Initial State
const initialState: DrawState = {
  segmentStartPoint: { ...geometryConfig.origin },
  startPoint: { ...geometryConfig.origin },
  endPoint: { ...geometryConfig.origin },
  previousStartPoint: { ...geometryConfig.origin },
  drawObjectId: '',
  drawingInteriorWalls: false,
  drawingStraightWalls: false,
  drawingPreshapes: false,
  alignedPoints: [],
  snapDivision: gridConfig.defaultPrecision,
  preshapePoints: [],
};

// Reducers
const startDrawReducer = (state: DrawState, startPoint: Point): DrawState => {
  const newStartPoint = getSnappedToGrid(startPoint, state.snapDivision);

  return {
    ...state,
    startPoint: newStartPoint,
    endPoint: newStartPoint,
    previousStartPoint: state.startPoint,
    drawObjectId: '',
    alignedPoints: [],
  };
};

const startDrawWithoutSnapReducer = (state: DrawState, startPoint: Point): DrawState => ({
  ...state,
  startPoint,
  endPoint: startPoint,
  previousStartPoint: state.startPoint,
  drawObjectId: '',
  alignedPoints: [],
});

const setSegmentStartPointReducer = (state: DrawState, segmentStartPoint: Point): DrawState => ({
  ...state,
  segmentStartPoint,
});

const setStartPointReducer = (state: DrawState, startPoint: Point): DrawState => {
  const newStartPoint = getSnappedToGrid(startPoint, state.snapDivision);

  return {
    ...state,
    startPoint: newStartPoint,
    previousStartPoint: state.startPoint
  };
};

const setPreviousStartPointReducer = (state: DrawState, previousStartPoint: Point): DrawState => ({
  ...state,
  previousStartPoint
});

const setStartPointWithoutSnapReducer = (state: DrawState, startPoint: Point): DrawState => ({
  ...state,
  startPoint,
  previousStartPoint: state.startPoint
});

const setDrawObjectReducer = (state: DrawState, drawObjectId: string): DrawState => ({
  ...state,
  drawObjectId,
});

const setEndPointWithoutSnappingReducer = (
  state: DrawState, endPointWithoutSnapping: EndPointWithoutSnapping,
): DrawState => ({
  ...state,
  endPoint: endPointWithoutSnapping.endPoint,
});

const setEndPointReducer = (state: DrawState, endPoint: Point): DrawState => {
  const { startPoint, drawingStraightWalls } = state;

  let newEndPoint = { ...endPoint };

  if (drawingStraightWalls) {
    if (Math.abs(startPoint.x - endPoint.x) > Math.abs(startPoint.y - endPoint.y)) {
      newEndPoint.y = startPoint.y;
    } else {
      newEndPoint.x = startPoint.x;
    }
  }
  newEndPoint = getSnappedToGrid(newEndPoint, state.snapDivision);

  return {
    ...state,
    endPoint: newEndPoint,
  };
};

const setPreshapeDefaultsReducer = (state: DrawState, { sides, rotation, maintainAspectRatio = true }: any): DrawState => ({
  ...state,
  preshapePoints: new Array(sides).fill({}),
  preshapeInitialRotation: rotation || 0,
  preshapeMaintainAspectRatio: maintainAspectRatio,
});

const setPreshapePointsReducer = (state: DrawState, radiusPoint: Point): DrawState => {
  const {
    startPoint,
    preshapePoints,
    preshapeInitialRotation,
    preshapeMaintainAspectRatio,
  } = state;
  const {
    radius,
    dx,
    dy,
    rPoint,
  } = compose(
    // eslint-disable-next-line no-shadow
    (rPoint: Point) => ({
      radius: distance(rPoint, startPoint),
      dx: Math.abs(rPoint.x - startPoint.x),
      dy: Math.abs(rPoint.y - startPoint.y),
      rPoint,
    }),
    // eslint-disable-next-line no-shadow
    (rPoint: Point): Point => getSnappedToGrid(rPoint, state.snapDivision * 2),
  )(radiusPoint);

  const sides = preshapePoints?.length || 3;
  const rotation = preshapeInitialRotation;
  const maintainAspect = preshapeMaintainAspectRatio;

  return {
    ...state,
    preshapePoints: preshapePoints?.length
      ? polygonPoints({
        cx: startPoint.x, cy: startPoint.y, radius, rotation, sides, dx, dy, maintainAspect,
      })
      : [{ x: rPoint.x, y: rPoint.y, pointId: uuidv4() }],
  };
};

const setAllDrawPointsReducer = (state: DrawState, point: Point): DrawState => ({
  ...state,
  startPoint: point,
  endPoint: point,
  segmentStartPoint: point,
});

const setAlignedPointsReducer = (state: DrawState, alignedPoints: Point[]): DrawState => ({
  ...state,
  alignedPoints,
});

const drawPositionedLabelReducer = (
  state: DrawState,
  drawPositionedLabelData: DrawPositionedLabelPayload,
): DrawState => ({
  ...state,
  drawObjectId: drawPositionedLabelData.labelId,
  startPoint: drawPositionedLabelData.at,
});

const drawPositionedSymbolReducer = (
  state: DrawState,
  drawPositionedSymbolData: DrawPositionedSymbolPayload,
): DrawState => ({
  ...state,
  drawObjectId: drawPositionedSymbolData.symbolId,
  startPoint: drawPositionedSymbolData.at,
});

const toggleDrawingInteriorWallsReducer = (state: DrawState, drawingInteriorWalls: boolean): DrawState => ({
  ...state,
  drawingInteriorWalls,
});

const toggleDrawingPreshapesReducer = (state: DrawState, drawingPreshapes: boolean): DrawState => ({
  ...state,
  drawingPreshapes,
});

const toggleDrawingStraightWallsReducer = (state: DrawState, drawingStraightWalls: boolean): DrawState => {
  if (state.drawingStraightWalls === drawingStraightWalls) {
    return state;
  }
  return {
    ...state,
    drawingStraightWalls,
  };
};

const setSnappingReducer = (state: DrawState, snapDivision: number): DrawState => ({
  ...state,
  snapDivision,
});

export const reducer = (state: DrawState = initialState, action: PayloadAction): DrawState => {
  switch (action.type) {
    case START_DRAW:
      return startDrawReducer(state, action.payload);

    case START_DRAW_WITHOUT_SNAP:
      return startDrawWithoutSnapReducer(state, action.payload);

    case SET_DRAW_OBJECT:
      return setDrawObjectReducer(state, action.payload);

    case SET_SEGMENT_START_POINT:
      return setSegmentStartPointReducer(state, action.payload);

    case SET_START_POINT:
      return setStartPointReducer(state, action.payload);

    case SET_PREVIOUS_START_POINT:
      return setPreviousStartPointReducer(state, action.payload);

    case SET_START_POINT_WITHOUT_SNAP:
      return setStartPointWithoutSnapReducer(state, action.payload);

    case SET_END_POINT:
      return setEndPointReducer(state, action.payload);

    case SET_PRESHAPE_POINTS:
      return setPreshapePointsReducer(state, action.payload);

    case SET_PRESHAPE_DEFAULTS:
      return setPreshapeDefaultsReducer(state, action.payload);

    case SET_END_POINT_WITHOUT_SNAP:
      return setEndPointWithoutSnappingReducer(state, action.payload);

    case SET_ALL_DRAW_POINTS:
      return setAllDrawPointsReducer(state, action.payload);

    case SET_ALIGNED_POINTS:
      return setAlignedPointsReducer(state, action.payload);

    case DRAW_POSITIONED_LABEL:
      return drawPositionedLabelReducer(state, action.payload);

    case DRAW_POSITIONED_SYMBOL:
      return drawPositionedSymbolReducer(state, action.payload);

    case TOGGLE_DRAWING_INTERIOR_WALLS:
      return toggleDrawingInteriorWallsReducer(state, action.payload);

    case TOGGLE_DRAWING_PRESHAPES:
      return toggleDrawingPreshapesReducer(state, action.payload);

    case TOGGLE_DRAWING_STRAIGHT_WALLS:
      return toggleDrawingStraightWallsReducer(state, action.payload);

    case getType(settingsActions.switchPrecision):
      return setSnappingReducer(state, action.payload);

    default:
      return state;
  }
};

// Selectors
const getDrawState = (rootState: RootState): DrawState => rootState.draw;

const getSegmentStartPoint = (rootState: RootState): CoordinatePoint => getDrawState(rootState).segmentStartPoint;

const getStartPoint = (rootState: RootState): CoordinatePoint => getDrawState(rootState).startPoint;

const getPreviousStartPoint = (rootState: RootState): CoordinatePoint => getDrawState(rootState).previousStartPoint;

const getEndPoint = (rootState: RootState): CoordinatePoint => getDrawState(rootState).endPoint;

const getAlignedPoints = (rootState: RootState): Point[] => getDrawState(rootState).alignedPoints;

const isDrawingInteriorWalls = (rootState: RootState): boolean => getDrawState(rootState).drawingInteriorWalls;

const isDrawingPreshapes = (rootState: RootState): boolean => getDrawState(rootState).drawingPreshapes;

const getNumpadModalState = (rootState: RootState): NumpadModalState => rootState.numpadModal;

const isNumpadModalShowing = (rootState: RootState): boolean => getNumpadModalState(rootState).isShowing;

export const selectors = {
  getDrawState,
  isDrawingInteriorWalls,
  isDrawingPreshapes,
  getSegmentStartPoint,
  getStartPoint,
  getEndPoint,
  getAlignedPoints,
  getPreviousStartPoint,
  getNumpadModalState,
  isNumpadModalShowing
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doStartDraw() {
    const { startPoint }: DrawState = getDrawState(yield select());
    yield call(startDrawFigure, startPoint);
  }

  function* doDrawFigure() {
    const drawState: DrawState = getDrawState(yield select());
    const {
      drawObjectId, startPoint, endPoint, drawingInteriorWalls
    } = drawState;
    if (isTooShort(startPoint, endPoint, viewportSelectors.getZoomInPercent(yield select()))) {
      if (toastConfig.drawMessagesEnabled) {
        toast.warning(messages.shortWallDetected);
      }
    } else {
      yield call(
        addWallToFigure,
        drawObjectId,
        startPoint,
        endPoint,
        drawingInteriorWalls,
      );
    }
    const point = endPoint as CoordinatePoint;
    const numPadOffSet = numpadModalSelectors.isShowing(yield select()) ? 100 : 0;
    yield put(viewportActions.panToCenter({
      x: point.x,
      y: point.y + numPadOffSet,
    }));
  }

  function* doDrawShape() {
    const drawState: DrawState = getDrawState(yield select());
    const { preshapePoints, drawObjectId, startPoint } = drawState;
    if (preshapePoints?.length === 1) {
      const radius = distance(preshapePoints[0], startPoint);
      yield call(addCircleWallToFigure, drawObjectId, startPoint, radius);
    } else {
      yield call(addPreshapeWallsToFigure, drawObjectId, preshapePoints);
    }
  }

  function* doDrawPositionedLabel(): any {
    const drawState: DrawState = getDrawState(yield select());
    const positionedLabelId = yield call(drawPositionedLabel, drawState.drawObjectId, drawState.startPoint);
    yield put(editModeActions.selectObjects([positionedLabelId]));
  }

  function* doDrawPositionedSymbol(): any {
    const drawState: DrawState = getDrawState(yield select());
    const positionedSymbolId = yield call(drawPositionedSymbol, drawState.drawObjectId, drawState.startPoint);
    yield put(editModeActions.selectObjects([positionedSymbolId]));
  }

  function* doAutoFinishSegment() {
    const drawState: DrawState = getDrawState(yield select());
    yield put(actions.setEndPointWithoutSnapping(drawState.segmentStartPoint, true));
    yield put(actions.finishSegment());
  }

  function* doFindAlignedPoints() {
    const endPoint: CoordinatePoint = getEndPoint(yield select());
    const wallsIds: string[] = wallsSelectors.getWallsIds(yield select());
    const wallsPointsArray: Immutable.List<Point>[] = [];
    for (let i = 0; i < wallsIds.length; i++) {
      const wallId = wallsIds[i];
      wallsPointsArray.push(wallsSelectors.getWallPoints(yield select(), wallId));
    }
    const alignedPoints = findAlignedPoints(wallsPointsArray, endPoint);
    yield put(actions.setAlignedPoints(alignedPoints));
  }

  function* doUpdateDrawPoints() {
    const oldStartPoint = getEndPoint(yield select()) as Point;
    const newStartPoint = pointsSelectors.getPointById(yield select(), oldStartPoint.pointId);
    if (newStartPoint) {
      yield put(actions.setStartPoint(newStartPoint));
      yield put(actions.setEndPoint(newStartPoint));
    }
  }

  function* doUpdatePreviousStartPoint() {
    const oldStartPoint = getStartPoint(yield select()) as Point;
    const drawState: DrawState = getDrawState(yield select());
    if (oldStartPoint.pointId) {
      const allWalls = wallsSelectors.getAllWalls(yield select());
      const wall = getWallsWithPoint(allWalls, oldStartPoint.pointId);
      const wallPointIds = wallsSelectors.getWallPoints(yield select(), wall[0]);
      const newPreviousStartPoint = wallPointIds.get(-2);
      const isShowingNumpad = numpadModalSelectors.isShowing(yield select());

      if (newPreviousStartPoint) {
        if (isShowingNumpad) {
          yield put(actions.setPreviousStartPoint(newPreviousStartPoint));
        } else {
          const snappedNewStartPoint = getSnappedToGrid(newPreviousStartPoint, drawState.snapDivision);
          if (newPreviousStartPoint) yield put(actions.setPreviousStartPoint(snappedNewStartPoint));
        }
      }
    }
  }

  return function* saga() {
    yield all([
      takeLatest(START_DRAW, doStartDraw),
      takeLatest(START_DRAW_WITHOUT_SNAP, doStartDraw),
      takeLatest(SET_START_POINT, doUpdatePreviousStartPoint),
      takeLatest(FINISH_SEGMENT, doDrawFigure),
      takeLatest(FINISH_SHAPE, doDrawShape),
      takeLatest(DRAW_POSITIONED_LABEL, doDrawPositionedLabel),
      takeLatest(DRAW_POSITIONED_SYMBOL, doDrawPositionedSymbol),
      takeLatest(AUTO_FINISH_SEGMENT, doAutoFinishSegment),
      takeLatest(SET_END_POINT, doFindAlignedPoints),
      takeLatest(RESET_DRAW_POINTS, doUpdateDrawPoints),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
