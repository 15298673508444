/** @jsx jsx */
import React from 'react';
import { connect } from 'react-redux';
import * as Immutable from 'immutable';
import { css, jsx } from '@emotion/react';
import { v4 as uuidv4 } from 'uuid';

import { RootState } from 'reducers/rootReducer';
import { Point } from 'types/point';
import { Dimensions } from 'types/dimensions';
import { AreaType } from 'types/areaType';
import { PositionedAreaLabel } from 'types/positionedAreaLabel';
import { mapMovedPoint } from 'helpers/move/mapMovedPoint';
import { getFigureMultiplier } from 'helpers/label/areaTypeLabel';
import { actions as editModeActions, selectors as editModeSelectors } from 'ducks/editMode';
import { selectors as selectionSelectors } from 'ducks/selection/selection';
import { selectors as areaTypesSelectors } from 'ducks/model/areaTypes';
import { MousePointToSVGPointFunction, selectors as viewportSelectors } from 'ducks/viewport';
import { actions as areaTypeModalActions } from 'ducks/modal/areaTypeModal';
import { actions as subAreaModalActions } from 'ducks/modal/subAreaModal';
import { selectors as pointsSelectors } from 'ducks/model/points';
import { selectors as figuresSelectors } from 'ducks/model/figures';
import { Figure } from 'types/figure';
import ContextMenu from 'components/ContextMenu/ContextMenu';
import AreaTypeLabelText from './AreaTypeLabelText';
import AreaTypeLabelArea from './AreaTypeLabelArea';
import Rotate from '../Rotate/Rotate';
import SelectionBorder from '../SelectionBorder/SelectionBorder';

const style = css`
  fill: transparent;
  cursor: pointer;
`;

interface InputProps {
  readonly figureId: string;
  readonly positionedAreaLabel: PositionedAreaLabel | undefined;
  readonly area: number;
}

interface StateProps {
  readonly areaType?: AreaType;
  readonly figure: Figure;
  readonly isSelectMode: boolean;
  readonly isSelectedMode: boolean;
  readonly isMovingMode: boolean;
  readonly movedPoints: Immutable.List<Point>;
  readonly zoomInPercent: number;
  readonly selectedObjects: Immutable.List<string>;
  readonly getMousePointToSvgPoint: MousePointToSVGPointFunction;
  readonly areaPoint: Point;
  readonly positionedAreaLabel: PositionedAreaLabel | undefined;
  readonly glaMultiplier: number | undefined;
}

interface ActionProps {
  readonly showAreaTypeModal: (figureId: string) => void;
  readonly showSubAreaModal: (figureId: string) => void;
  readonly startMove: typeof editModeActions.switchToMoving;
  readonly switchToSelected: typeof editModeActions.switchToSelected;
}

type Props = InputProps & StateProps & ActionProps;

interface State {
  readonly textSize: Dimensions;
}
class FigureLabel extends React.Component<Props, State> {
  // eslint-disable-next-line react/sort-comp
  private readonly labelRef: React.RefObject<SVGTextElement>;

  private readonly areaTypeLabelUuid: string;

  public constructor(props: Props) {
    super(props);
    this.labelRef = React.createRef();
    this.state = { textSize: { width: 0, height: 0 } };
    this.areaTypeLabelUuid = uuidv4();
  }

  public componentDidMount = (): void => {
    this.updateSize();
  };

  public componentDidUpdate(prevProps: Props): void {
    const { zoomInPercent } = this.props;
    if (zoomInPercent !== prevProps.zoomInPercent) {
      this.updateSize();
    }
  }

  // eslint-disable-next-line react/sort-comp
  private updateSize(): void {
    const bbox = this.labelRef.current!.getBBox();
    this.setState({ textSize: { width: bbox.width, height: bbox.height } });
  }

  private handleDoubleClick = (): void => {
    const { figureId, showAreaTypeModal } = this.props;
    showAreaTypeModal(figureId);
  };

  private handleSubAreaDoubleClick = (): void => {
    const { figureId, showSubAreaModal } = this.props;
    showSubAreaModal(figureId);
  };

  private handleClick = (mouseEvent: React.MouseEvent<SVGGElement>): void => {
    if (mouseEvent.button !== 0) {
      return;
    }

    const { isSelectMode, isSelectedMode, switchToSelected, positionedAreaLabel } = this.props;
    if (isSelectMode || isSelectedMode) {
      if (positionedAreaLabel!.pointId) {
        switchToSelected([positionedAreaLabel!.pointId]);
      }
      mouseEvent.stopPropagation();
    }
  };

  private handleMouseDown = (mouseEvent: React.PointerEvent<SVGGElement>): void => {
    if (mouseEvent.button !== 0) {
      return;
    }

    const { isSelectedMode, startMove, getMousePointToSvgPoint } = this.props;

    if (isSelectedMode) {
      const point = getMousePointToSvgPoint(mouseEvent);
      startMove(point);
      mouseEvent.stopPropagation();
    }
  };

  public render = (): JSX.Element => {
    const { textSize } = this.state;
    const { areaType, isMovingMode, selectedObjects, isSelectMode, isSelectedMode, areaPoint, movedPoints, area, positionedAreaLabel, glaMultiplier, figure } =
      this.props;

    const mappedMovedPoint = mapMovedPoint(movedPoints, areaPoint);
    const p = isMovingMode ? mappedMovedPoint : areaPoint;


    const selectedAreaLabel = selectedObjects ? selectedObjects.includes(areaPoint.pointId) : false;
    const { rotation } = positionedAreaLabel!;
    const areaPosition = { ...p, y: p.y + textSize.height / 2 };

    return (
      <React.Fragment>
        <ContextMenu type={`areaTypeLabel${this.areaTypeLabelUuid}`} parentRef={this.labelRef} />
        <Rotate degrees={rotation} position={p}>
          {selectedAreaLabel && (
            <SelectionBorder
              hideHandles
              x={p.x - textSize.width / 2 - 10}
              y={p.y - textSize.height / 2 - 10}
              width={textSize.width + 20}
              height={textSize.height * 2 + 20}
            />
          )}
          {figure.isMultiFamily && (
            <g
              css={[(isSelectMode || isSelectedMode) && style]}
              onPointerDown={this.handleMouseDown}
              onClick={(mouseEvent: any) => this.handleClick(mouseEvent)}
              onDoubleClick={this.handleSubAreaDoubleClick}
            >
              <AreaTypeLabelText
                labelRef={this.labelRef}
                text={figure.subAreaText ? figure.subAreaText : 'Sub Area'}
                position={{ ...p, y: p.y - 20 }}
                selected={selectedAreaLabel}
              />
            </g>
          )}
          <g
            css={[(isSelectMode || isSelectedMode) && style]}
            onPointerDown={this.handleMouseDown}
            onClick={this.handleClick}
            onDoubleClick={this.handleDoubleClick}
          >
            <AreaTypeLabelText labelRef={this.labelRef} text={areaType ? areaType.text : ''} position={p} selected={selectedAreaLabel} />
            <AreaTypeLabelArea area={area} position={areaPosition} />
            {glaMultiplier !== undefined && glaMultiplier < 1 && (
              <React.Fragment>
                <AreaTypeLabelArea
                  area={area * (1 - glaMultiplier)}
                  position={{
                    ...areaPosition,
                    y: p.y + textSize.height + 20,
                  }}
                />
              </React.Fragment>
            )}
          </g>
        </Rotate>
      </React.Fragment>
    );
  };
}

export default connect(
  (state: RootState, { positionedAreaLabel }: InputProps): StateProps => {
    const figure = figuresSelectors.getFigureByAreaLabelPosition(state, positionedAreaLabel!.pointId);
    return {
      figure: figuresSelectors.getFigureById(state, figure.figureId),
      areaType: areaTypesSelectors.getAreaTypeById(state, figure.areaTypeId),
      areaPoint: pointsSelectors.getPointById(state, positionedAreaLabel!.pointId),
      isMovingMode: editModeSelectors.isMovingMode(state),
      movedPoints: selectionSelectors.getMovedSelectedPoints(state),
      zoomInPercent: viewportSelectors.getZoomInPercent(state),
      selectedObjects: editModeSelectors.getSelectedObjects(state),
      isSelectMode: editModeSelectors.isSelectMode(state),
      isSelectedMode: editModeSelectors.isSelectedMode(state),
      positionedAreaLabel: figuresSelectors.getAreaLabel(state, figure.figureId),
      getMousePointToSvgPoint: viewportSelectors.getMousePointToSvgPoint(state),
      glaMultiplier: getFigureMultiplier(figure),
    };
  },
  {
    showAreaTypeModal: areaTypeModalActions.showForFigure,
    showSubAreaModal: subAreaModalActions.showForFigure,
    switchToSelected: editModeActions.switchToSelected,
    startMove: editModeActions.switchToMoving,
  },
)(FigureLabel);
