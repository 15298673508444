import { CoordinatePoint, Point } from 'types/point';

import * as Immutable from 'immutable';
import { compose, coordinateExtremes, listToArray } from './utils';

interface SelectGroupBounds {
  topLeft: CoordinatePoint;
  bottomLeft: CoordinatePoint;
  topRight: CoordinatePoint;
  bottomRight: CoordinatePoint;
}

/** TODO: Update this documentation
 * Find all objects that intersect, or are inside, the select box area, and return their object IDs
 *
 * @param selectedPoints the list of points contained within the selection
 * @returns a SelectGroupBounds object that delineates the corners of the select group
 */
export const createSelectGroup = (selectedPoints: Immutable.List<Point>): SelectGroupBounds => {
  const greatestSmallestXY = compose(
    coordinateExtremes,
    listToArray,
  )(selectedPoints);

  return {
    topLeft: {
      x: greatestSmallestXY.lx,
      y: greatestSmallestXY.ly,
    },
    bottomLeft: {
      x: greatestSmallestXY.lx,
      y: greatestSmallestXY.hy,
    },
    topRight: {
      x: greatestSmallestXY.hx,
      y: greatestSmallestXY.ly,
    },
    bottomRight: {
      x: greatestSmallestXY.hx,
      y: greatestSmallestXY.hy,
    },
  };
};
