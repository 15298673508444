/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import usePortal from 'hooks/usePortal';
import { menu } from 'config/paletteConfig';
import { css, jsx } from '@emotion/react';
import { v4 as uuidv4 } from 'uuid';

interface MobileMenuProps {
  readonly menuList: any;
  readonly handleClose: any;
}

const StyledContextMenu = styled.div({
  top: '1rem',
  right: '1rem',
  width: '15rem',
  opacity: '100%',
  position: 'absolute',
  zIndex: 1000,
  backgroundColor: 'white',
  padding: '6px 0px',
  display: 'block',
  overflow: 'hidden auto',
  boxShadow: '0 2px 6px 2px rgba(60,64,67,.15)',
  borderRadius: '3px',
});


const StyledContextMenuItem = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '6px 12px',
  border: 0,
  color: menu.listItem.text,
  fontSize: 12,
  fontWeight: 600,
  textAlign: 'left',
  transition: 'background-color 0.5s ease, color 0.5s ease',

  '&:hover': {
    backgroundColor: menu.listItem.hover,
  },

  borderTop: `3px solid ${menu.listItem.border}`,
  '&:first-of-type': {
    borderTop: 'unset',
  },
});

const menuItemStyle = css({
  backgroundColor: 'unset',
  border: 'unset',
  display: 'flex',
  alignItems: 'center',
});

const contextMenuList = css({
  paddingLeft: '6px'
});

const MobileMenu = ({ menuList, handleClose }: MobileMenuProps): JSX.Element => {
  const target = usePortal('sketch-context-menu-root');

  return ReactDOM.createPortal(
    <React.Fragment>
      <StyledContextMenu>
        {menuList
          && menuList.map((contextMenuListItem: any) => (
            <StyledContextMenuItem key={uuidv4()} onClick={(event) => handleClose(event, contextMenuListItem.onClick)}>
              <button css={menuItemStyle}>
                <React.Fragment>{contextMenuListItem.icon}</React.Fragment>
                <div css={contextMenuList}>{contextMenuListItem.title}</div>
              </button>
            </StyledContextMenuItem>
          ))}
      </StyledContextMenu>
    </React.Fragment>,
    target,
  );
};

export default MobileMenu;
