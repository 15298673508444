import React from 'react';
import * as Immutable from 'immutable';

import { Point, CoordinatePoint, isPoint } from 'types/point';
import WallsAngle from 'components/sketch/Wall/WallsAngle';

interface Props {
  readonly isFigureClosed: boolean;
  readonly figurePoints: Immutable.List<Point | CoordinatePoint>;
}

const FigureAngles = ({ isFigureClosed, figurePoints }: Props): JSX.Element | null => {
  if (!figurePoints || !figurePoints.size || figurePoints.size < 3) {
    return null;
  }

  const figureAngleLabels = [];
  if (!isFigureClosed) {
    for (let i = 0; i <= figurePoints.size - 3; i++) {
      const p1 = figurePoints.get(i)!;
      const p2 = figurePoints.get(i + 1)!;
      const p3 = figurePoints.get(i + 2)!;
      const angleComponent = (
        <WallsAngle
          key={isPoint(p2) ? p2.pointId : undefined}
          wallsPoints={Immutable.List([p1, p2, p3])}
          figurePoints={figurePoints}
        />
      );
      figureAngleLabels.push(angleComponent);
    }
  }

  return (
    <>
      {figureAngleLabels}
    </>
  );
};

export default FigureAngles;
