import {
  put, all, select, call,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as Immutable from 'immutable';

import { messages } from 'config/messages';
import { Point } from 'types/point';
import { PointType } from 'types/pointType';
import { Wall } from 'types/wall';
import { WallType } from 'types/wallType';
import { ClosedFigure, Figure } from 'types/figure';
import { canRemoveMiddlePoint } from 'helpers/remove/middlePoint';
import { isTriangleRoom } from 'helpers/remove/triangle';
import { getFiguresWithWall } from 'helpers/model/figureWalls';
import { getWallsWithPoint, getWallWithoutMiddlePoint } from 'helpers/model/wallPoints';
import { updateAreaLabel } from 'ducks/moveObjects';
import { selectors as figuresSelectors, actions as figuresActions } from 'ducks/model/figures';
import { selectors as wallsSelectors, actions as wallsActions } from 'ducks/model/walls';
import { selectors as pointsSelectors, actions as pointsActions } from 'ducks/model/points';
import { actions as bluePrintImageActions } from 'ducks/bluePrintImage/bluePrintImage';
import { actions as areaTypeModalActions } from 'ducks/modal/areaTypeModal';
import { removeWall } from 'ducks/remove/removeWall';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
function* doRemoveMiddlePoint(wallsWithPoint: string[], pointId: string) {
  const walls: Immutable.Map<string, Wall> = wallsSelectors.getAllWalls(yield select());
  const figures: Immutable.Map<string, Figure> = figuresSelectors.getAllFigures(yield select());
  const prevWallId = wallsWithPoint[0];
  const nextWallId = wallsWithPoint[1];
  const figuresWithPrevWall = getFiguresWithWall(figures, prevWallId);
  const figureId = figuresWithPrevWall[0];

  // here is middle point
  const newWallPoints = getWallWithoutMiddlePoint(walls, prevWallId, nextWallId, pointId);
  const prevWall = walls.get(prevWallId)!;
  const wallType = prevWall.wallType === WallType.INTERIOR ? WallType.INTERIOR : WallType.LINE;
  yield put(wallsActions.update(prevWall.wallId, {
    wallType,
    points: newWallPoints,
  }));

  if (prevWall.wallType === WallType.ARC) {
    yield put(pointsActions.remove(prevWall.points[2]));
  }

  yield put(figuresActions.removeWall(figureId, nextWallId));
  yield put(wallsActions.remove(nextWallId));
  yield put(pointsActions.remove(pointId));

  yield all(figuresWithPrevWall.map(fId => call(updateAreaLabel, fId)));
}

function* doRemoveArcPoint(wallsWithPoint: string[], pointId: string) {
  if (wallsWithPoint.length !== 1) {
    return;
  }

  const wallId = wallsWithPoint[0];
  const wall: Wall = wallsSelectors.getWallById(yield select(), wallId);

  yield put(wallsActions.update(wall.wallId, {
    wallType: WallType.LINE,
    points: [wall.points[0], wall.points[1]],
  }));

  yield put(pointsActions.remove(pointId));
}

function* doRemoveBluePrintPoint(pointId: string) {
  yield put(bluePrintImageActions.removeImage());
  yield put(pointsActions.remove(pointId));
}

function* doRemoveLabelPoint(pointId: string) {
  const figure: ClosedFigure = figuresSelectors.getFigureByAreaLabelPosition(yield select(), pointId);
  if (!figure || !figure.positionedAreaLabel) {
    return;
  }

  yield put(areaTypeModalActions.addAreaTypeForFigure(
    figure.figureId,
    figure.areaTypeId,
    figure.glaType,
  ));

  yield put(pointsActions.remove(pointId));
}

export function* removePoint(pointId: string) {
  const walls: Immutable.Map<string, Wall> = wallsSelectors.getAllWalls(yield select());
  const wallsWithPoint = getWallsWithPoint(walls, pointId);
  const figures: Immutable.Map<string, Figure> = figuresSelectors.getAllFigures(yield select());
  if (wallsWithPoint.length > 2) {
    // we can't remove shared points
    toast.error(messages.cannotRemoveSharedPoint);
    return;
  }

  const point: Point = pointsSelectors.getPointById(yield select(), pointId);
  if (point.pointType === PointType.ARC) {
    yield call(doRemoveArcPoint, wallsWithPoint, pointId);
    return;
  }

  if (point.pointType === PointType.BLUE_PRINT_IMAGE) {
    yield call(doRemoveBluePrintPoint, pointId);
    return;
  }

  if (point.pointType === PointType.LABEL) {
    yield call(doRemoveLabelPoint, pointId);
    return;
  }

  if (canRemoveMiddlePoint(figures, wallsWithPoint)) {
    yield call(doRemoveMiddlePoint, wallsWithPoint, pointId);
    return;
  }

  if (isTriangleRoom(figures, wallsWithPoint)) {
    toast.error(messages.cannotRemovePointRoomCollapse);
    return;
  }

  yield all(wallsWithPoint.map(wallId => call(removeWall, wallId)));
  yield put(pointsActions.remove(pointId));
}
/* eslint-enable @typescript-eslint/explicit-function-return-type */
