import React from 'react';
import { connect } from 'react-redux';
import * as Immutable from 'immutable';
import booleanPointOnLine from '@turf/boolean-point-on-line';
import { point, lineString } from '@turf/helpers';

import { CoordinatePoint, Point } from 'types/point';
import { selectors as selectionSelectors } from 'ducks/selection/selection';
import { RootState } from 'reducers/rootReducer';
import { DEGREES_PER_RADIAN } from 'helpers/rotation';
import { createSelectGroup } from 'helpers/createSelectGroup';
import { getAdditionalLine } from 'helpers/draw/getHelperLine';
import { selectors as editModeSelectors } from 'ducks/editMode';
import { selectors as modelSelectors } from 'ducks/model/model';
import { selectors as boxSelectSelectors } from 'ducks/boxSelect';
import { selectors as pointsSelectors } from 'ducks/model/points';
import { selectors as positionedLabelsSelectors } from 'ducks/model/positionedLabels';
import { selectors as positionedSymbolsSelectors } from 'ducks/model/positionedSymbols';
import { getCenter } from 'helpers/geometry';

import SelectedSegmentLine from 'components/sketch/SelectedSegment/SelectedSegmentLine';
import RotateHandle from '../Rotate/RotateHandle';
import Rotate from '../Rotate/Rotate';

const getHandleRotation = (center: CoordinatePoint, lowerBottom: CoordinatePoint): number => {
  const rotation = Math.atan2(lowerBottom.y - center.y, lowerBottom.x - center.x) * DEGREES_PER_RADIAN;
  return rotation;
};

interface StateProps {
  /* eslint-disable-next-line react/no-unused-prop-types */
  readonly selectedObjects: ReturnType<typeof editModeSelectors.getSelectedObjects>;
  /* eslint-disable-next-line react/no-unused-prop-types */
  readonly selectableObjects: ReturnType<typeof modelSelectors.getSelectableObjects>;
  readonly selectedPoints: Immutable.List<Point>;
  readonly boxSelectedFormattedPositionedObjects: any[] | null;
}

const SelectGroup = (props: StateProps): JSX.Element => {
  const {
    selectedPoints,
    boxSelectedFormattedPositionedObjects,
  } = props;

  const selectGroupBounds = createSelectGroup(selectedPoints);
  const {
    topLeft, bottomLeft, topRight, bottomRight,
  } = selectGroupBounds;
  const selectGroupPolygon = Immutable.List([topLeft, bottomLeft, topRight, bottomRight]);
  const center = getCenter(selectGroupPolygon);

  // Formatted for turf's 'lineString' function
  const selectGroupLines = {
    top: [[topLeft.x, topLeft.y], [topRight.x, topRight.y]],
    left: [[topLeft.x, topLeft.y], [bottomLeft.x, bottomLeft.y]],
    bottom: [[bottomLeft.x, bottomLeft.y], [bottomRight.x, bottomRight.y]],
    right: [[topRight.x, topRight.y], [bottomRight.x, bottomRight.y]],
  };

  if (boxSelectedFormattedPositionedObjects) {
    /*
      Loop through all labels & symbols (i.e. "positioned objects") inside the select group.
      For each label/symbol, see if it is colinear with a line of the select box.
      If it is, we will increase the size of the select box by the label/symbol's width and/or height,
      depending on which line of the select group it is colinear with.
    */
    for (let i = 0; i < boxSelectedFormattedPositionedObjects.length; i++) {
      const currentFormattedPositionedObject = boxSelectedFormattedPositionedObjects[i];
      const turfPoint = point([currentFormattedPositionedObject.point.x, currentFormattedPositionedObject.point.y]);
      for (const [key, currentSelectGroupLine] of Object.entries(selectGroupLines)) {
        const turfLineString = lineString(currentSelectGroupLine);
        if (booleanPointOnLine(turfPoint, turfLineString)) {
          switch (key) {
            case 'top':
              topLeft.y -= currentFormattedPositionedObject.positionedObject.size.height;
              topRight.y -= currentFormattedPositionedObject.positionedObject.size.height;
              break;
            case 'left':
              // This 'ceil' ensures the select box doesn't expand too far, or too little, to accommodate the extreme label/symbol
              topLeft.x -= Math.ceil(currentFormattedPositionedObject.positionedObject.size.width / 2);
              bottomLeft.x -= Math.ceil(currentFormattedPositionedObject.positionedObject.size.width / 2);
              break;
            case 'bottom':
              bottomLeft.y += currentFormattedPositionedObject.positionedObject.size.height;
              bottomRight.y += currentFormattedPositionedObject.positionedObject.size.height;
              break;
            case 'right':
              topRight.x += Math.ceil(currentFormattedPositionedObject.positionedObject.size.width / 2);
              bottomRight.x += Math.ceil(currentFormattedPositionedObject.positionedObject.size.width / 2);
              break;
          }
        }
      }
    }
  }

  const selectBoxLines = {
    left: getAdditionalLine(topLeft, bottomLeft.x, bottomLeft.y),
    bottom: getAdditionalLine(bottomLeft, bottomRight.x, bottomRight.y),
    right: getAdditionalLine(bottomRight, topRight.x, topRight.y),
    top: getAdditionalLine(topLeft, topRight.x, topLeft.y),
  };

  return (
    <>
      {bottomRight && (
        <Rotate position={bottomRight} degrees={getHandleRotation(center, bottomRight)}>
          <RotateHandle
            center={center}
            position={{
              x: bottomRight.x + 5,
              y: bottomRight.y + 5,
            }}
          />
        </Rotate>
      )}
      {bottomRight && Object.entries(selectBoxLines).map(item => (
        <SelectedSegmentLine
          key={item[0]}
          wallPoints={item[1]}
        />
      ))}
    </>
  );
};

export default connect((rootState: RootState): StateProps => ({
  selectedObjects: editModeSelectors.getSelectedObjects(rootState),
  selectableObjects: modelSelectors.getSelectableObjects(rootState),
  selectedPoints: selectionSelectors.getSelectedPoints(rootState),
  boxSelectedFormattedPositionedObjects: (() => {
    const boxSelectedObjectIds = boxSelectSelectors.getBoxSelectObjectIds(rootState);

    const boxSelectedPositionedObjects = boxSelectedObjectIds.reduce((accum, boxSelectedObjectId) => {
      const positionedObject = positionedLabelsSelectors.getPositionedLabelById(rootState, boxSelectedObjectId)
       || positionedSymbolsSelectors.getPositionedSymbolById(rootState, boxSelectedObjectId);

      if (positionedObject) {
        accum.push({
          positionedObject,
          point: pointsSelectors.getPointById(rootState, positionedObject.pointId),
        });
      }

      return accum;
    }, [] as any);

    if (boxSelectedPositionedObjects.length) return boxSelectedPositionedObjects;

    return null;
  })(),
}))(SelectGroup);
