/** @jsx jsx */
import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import posed from 'react-pose';
import * as Immutable from 'immutable';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';

import { RootState } from 'reducers/rootReducer';
import { messages } from 'config/messages';
import { modal as colors, colors as tooltipColors } from 'config/paletteConfig';

import { Figure } from 'types/figure';
import { Group } from 'types/group';
import { Wall, CircleWall } from 'types/wall';
import { Point } from 'types/point';
import { Label } from 'types/label';
import { PositionedLabel } from 'types/positionedLabel';
import { Precision } from 'types/precision';

import { calculateTotalArea, calculateTotalGlaArea, calculateTotalGbaArea } from 'helpers/polygonArea';
import { formatAreaLabel } from 'helpers/pixelsToDistance';
import { listToArray } from 'helpers/utils';

import { selectors as settingsSelectors } from 'ducks/settings';
import { selectors as figuresSelectors } from 'ducks/model/figures';
import { selectors as wallsSelectors } from 'ducks/model/walls';
import { selectors as pointsSelectors } from 'ducks/model/points';
import { selectors as labelSelectors } from 'ducks/model/labels';
import { selectors as positionedLabelSelectors } from 'ducks/model/positionedLabels';
import { actions as glaBreakdownModalActions } from 'ducks/modal/glaBreakdownModal';
import { selectors as groupSelectors } from 'ducks/groupObjects';

import { ReactComponent as IconClose } from 'assets/icons/x.svg';
import { appearFromTop, DisplayPose, ButtonWithFade } from 'components/animations';
import { Modal, ButtonModalHeader as Button } from 'components/modal/Modal';
import { withTooltip } from 'components/elements/Tooltip';
import GlaBreakdownRow, { EmptyRow } from './GlaBreakdownRow';
import GroupingBreakdownRow from './GroupingBreakdownRow';
import StateProps from '../sketch/SelectedSegment/SelectedSegment';

import { sidebarItemButtonStyles } from '../sidebar/styles';

const MetaDataTooltipStyled = styled(ButtonWithFade)(sidebarItemButtonStyles, {
  boxSizing: 'content-box',
  flex: '0 0 auto',
  padding: 4,
  marginLeft: 5,
  color: 'inherit',

  '&:hover, &:focus': {
    backgroundColor: 'transparent',
    color: tooltipColors.blue,
  },
});

export const MetaDataTooltip = withTooltip(MetaDataTooltipStyled);

const ModalAnimated = posed(Modal)(appearFromTop);

const modalStyle = css`
  width: ${window.screen.width > 400 ? '500px' : '100%'};
  z-index: 1;

  h2 {
    display: flex;
    justify-content: space-between;
  }

  span {
    text-transform: capitalize;
  }
`;

const tableStyle = css`
  border-collapse: collapse;
  border-spacing: 0;

  th,
  td {
    padding: 8px 15px;
    text-align: left;
    background-color: ${colors.table.base};
    vertical-align: middle;
  }

  thead th {
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 12px;
    text-transform: uppercase;
  }

  tbody td {
    border-top: 3px solid ${colors.base};
    border-bottom: 3px solid ${colors.base};
  }

  tfoot {
    border-top: 3px solid ${colors.base};
  }

  tfoot th {
    text-align: right;
  }

  svg {
    position: relative;
    top: 1px;
    width: 12px;
    height: 12px;
    margin-right: 1ex;
  }
`;

interface InputProps {
  readonly top: number;
  readonly pose?: DisplayPose;
}

interface StateProps {
  readonly figures: Immutable.Map<string, Figure>;
  readonly walls: Immutable.Map<string, Wall | CircleWall>;
  readonly points: Immutable.Map<string, Point>;
  readonly labels: Immutable.Map<string, Label>;
  readonly positionedLabels: Immutable.Map<string, PositionedLabel>;
  readonly precision: Precision;
  readonly unitOfMeasure: string;
  readonly gridSizeInFeet: number;
  readonly groups: Immutable.Map<string, Group>;
}

interface ActionProps {
  readonly close: () => void;
}

type Props = InputProps & StateProps & ActionProps;

class GlaBreakdownModal extends React.Component<Props> {
  private close = (): void => {
    const { close } = this.props;
    close();
  };

  public render = (): JSX.Element => {
    const {
      figures, points, walls, top, pose, precision, unitOfMeasure, gridSizeInFeet, groups,
    } = this.props;

    // displays metaData attributes on hover
    // const metaData = listToArray(positionedLabels).reduce((acc: any, { labelId }: PositionedLabel): object => {
    //   const { meta } = labels.get(labelId) || {};
    //   if (meta) {
    //     Object.entries(meta).forEach(([key]) => {
    //       acc[key] = acc[key] ? acc[key] + 1 : 1;
    //     });
    //   }
    //   return acc;
    // }, {});


    let unGroupedFigures: any;
    const groupedFiguresIds: any = [];
    groups.entrySeq().forEach((group: any) => {
      const groupFiguresIds = group[1].figures;
      groupedFiguresIds.push(...groupFiguresIds);
      unGroupedFigures = figures.filter(figure => !groupedFiguresIds.includes(figure.figureId));
    });

    const totalArea = formatAreaLabel(unitOfMeasure, precision, gridSizeInFeet)(calculateTotalArea(figures, points, walls));
    const totalGLAArea = formatAreaLabel(unitOfMeasure, precision, gridSizeInFeet)(calculateTotalGlaArea(figures, points, walls, true));
    const totalGBAArea = formatAreaLabel(unitOfMeasure, precision, gridSizeInFeet)(calculateTotalGbaArea(figures, points, walls));

    return (
      <ModalAnimated top={top} right={0} pose={pose} css={modalStyle}>
        <h2>
          <Button onClick={this.close}>
            <IconClose />
          </Button>
          <span>{messages.areaBreakdownTitle}</span>
          <div />
        </h2>
        <table css={tableStyle}>
          <thead>
            <tr>
              <th>{messages.areaBreakdownName}</th>
              <th>{messages.areaBreakdownCalculation}</th>
              <th>{messages.areaBreakdownInclude}</th>
            </tr>
          </thead>
          <tbody>
            {listToArray(groups).map((group: any) => (
              <GroupingBreakdownRow key={group.groupId} group={group} positionedLabelId={group.positionedLabelId} />
            ))}
            {figures.size === 0 && <EmptyRow />}
            {groups.max() && unGroupedFigures.valueSeq().map((figure: Figure) => (
              <GlaBreakdownRow key={figure.figureId} figure={figure} walls={walls} />
            ))}
            {!groups.max() && figures.valueSeq().map((figure) => (
              <GlaBreakdownRow key={figure.figureId} figure={figure} walls={walls} />
            ))}
            <tr>
              <th>{messages.areaBreakdownTotal}</th>
              <th>{totalArea}</th>
              <th />
            </tr>
            <tr>
              <th>{messages.areaBreakdownTotalGLA}</th>
              <th>{totalGLAArea}</th>
              <th />
            </tr>
            <tr>
              <th>{messages.areaBreakdownTotalGBA}</th>
              <th>{totalGBAArea}</th>
              <th />
            </tr>
          </tbody>

          {/* displays metaData attributes on hover */}
          {/* <tfoot>
            {!!Object.keys(metaData).length && (
              <tr>
                <td />
                <td />
                <th>
                  <MetaDataTooltip tooltip={<MetaDataTooltipContent meta={metaData} />} tooltipPosition="right" css={metaDataTooltipStyles}>
                    <div style={{ fontWeight: 600 }}>
                      Attributes
                    </div>
                  </MetaDataTooltip>
                </th>
              </tr>
            )}
          </tfoot> */}
        </table>
      </ModalAnimated>
    );
  };
}

export default connect(
  (state: RootState): StateProps => ({
    figures: figuresSelectors.getNonInteriorFigures(state),
    walls: wallsSelectors.getAllWalls(state),
    points: pointsSelectors.getAllPoints(state),
    labels: labelSelectors.getAllLabels(state),
    positionedLabels: positionedLabelSelectors.getAllPositionedLabels(state),
    precision: settingsSelectors.getPrecision(state),
    unitOfMeasure: settingsSelectors.getUnitOfMeasure(state),
    gridSizeInFeet: settingsSelectors.getGridSizeInFeet(state),
    groups: groupSelectors.getAllGroups(state),
  }),
  (dispatch: Dispatch): ActionProps => bindActionCreators(
    {
      close: glaBreakdownModalActions.hide,
    },
    dispatch,
  ),
)(GlaBreakdownModal);
