import * as Immutable from 'immutable';
import {
  all, call, debounce, put, select, take, takeLatest, takeEvery,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { RootState } from 'reducers/rootReducer';
import { PayloadAction } from 'types/payloadAction';
import { SketchModel } from 'types/sketchModel';
import { hasClosedFigure } from 'helpers/history/history';
import { actions as sketchPersistenceActions, MODEL_REPLACED } from 'ducks/persistence/sketchPersistence';
import { selectors as modelSelectors } from 'ducks/model/model';
import { actions as sidebarActions } from 'ducks/sidebar/sidebar';
import { actions as modalActions } from 'ducks/modal/areaTypeModal';
// Actions to track
import { actions as pagesActions } from 'ducks/model/pages';
import { ADD_WALL, UPDATE as UPDATE_FIGURE } from 'ducks/model/figures';
import { actions as positionedLabelsActions } from 'ducks/model/positionedLabels';
import { ADD as ADD_SYMBOL, UPDATE as UPDATE_SYMBOL, REMOVE as REMOVE_SYMBOL } from 'ducks/model/positionedSymbols';
import { REMOVE as REMOVE_WALL } from 'ducks/model/walls';
import { MOUNTED } from 'ducks/mount';
import { OBJECT_MOVED } from 'ducks/moveObjects';
import { OBJECT_RESIZED } from 'ducks/resizeObjects';
import { END_ROTATE } from 'ducks/rotateObjects';
import { CURVE_ADDED } from 'ducks/draw/drawCurve';
import { actions as bluePrintImageActions } from 'ducks/bluePrintImage/bluePrintImage';

const TRACKED_ACTIONS = [
  MOUNTED,
  getType(pagesActions.add),
  getType(pagesActions.update),
  getType(pagesActions.remove),
  getType(pagesActions.upsertObject),
  getType(pagesActions.removeObject),
  ADD_WALL,
  REMOVE_WALL,
  UPDATE_FIGURE,
  positionedLabelsActions.add,
  positionedLabelsActions.update,
  positionedLabelsActions.remove,
  ADD_SYMBOL,
  UPDATE_SYMBOL,
  REMOVE_SYMBOL,
  OBJECT_MOVED,
  OBJECT_RESIZED,
  END_ROTATE,
  CURVE_ADDED,
  getType(bluePrintImageActions.addImage),
  getType(bluePrintImageActions.removeImage),
];

const MAX_HISTORY_STEPS = 50;

// Action Types
const NAME = 'history';

const STORE_STATE = `${NAME}/STORE_STATE`;
const UPDATE_HISTORY = `${NAME}/UPDATE_HISTORY`;
const UNDO = `${NAME}/UNDO`;
const REDO = `${NAME}/REDO`;
const CLEAR = `${NAME}/CLEAR`;
const START_RECORDING = `${NAME}/START_RECORDING`;
const STOP_RECORDING = `${NAME}/STOP_RECORDING`;
const SET_HISTORY_INDEX = `${NAME}/SET_HISTORY_INDEX`;

export interface HistoryState {
  // stores previous models, from latest to oldest
  readonly modelHistory: Immutable.List<SketchModel>;

  // indicated where in the model history we currently are
  readonly historyIndex: number;

  // indicates whether to store new model to the history when a change is detected
  // used to ignore incremental model changes during undoing/redoing
  readonly recording: boolean;
}

// Initial State
const initialState: HistoryState = {
  modelHistory: Immutable.List<SketchModel>(),
  recording: true,
  historyIndex: 0,
};

// Action Creators
export const actions = {
  storeState: (sketchModel: SketchModel) => ({
    type: STORE_STATE,
    payload: sketchModel,
  }),

  updateHistory: () => ({
    type: UPDATE_HISTORY,
  }),

  undo: () => ({
    type: UNDO,
  }),

  redo: () => ({
    type: REDO,
  }),

  clear: () => ({
    type: CLEAR,
  }),

  startRecording: () => ({
    type: START_RECORDING,
  }),

  stopRecording: () => ({
    type: STOP_RECORDING,
  }),

  setHistoryIndex: (historyIndex: number) => ({
    type: SET_HISTORY_INDEX,
    payload: historyIndex,
  }),
};

// Selectors
const getHistory = (rootState: RootState): HistoryState => rootState.history;

const getModelHistory = (rootState: RootState): Immutable.List<SketchModel> => getHistory(rootState).modelHistory;

const getHistoryIndex = (rootState: RootState): number => getHistory(rootState).historyIndex;

const isRecording = (rootState: RootState): boolean => getHistory(rootState).recording;

const canUndo = (rootState: RootState): boolean => {
  const { modelHistory, historyIndex } = getHistory(rootState);
  return modelHistory.size > historyIndex + 1;
};

const canRedo = (rootState: RootState): boolean => getHistory(rootState).historyIndex > 0;

export const selectors = {
  getHistory,
  getModelHistory,
  getHistoryIndex,
  isRecording,
  canUndo,
  canRedo,
};

// Reducers
const storeStateReducer = (state: HistoryState, sketchModel: SketchModel): HistoryState => {
  const { modelHistory, historyIndex } = state;
  let newModelHistory = modelHistory
    .slice(historyIndex) // discard the changes that user undo'd if they make a new change to the model
    .unshift(sketchModel); // add new model to the beginning of the history
  // trim the history if it's too long
  newModelHistory = newModelHistory.setSize(Math.min(newModelHistory.size, MAX_HISTORY_STEPS));

  return {
    ...state,
    historyIndex: 0,
    modelHistory: newModelHistory,
  };
};

const startRecordingReducer = (state: HistoryState): HistoryState => ({
  ...state,
  recording: true,
});

const stopRecordingReducer = (state: HistoryState): HistoryState => ({
  ...state,
  recording: false,
});

const setHistoryIndexReducer = (state: HistoryState, historyIndex: number): HistoryState => ({
  ...state,
  historyIndex,
});

const clearReducer = (): HistoryState => initialState;

export const reducer = (state: HistoryState = initialState, action: PayloadAction): HistoryState => {
  switch (action.type) {
    case STORE_STATE:
      return storeStateReducer(state, action.payload);
    case START_RECORDING:
      return startRecordingReducer(state);
    case STOP_RECORDING:
      return stopRecordingReducer(state);
    case SET_HISTORY_INDEX:
      return setHistoryIndexReducer(state, action.payload);
    case CLEAR:
      return clearReducer();
    default:
      return state;
  }
};

// Sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doProcessAction() {
    const isRecordingHistory: boolean = isRecording(yield select());
    if (!isRecordingHistory) {
      return;
    }
    yield put(actions.updateHistory());
  }

  function* doUpdateHistory() {
    const model: SketchModel = modelSelectors.getModel(yield select());
    yield put(actions.storeState(model));
    yield put(sketchPersistenceActions.setChanged(true));
  }

  function* moveInHistory(stepSize: number) {
    yield put(actions.stopRecording());

    const historyState: HistoryState = getHistory(yield select());

    let oldHistoryIndex = historyState.historyIndex;
    let newHistoryIndex = oldHistoryIndex + stepSize;
    let oldModel: SketchModel = historyState.modelHistory.get(oldHistoryIndex)!;
    let newModel: SketchModel = historyState.modelHistory.get(newHistoryIndex)!;

    while (hasClosedFigure(stepSize, oldModel, newModel)) {
      // move from non-consistent figure state
      oldHistoryIndex = newHistoryIndex;
      newHistoryIndex = oldHistoryIndex + stepSize;

      oldModel = historyState.modelHistory.get(oldHistoryIndex)!;
      newModel = historyState.modelHistory.get(newHistoryIndex)!;
    }

    yield put(sketchPersistenceActions.replaceModel(newModel));
    yield take(MODEL_REPLACED);

    // hide sidebars or modals that could be linked to now non-existing object
    yield put(sidebarActions.hide());
    yield put(modalActions.hide());

    yield put(actions.setHistoryIndex(newHistoryIndex));
    yield put(actions.startRecording());
  }

  function* doUndo() {
    yield call(moveInHistory, +1);
  }

  function* doRedo() {
    yield call(moveInHistory, -1);
  }

  return function* saga() {
    yield all([debounce(15, UPDATE_HISTORY, doUpdateHistory), takeEvery(TRACKED_ACTIONS, doProcessAction), takeLatest(UNDO, doUndo), takeLatest(REDO, doRedo)]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
