import * as Immutable from 'immutable';

import { CoordinatePoint, Point } from 'types/point';
import { getSnappedToGrid } from 'helpers/snap/snapToGrid';
import { snapLineToGrid } from 'helpers/snap/snapLineToGrid';
import { snapFigureToGrid } from 'helpers/snap/snapFigureToGrid';
import { movePolygon } from 'helpers/move/movePolygon';
import { getArcPoint, getCurveByArcPoint } from 'helpers/curve/getArcPoint';

export const moveAndSnapPoint = <T extends CoordinatePoint>(p: T, d: CoordinatePoint, snapDivision: number): T => {
  const snapped = getSnappedToGrid({
    ...p,
    x: p.x + d.x,
    y: p.y + d.y,
  }, snapDivision);

  return snapped;
};

/**
 * user move arc point
 * we should calculate new position for middle arc point
 */
export const moveArcPoint = <T extends CoordinatePoint>(wallPoints: Immutable.List<T>, d: CoordinatePoint): T => {
  const p1 = wallPoints.get(0)!;
  const p2 = wallPoints.get(1)!;
  let arcPoint = wallPoints.get(2)!;
  arcPoint = {
    ...arcPoint,
    x: arcPoint.x + d.x,
    y: arcPoint.y + d.y,
  };
  const curveParameters = getCurveByArcPoint(p1, p2, arcPoint);
  const changedArcPoint = getArcPoint(p1, p2, curveParameters.arcHeight);

  return {
    ...arcPoint,
    x: changedArcPoint.x,
    y: changedArcPoint.y,
  };
};

/**
 * user move some oth corner of the arc wall
 * we should calculate new position for middle arc point
 */
export const moveArcPointByCorner = <T extends CoordinatePoint>(
  wallPoints: Immutable.List<T>,
  changedWallPoints: Immutable.List<T>,
): T => {
  const p1 = wallPoints.get(0)!;
  const p2 = wallPoints.get(1)!;
  const arcPoint = wallPoints.get(2)!;

  const curveParameters = getCurveByArcPoint(p1, p2, arcPoint);
  const p1Changed = changedWallPoints.get(0)!;
  const p2Changed = changedWallPoints.get(1)!;

  const changedArcPoint = getArcPoint(p1Changed, p2Changed, curveParameters.arcHeight);

  return {
    ...arcPoint,
    x: changedArcPoint.x,
    y: changedArcPoint.y,
  };
};

export const moveAndSnapWall = <T extends CoordinatePoint>(
  wallPoints: Immutable.List<T>, d: CoordinatePoint, snapDivision: number,
): Immutable.List<T> => {
  const p1: T = wallPoints.first();
  let otherPoints = wallPoints.shift();
  const p2: T = otherPoints.first();
  otherPoints = otherPoints.shift();

  const snapped = snapLineToGrid(p1, p2, d, snapDivision);
  return Immutable.List([
    snapped.p1,
    snapped.p2,
    ...otherPoints.toArray().map(p => moveAndSnapPoint(p, d, snapDivision)),
  ]);
};

export const moveAndSnapFigure = (
  figurePoints: Immutable.List<Point>, d: CoordinatePoint, snapDivision: number,
): Immutable.List<Point> => {
  const d1 = snapFigureToGrid(figurePoints, d, snapDivision);
  const movedPoints = movePolygon(figurePoints, d1.x, d1.y);

  return movedPoints;
};
