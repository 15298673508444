import * as Immutable from 'immutable';

import { SketchModel } from 'types/sketchModel';
import { Dimensions } from 'types/dimensions';
import { ApiSketch } from 'types/api/apiSketch';
import { selectors as modelSelectors } from 'ducks/model/model';
import { selectors as viewportSelectors } from 'ducks/viewport';
import { selectors as sketchPersistenceSelectors } from 'ducks/persistence/sketchPersistence';
import { RootState } from 'reducers/rootReducer';
import { buildPreviewPage } from 'helpers/save/buildPreviewPage';
import {
  compose,
  filterBy,
  isSomething,
} from 'helpers/utils';
import { v4 as uuidv4 } from 'uuid';

/**
 * convert model to json model for save
 */
export const saveSketchData = async (
  model: SketchModel,
  id: string,
  totalGla: number,
  svg: SVGSVGElement | null,
  svgSize: Dimensions,
  sketchName: string,
  propertyAddress: string,
  orderId: string,
): Promise<ApiSketch> => {
  const dataUri = svg
    ? await Promise.all(
      model.pages.valueSeq().map(async (page) => (buildPreviewPage(page?.previewPngs?.sketch, page?.previewPngs?.areaTable))),
    ) : [];

  const images: string[] = compose(
    filterBy(isSomething),
  )(dataUri);

  return ({
    id,
    order_id: orderId,
    last_modified_date: new Date().toJSON(),
    property_address: propertyAddress,
    sketch_name: sketchName,
    images,
    data: {
      ...Immutable.fromJS(model).toJS(),
      totalGla,
    },
  });
};

export const saveSketch = async (rootState: RootState, sketchName: string, propertyAddress: string, orderId: string): Promise<ApiSketch> => {
  const currentSketch = sketchPersistenceSelectors.getSketch(rootState)
  const model = modelSelectors.getModelForSave(rootState);
  const svgElement = viewportSelectors.getSvgElement(rootState);
  const svgSize = viewportSelectors.getSvgSize(rootState);
  const totalGla = model.pages.reduce((acc: any, index) => acc + index.totalGla, 0);

  if (!currentSketch.id) {
    const id = uuidv4();
    return saveSketchData(model, id, totalGla, svgElement, svgSize, sketchName, propertyAddress, orderId);
  }
  return saveSketchData(model, currentSketch.id, totalGla, svgElement, svgSize, sketchName, propertyAddress, orderId);
};
