import * as Immutable from 'immutable';

import { Point, CoordinatePoint } from 'types/point';
import { SizingInfo } from 'types/sizingInfo';
import { PositioningInfo } from 'types/positioningInfo';
import { PositionedLabel } from 'types/positionedLabel';
import { PositionedSymbol } from 'types/positionedSymbol';
import { Dimensions } from 'types/dimensions';
import { mapMovedPoint } from 'helpers/move/mapMovedPoint';

type CommonPositionedProps = 'size' | 'rotation'
type PositionedObject
  = Pick<PositionedLabel, CommonPositionedProps | 'fontScale'>
  | Pick<PositionedSymbol, CommonPositionedProps>

export const determinePositioning = (
  objectId: string, object: PositionedObject, currentPosition: Point,
  movedPoints: Immutable.List<Point>,
  objectSizes: Immutable.Map<string, SizingInfo>,
  isMovingMode: boolean, isResizingMode: boolean,
): PositioningInfo => {
  const resized = objectSizes.get(objectId);
  // eslint-disable-next-line no-nested-ternary
  const position = isResizingMode && resized
    ? resized.position
    : isMovingMode
      ? mapMovedPoint(movedPoints, currentPosition)
      : currentPosition;

  const size = isResizingMode && resized
    ? resized.size
    : (object && object.size) || { width: 0, height: 0 };

  const fontScale = (isResizingMode && resized && 'fontScale' in object)
    ? resized.fontScale
    : (object && (object as PositionedLabel).fontScale) || 100;

  const rotation = object.rotation ?? 0;

  return {
    position, size, rotation, fontScale,
  };
};

export const getPositioningWithoutRotation = (
  objectId: string, object: PositionedObject, currentPosition: Point,
  movedPoints: Immutable.List<Point>,
  objectSizes: Immutable.Map<string, SizingInfo>,
  isMovingMode: boolean, isResizingMode: boolean,
): { size: Dimensions; position: CoordinatePoint; topLeftCorner: CoordinatePoint} => {
  const positioning = determinePositioning(
    objectId, object, currentPosition,
    movedPoints, objectSizes,
    isMovingMode, isResizingMode,
  );

  const topLeftCorner = {
    x: positioning.position.x - positioning.size.width / 2,
    y: positioning.position.y - positioning.size.height / 2,
  };

  return {
    size: positioning.size,
    position: positioning.position,
    topLeftCorner,
  };
};
