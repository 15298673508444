import * as Immutable from 'immutable';

import { Page } from 'types/page';
import { Figure } from 'types/figure';
import { Point } from 'types/point';
import { SketchModel } from 'types/sketchModel';
import { Wall } from 'types/wall';
import { Label } from 'types/label';
import { AreaType } from 'types/areaType';
import { PositionedLabel } from 'types/positionedLabel';
import { PositionedSymbol } from 'types/positionedSymbol';
import { ApiSketch } from 'types/api/apiSketch';
import { Group } from '../../types/group';

/**
 * convert model to json model for save
 */
export const loadSketch = (
  modelJson: ApiSketch,
  areaTypes: Immutable.Map<string, AreaType>,
): SketchModel => {
  const { data } = modelJson;

  const model: SketchModel = {
    pages: Immutable.Map<string, Page>(data.pages),
    pagesOrder: data.pagesOrder,
    groups: Immutable.Map<string, Group>(data.groups),
    points: Immutable.Map<string, Point>(data.points),
    walls: Immutable.Map<string, Wall>(data.walls),
    figures: Immutable.Map<string, Figure>(data.figures),
    areaTypes,
    labels: Immutable.Map<string, Label>(data.labels),
    positionedLabels: Immutable.Map<string, PositionedLabel>(data.positionedLabels),
    positionedSymbols: Immutable.Map<string, PositionedSymbol>(data.positionedSymbols),
  };

  return model;
};
