import * as Immutable from 'immutable';
import { createAction } from 'typesafe-actions';

import { RootState } from 'reducers/rootReducer';
import { PayloadAction } from 'types/payloadAction';
import { SketchSymbolGroup } from 'types/sketchSymbolGroup';
import { compose } from 'helpers/utils';

// Action Types
const NAME = 'symbolGroups';

const ADD = `${NAME}/ADD`;
const SET = `${NAME}/SET`;

export interface SymbolGroupsState {
  readonly symbolGroup: Immutable.Map<string, SketchSymbolGroup>;
}

interface SetSymbolGroupsPayload {
  clear: boolean;
  symbolGroups: SketchSymbolGroup[];
}

// Initial State
const initialState: SymbolGroupsState = {
  symbolGroup: Immutable.Map<string, SketchSymbolGroup>(),
};

// Action Creators
export const actions = {
  add: (symbolGroup: SketchSymbolGroup) => ({
    type: ADD,
    payload: symbolGroup,
  }),
  set: createAction(SET)<SetSymbolGroupsPayload>(),
};

// Selectors
const getSymbolGroupsState = (rootState: RootState): SymbolGroupsState => rootState.symbolGroups;

const getAllSymbolGroups = (
  rootState: RootState,
): Immutable.Map<string, SketchSymbolGroup> => getSymbolGroupsState(rootState).symbolGroup;

const getSymbolGroupById = (
  rootState: RootState,
  symbolGroupId: string,
): SketchSymbolGroup => getAllSymbolGroups(rootState).get(symbolGroupId)!;

export const selectors = {
  getAllSymbolGroups,
  getSymbolGroupById,
};

// Reducers
const addSymbolGroupReducer = (state: SymbolGroupsState, symbolGroup: SketchSymbolGroup): SymbolGroupsState => ({
  ...state,
  symbolGroup: state.symbolGroup.set(symbolGroup.symbolGroupId, symbolGroup),
});

const setSymbolGroupsReducer = (state: SymbolGroupsState, { clear, symbolGroups }: SetSymbolGroupsPayload): SymbolGroupsState => ({
  ...state,
  symbolGroup: compose(
    (newMap: Map<string, SketchSymbolGroup>) => clear ? newMap : state.symbolGroup.merge(newMap),
  )(Immutable.Map<string, SketchSymbolGroup>(symbolGroups.map((symbolGroup) => [symbolGroup.symbolGroupId, symbolGroup]))),
});

export const reducer = (state: SymbolGroupsState = initialState, action: PayloadAction): SymbolGroupsState => {
  switch (action.type) {
    case SET:
      return setSymbolGroupsReducer(state, action.payload);
    case ADD:
      return addSymbolGroupReducer(state, action.payload);
    default:
      return state;
  }
};
