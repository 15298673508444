import * as Immutable from 'immutable';

import { RootState } from 'reducers/rootReducer';
import { PayloadAction } from 'types/payloadAction';
import { PositionedSymbol } from 'types/positionedSymbol';
import { Dimensions } from 'types/dimensions';

// Action Types
const NAME = 'positionedSymbols';

const RESIZE = `${NAME}/RESIZE`;
export const ADD = `${NAME}/ADD`;
export const UPDATE = `${NAME}/UPDATE`;
export const REMOVE = `${NAME}/REMOVE`;
const CLEAR = `${NAME}/CLEAR`;

export interface PositionedSymbolsState {
  readonly positionedSymbols: Immutable.Map<string, PositionedSymbol>;
}

// Initial State
const initialState: PositionedSymbolsState = {
  positionedSymbols: Immutable.Map<string, PositionedSymbol>(),
};

// Action Creators
export const actions = {
  add: (symbol: PositionedSymbol) => ({
    type: ADD,
    payload: symbol,
  }),

  update: (symbol: PositionedSymbol) => ({
    type: UPDATE,
    payload: symbol,
  }),

  remove: (positionedSymbolId: string) => ({
    type: REMOVE,
    payload: positionedSymbolId,
  }),

  clear: () => ({
    type: CLEAR,
  }),

  resize: (positionedSymbolId: string, size: Dimensions) => ({
    type: RESIZE,
    payload: { positionedSymbolId, size },
  }),
};

// Selectors
const getPositionedSymbolsState = (rootState: RootState): PositionedSymbolsState => rootState.positionedSymbols;

const getAllPositionedSymbols = (
  rootState: RootState,
): Immutable.Map<string, PositionedSymbol> => getPositionedSymbolsState(rootState).positionedSymbols;

const getPositionedSymbolsIds = (
  rootState: RootState,
): string[] => getAllPositionedSymbols(rootState).keySeq().toArray();

const getPositionedSymbolById = (
  rootState: RootState,
  positionedSymbolId: string,
): PositionedSymbol => getAllPositionedSymbols(rootState).get(positionedSymbolId)!;

export const selectors = {
  getAllPositionedSymbols,
  getPositionedSymbolsIds,
  getPositionedSymbolById,
};

// Reducers
const addSymbolReducer = (state: PositionedSymbolsState, symbol: PositionedSymbol): PositionedSymbolsState => ({
  ...state,
  positionedSymbols: state.positionedSymbols.set(symbol.positionedSymbolId, symbol),
});

const removeSymbolReducer = (state: PositionedSymbolsState, positionedSymbolId: string): PositionedSymbolsState => ({
  ...state,
  positionedSymbols: state.positionedSymbols.remove(positionedSymbolId),
});

const clearSymbolReducer = (state: PositionedSymbolsState): PositionedSymbolsState => ({
  ...state,
  positionedSymbols: Immutable.Map<string, PositionedSymbol>(),
});

const resizeSymbolReducer = (
  state: PositionedSymbolsState,
  positionedSymbolId: string,
  size: Dimensions,
): PositionedSymbolsState => {
  const positionedSymbol = state.positionedSymbols.get(positionedSymbolId);
  if (positionedSymbol) {
    return {
      ...state,
      positionedSymbols: state.positionedSymbols.set(positionedSymbolId, {
        ...positionedSymbol,
        size,
      }),
    };
  }
  return state;
};

export const reducer = (
  state: PositionedSymbolsState = initialState,
  action: PayloadAction,
): PositionedSymbolsState => {
  switch (action.type) {
    case ADD:
    case UPDATE:
      return addSymbolReducer(state, action.payload);

    case REMOVE:
      return removeSymbolReducer(state, action.payload);

    case RESIZE:
      return resizeSymbolReducer(state, action.payload.positionedSymbolId, action.payload.size);

    case CLEAR:
      return clearSymbolReducer(state);

    default:
      return state;
  }
};
