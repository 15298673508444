import * as Immutable from 'immutable';
import { RootState } from 'reducers/rootReducer';
import { Point } from 'types/point';
import { Wall } from 'types/wall';
import { Figure } from 'types/figure';
import { PositionedLabel } from 'types/positionedLabel';
import { PositionedSymbol } from 'types/positionedSymbol';
import { GeometryType } from 'types/geometryType';
import { SketchModel } from 'types/sketchModel';
import { SelectableObjects } from 'types/selection';
import { selectors as groupObjectSelectors } from 'ducks/groupObjects';
import { selectors as figuresSelectors } from 'ducks/model/figures';
import { selectors as wallsSelectors } from 'ducks/model/walls';
import { selectors as pointsSelectors } from 'ducks/model/points';
import { selectors as areaTypesSelectors } from 'ducks/model/areaTypes';
import { selectors as labelsSelectors } from 'ducks/model/labels';
import { selectors as pagesSelectors } from 'ducks/model/pages';
import { selectors as positionedLabelsSelectors } from 'ducks/model/positionedLabels';
import { selectors as positionedSymbolsSelectors } from 'ducks/model/positionedSymbols';
import { selectors as bluePrintImageSelectors } from 'ducks/bluePrintImage/bluePrintImage';
import { selectors as selectionSelectors } from 'ducks/selection/selection';
import { getConnectedComponent } from 'helpers/path/getConnectedComponent';
import { selectors as drawSelectors } from 'ducks/draw/draw';

const getModelObject = (
  rootState: RootState,
  objectId: string,
): Point | Wall | Figure | PositionedLabel | PositionedSymbol | undefined => {
  const points = pointsSelectors.getAllPoints(rootState);
  const point = points.get(objectId);
  if (point) {
    return point;
  }

  const walls = wallsSelectors.getAllWalls(rootState);
  const wall = walls.get(objectId);
  if (wall) {
    return wall;
  }

  const figures = figuresSelectors.getAllFigures(rootState);
  const figure = figures.get(objectId);
  if (figure) {
    return figure;
  }

  const positionedLabels = positionedLabelsSelectors.getAllPositionedLabels(rootState);
  const positionedLabel = positionedLabels.get(objectId);
  if (positionedLabel) {
    return positionedLabel;
  }

  const positionedSymbols = positionedSymbolsSelectors.getAllPositionedSymbols(rootState);
  return positionedSymbols.get(objectId);
};

const getGeometryType = (rootState: RootState, objectId: string): GeometryType => {
  const points = pointsSelectors.getAllPoints(rootState);
  if (points.has(objectId)) {
    return GeometryType.POINT;
  }

  const walls = wallsSelectors.getAllWalls(rootState);
  if (walls.has(objectId)) {
    return GeometryType.WALL;
  }

  const figures = figuresSelectors.getAllFigures(rootState);
  if (figures.has(objectId)) {
    return GeometryType.FIGURE;
  }

  const positionedLabels = positionedLabelsSelectors.getAllPositionedLabels(rootState);
  if (positionedLabels.has(objectId)) {
    return GeometryType.POSITIONED_LABEL;
  }

  const positionedSymbols = positionedSymbolsSelectors.getAllPositionedSymbols(rootState);
  if (positionedSymbols.has(objectId)) {
    return GeometryType.POSITIONED_SYMBOL;
  }

  return GeometryType.UNKNOWN;
};

const getSelectableObjects = (rootState: RootState): SelectableObjects => ({
  points: pointsSelectors.getAllPoints(rootState),
  walls: wallsSelectors.getAllWalls(rootState),
  figures: figuresSelectors.getAllFigures(rootState),
  positionedLabels: positionedLabelsSelectors.getAllPositionedLabels(rootState),
  positionedSymbols: positionedSymbolsSelectors.getAllPositionedSymbols(rootState),
});

const getModelForSave = (rootState: RootState): SketchModel => ({
  pages: pagesSelectors.getAllPagesAsMap(rootState),
  pagesOrder: pagesSelectors.getPageIds(rootState),
  points: pointsSelectors.getAllPoints(rootState),
  walls: wallsSelectors.getAllWalls(rootState),
  figures: figuresSelectors.getAllFigures(rootState),
  areaTypes: areaTypesSelectors.getAllAreaTypes(rootState),
  labels: labelsSelectors.getTextLabels(rootState),
  positionedLabels: positionedLabelsSelectors.getAllPositionedLabels(rootState),
  positionedSymbols: positionedSymbolsSelectors.getAllPositionedSymbols(rootState),
  groups: groupObjectSelectors.getAllGroups(rootState),
  drawState: drawSelectors.getDrawState(rootState)
});

const getModel = (rootState: RootState): SketchModel => ({
  ...getModelForSave(rootState),
  image: bluePrintImageSelectors.getImageForSketchModel(rootState),
});

const getConnectedAndInnerPoints = (rootState: RootState, pointId: string): Immutable.List<Point> => {
  const walls: Immutable.Map<string, Wall> = wallsSelectors.getAllWalls(rootState);
  const connectedComponentPoints = getConnectedComponent(walls, pointId, []);

  const allPoints: Immutable.List<Point> = pointsSelectors.getAllPointsList(rootState);
  let points = allPoints.filter((p: Point) => connectedComponentPoints.includes(p.pointId));

  const figuresIds: string[] = selectionSelectors.getChangedFigures(rootState, points);
  for (let i = 0; i < figuresIds.length; i++) {
    const figureId = figuresIds[i];
    const pointsIds = points.map(p => p.pointId);

    // add all inner points
    const innerPoints: Immutable.List<Point> = figuresSelectors
      .getInnerFigurePoints(rootState, figureId, pointsIds.toArray());
    points = points.concat(innerPoints);

    // add area label, if it is not added already
    // area label could be moved outside the figure
    const areaLabelPosition: (Point | undefined) = figuresSelectors.getAreaLabelPosition(rootState, figureId);
    if (areaLabelPosition) {
      if (!points.find(p => p.pointId === areaLabelPosition.pointId)) {
        points = points.push(areaLabelPosition);
      }
    }

    const figureArcPoints: Immutable.List<Point> = figuresSelectors.getFigureArcPoints(rootState, figureId);

    points = points.concat(figureArcPoints);
  }

  return points;
};

export const selectors = {
  getModelObject,
  getGeometryType,
  getSelectableObjects,
  getModelForSave,
  getModel,
  getConnectedAndInnerPoints,
};
