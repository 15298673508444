/** @jsx jsx */
import React, { Component } from 'react';
import { BlobProvider } from '@react-pdf/renderer';
import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import posed from 'react-pose';
import { css, jsx } from '@emotion/react';

import { RootState } from 'reducers/rootReducer';
import { messages, printPreview } from 'config/messages';
import { Page } from 'types/page';
import { selectors as printPreviewModalSelectors, actions as printPreviewModalActions } from 'ducks/modal/printPreviewModal';
import { actions as pageGroupsModalActions } from 'ducks/modal/pageGroupsModal';
import { selectors as pagesSelectors } from 'ducks/model/pages';

import { ReactComponent as IconClose } from 'assets/icons/x.svg';
import { ReactComponent as IconBack } from 'assets/icons/arrow-back.svg';
import { ReactComponent as IconForward } from 'assets/icons/arrow-forward.svg';
import { ReactComponent as IconPrinterBlack } from 'assets/icons/printer-black.svg';
import { ReactComponent as IconTable } from 'assets/icons/table.svg';
import { ReactComponent as IconTableSelected } from 'assets/icons/table-selected.svg';
import { fade, scaleIn } from 'components/animations';
import { ModalPrintPreview, ModalBackdrop } from 'components/modal/Modal';
import PrintPreviewSketch from 'components/modal/PrintPreviewSketch';
import SketchPrintPdf from 'components/modal/SketchPrintPdf';
import { buildAreaTablePreviewPage, buildPreviewPage } from 'helpers/save/buildPreviewPage';

const prevStyle = css`
  position: absolute;
  top: 50%;
  left: -80px;
  transform: translateY(-50%);
`;

const nextStyle = css`
  position: absolute;
  top: 50%;
  right: -80px;
  transform: translateY(-50%);
`;

const closeStyle = css`
  margin: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fafafa;
`;

const headerStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const pageNumStyle = css`
  position: relative !important;
  padding-top: 32px;
`;

const printSketchStyle = css`
  text-decoration: none;
`;

const previewContainerStyle = css`
  overflow-y: auto;
`;

const Poser = posed.div({
  exit: {
    applyAtEnd: {
      display: 'none',
    },
  },
  enter: {
    applyAtStart: {
      display: 'block',
    },
    delayChildren: 200,
  },
});
const ModalAnimated = posed(ModalPrintPreview)(scaleIn);
const Backdrop = posed(ModalBackdrop)(fade);
const Button = posed.button({
  hoverable: true,
  init: {
    scale: 1,
    boxShadow: '0 0 3px 0 rgba(26, 36, 79, 0.1)',
  },
  hover: {
    scale: 1.1,
    boxShadow: '0 1px 5px 0 rgba(26, 36, 79, 0.1)',
  },
  exit: {
    opacity: 0,
    scale: 0,
  },
  enter: {
    opacity: 1,
    scale: 1,
  },
});

interface InputProps {
  readonly pose?: string;
}

interface StateProps {
  readonly isShowing: boolean;
  readonly isSeparateAreaTable: boolean;
  readonly pages: Immutable.List<Page>;
  readonly currentPreviewPage?: Page;
}

interface ActionProps {
  readonly setSeparateAreaTable: typeof printPreviewModalActions.setSeparateAreaTable;
  readonly setCurrentPreviewPage: typeof printPreviewModalActions.setCurrentPreviewPage;
  readonly updatePreviewImage: () => void;
  readonly close: () => void;
  readonly hidePageGroupsModal: () => void;
}

type Props = InputProps & StateProps & ActionProps;

class PrintPreviewModal extends Component<Props> {
  private componentRef: any;

  constructor(props: any) {
    super(props);
    this.componentRef = React.createRef();

    this.state = {
      // eslint-disable-next-line
      areaTablePage: {},
      pdfReady: true,
      builtPreviewPages: [],
    };
  }

  // Look what they reduce me to. Look what they take.
  public UNSAFE_componentWillReceiveProps(props) {
    const { isSeparateAreaTable, pages } = props;
    this.setState({
      pdfReady: false,
      builtPreviewPages: [],
    });
    (async () => {
      const builtPngPromises = pages.toArray().map(currentPreviewPage => {
        if (currentPreviewPage?.previewPngs?.sketch && currentPreviewPage?.previewPngs?.sketch !== 'data:,') {
          const { sketch, areaTable } = currentPreviewPage.previewPngs;
          if (!isSeparateAreaTable) return buildPreviewPage(sketch, areaTable);
          return currentPreviewPage?.previewPngs.sketch;
        }
        return '';
      });
      await Promise.all(builtPngPromises).then((builtPngs) => {
        this.setState({
          pdfReady: true,
          builtPreviewPages: builtPngs,
        });
      });
    })();
  }

  public componentDidUpdate() {
    const { isSeparateAreaTable, pages, currentPreviewPage, setCurrentPreviewPage } = this.props;

    if (!isSeparateAreaTable && currentPreviewPage?.isAreaTable) {
      setCurrentPreviewPage(pages.last());
    }
  }

  private close = (): void => {
    const { close, hidePageGroupsModal } = this.props;
    hidePageGroupsModal();
    close();
  };

  private changePage = async (change: number): void => {
    const { pages, setCurrentPreviewPage, currentPreviewPage, isSeparateAreaTable } = this.props;

    let newPreviewPage;

    // @ts-ignore
    if (isSeparateAreaTable) {
      if (currentPreviewPage?.pageId === pages.last().pageId && change > 0) {
        // Pressing 'next' while in isSeparateAreaTable mode on the second to last page
        const areaTablePageUri = await buildAreaTablePreviewPage(pages.toArray());

        newPreviewPage = {
          pageId: 'areaTable',
          isAreaTable: true,
          previewPngs: {
            sketch: areaTablePageUri,
          }
        };

        // This is a hack to ensure there's no issue rendering the actual print dialogue
        this.setState({
          areaTablePage: newPreviewPage,
          pdfReady: false,
        });

        setTimeout(() => {
          this.setState({
            pdfReady: true,
          });
        }, 1000);
      } else if (currentPreviewPage?.isAreaTable && change < 0) {
        // Pressing 'previous' while in isSeparateAreaTable mode on the area table page
        newPreviewPage = pages.toArray()[pages.size - 1];
      } else {
        const currentPreviewPageIndex = this.getCurrentPreviewPageIndex(pages.toArray(), currentPreviewPage);
        const newCurrentPreviewPageIndex = currentPreviewPageIndex + change;

        newPreviewPage = pages.toArray()[newCurrentPreviewPageIndex];
      }
    } else {
      // Navigating while not in isSeparateAreaTableMode
      const currentPreviewPageIndex = this.getCurrentPreviewPageIndex(pages.toArray(), currentPreviewPage);
      const newCurrentPreviewPageIndex = currentPreviewPageIndex + change;

      newPreviewPage = pages.toArray()[newCurrentPreviewPageIndex];
    }

    if (newPreviewPage) setCurrentPreviewPage(newPreviewPage);
  };

  private toggleGlaTable = async () => {
    const { setSeparateAreaTable, isSeparateAreaTable, pages } = this.props;

    // This is a hack to ensure there's no issue rendering the actual print dialogue
    this.setState({
      pdfReady: false
    });

    if (!isSeparateAreaTable === true) {
      const areaTablePageUri = await buildAreaTablePreviewPage(pages.toArray());

      const newPreviewPage = {
        pageId: 'areaTable',
        isAreaTable: true,
        previewPngs: {
          sketch: areaTablePageUri,
        }
      };

      this.setState({
        areaTablePage: newPreviewPage,
      });
    } else if (!isSeparateAreaTable === false) {
      this.setState({
        areaTablePage: {},
      });
    }

    setTimeout(() => {
      this.setState({
        pdfReady: true,
      });
    }, 1000);

    setSeparateAreaTable(!isSeparateAreaTable);
  };

  // eslint-disable-next-line
  private getCurrentPreviewPageIndex = (pages: Page[], currentPage: Page) => {
    return pages.length && currentPage ? pages.findIndex((page: Page) => currentPage.pageId === page.pageId) : 0;
  };

  public render = (): JSX.Element => {
    const {
      pose,
      isSeparateAreaTable,
      isShowing,
      pages,
      currentPreviewPage,
    } = this.props;

    const currentPreviewPageIndex = this.getCurrentPreviewPageIndex(pages.toArray(), currentPreviewPage);
    let pageNum = currentPreviewPageIndex + 1;

    if (isSeparateAreaTable && currentPreviewPage?.isAreaTable) pageNum = pages.size + 1;

    let prevDisabled = true;
    let nextDisabled = true;

    if (currentPreviewPage) {
      prevDisabled = currentPreviewPage.pageId === pages.toArray()[0].pageId;

      if (isSeparateAreaTable) {
        nextDisabled = currentPreviewPage.pageId === 'areaTable';
      } else {
        nextDisabled = currentPreviewPage.pageId === pages.toArray()[pages.toArray().length - 1].pageId;
      }
    }

    const renderSketchPrintPdf = () => {
      // @ts-ignore
      const { areaTablePage, builtPreviewPages } = this.state;

      let previewPages = builtPreviewPages;

      if (isSeparateAreaTable && areaTablePage?.previewPngs?.sketch) {
        previewPages = previewPages.concat(areaTablePage?.previewPngs?.sketch);
      }

      return <SketchPrintPdf previewPages={previewPages} isSeparateAreaTable={isSeparateAreaTable} />;
    };

    return (
      <Poser pose={pose}>
        <Backdrop />
        <ModalAnimated>
          <header css={headerStyle}>
            <div>
              <Button css={closeStyle} onClick={this.close} title={messages.closeModal}>
                <IconClose />
              </Button>
              <p>{printPreview.exitCopy}</p>
            </div>

            {(isShowing && this.state.pdfReady && this.state.builtPreviewPages.length) ? (
              <BlobProvider document={renderSketchPrintPdf()}>
                {({ url }) => (
                  <div>
                    {/* @ts-ignore */}
                    <a href={url} target="_blank" rel="noreferrer" css={printSketchStyle}>
                      <Button css={closeStyle} title={printPreview.printCopy}>
                        <IconPrinterBlack />
                      </Button>
                      <p>{printPreview.printCopy}</p>
                    </a>
                  </div>
                )}
              </BlobProvider>
            ) : (
              <div>
                <div>
                  <Button css={closeStyle} title={printPreview.printCopy}>
                    <IconPrinterBlack />
                  </Button>
                  <p>{printPreview.printCopy}</p>
                </div>
              </div>
            )}

            <div>
              <Button css={closeStyle} onClick={async () => this.toggleGlaTable()} title={printPreview.separateGlaTableCopy}>
                {isSeparateAreaTable ? <IconTableSelected /> : <IconTable />}
              </Button>
              <p>{printPreview.separateGlaTableCopy}</p>
            </div>
          </header>
          <Button css={prevStyle} pose={prevDisabled ? 'exit' : 'enter'} withParent={false} disabled={prevDisabled} onClick={async () => this.changePage(-1)}>
            <IconBack />
          </Button>
          <Button css={nextStyle} pose={nextDisabled ? 'exit' : 'enter'} withParent={false} disabled={nextDisabled} onClick={async () => this.changePage(1)}>
            <IconForward />
          </Button>
          {isShowing && (
            <div ref={this.componentRef} id="printPreviewSketchContainer" css={previewContainerStyle}>
              <PrintPreviewSketch currentPreviewPage={currentPreviewPage} isSeparateAreaTable={isSeparateAreaTable} />
            </div>
          )}
          <span css={pageNumStyle}>{`Page ${pageNum}`}</span>
        </ModalAnimated>
      </Poser>
    );
  };
}

export default connect(
  (state: RootState): StateProps => {
    const pages = pagesSelectors.getPages(state);

    return {
      isShowing: printPreviewModalSelectors.isShowing(state),
      isSeparateAreaTable: printPreviewModalSelectors.isSeparateAreaTable(state),
      pages,
      currentPreviewPage: (() => {
        const currentPreviewPage = printPreviewModalSelectors.getCurrentPreviewPage(state);

        if (!currentPreviewPage) {
          const currentPage = pagesSelectors.getCurrentPage(state);

          return pages.find((page: Page) => page.pageId === currentPage?.pageId);
        }

        return currentPreviewPage;
      })(),
    };
  },
  {
    setCurrentPreviewPage: printPreviewModalActions.setCurrentPreviewPage,
    close: printPreviewModalActions.hide,
    setSeparateAreaTable: printPreviewModalActions.setSeparateAreaTable,
    hidePageGroupsModal: pageGroupsModalActions.hide,
  },
)(PrintPreviewModal);
