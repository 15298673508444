import * as Immutable from 'immutable';

import { Point, CoordinatePoint } from 'types/point';
import { Figure, isClosedFigure } from 'types/figure';
import { Wall, CircleWall } from 'types/wall';
import { polygonArea } from 'helpers/polygonArea';
import {
  formatAreaLabel, precisionedAreaFeet, precisionedAreaMetric, formatSquareFeetArea,
} from 'helpers/pixelsToDistance';
import { AreaType } from 'types/areaType';
import { GlaType } from 'types/glaType';
import { PositionedAreaLabel } from 'types/positionedAreaLabel';
import { UnreachableCaseError } from 'helpers/UnreachableCaseError';
import { compose, multiplyBy } from 'helpers/utils';
import { circleArea } from 'helpers/geometry';

export const getFigureGlaType = (figure: Figure): GlaType | undefined => {
  if (isClosedFigure(figure)) {
    return figure.glaType;
  }
  return undefined;
};

export const getFigureAreaType = (figure: Figure): string | undefined => {
  if (isClosedFigure(figure)) {
    return figure.areaTypeId;
  }
  return undefined;
};

export const getFigureMultiplier = (figure: Figure): number | undefined => {
  if (isClosedFigure(figure)) {
    return figure.glaMultiplier;
  }

  return undefined;
};

export const createAreaTypeLabel = (
  areaLabelPosition: Point,
): PositionedAreaLabel => ({
  isMovedByUser: false,
  pointId: areaLabelPosition.pointId,
  rotation: 0,
});

interface GLARow {
  readonly figure: Figure;
  readonly figurePoints?: Immutable.List<CoordinatePoint>;
  readonly areaType?: AreaType;
  readonly walls?: Immutable.Map<string, Wall | CircleWall>;
  readonly precision?: number;
  readonly unitOfMeasure?: string;
  readonly gridSizeInFeet?: number;
}

interface GLARowData {
  readonly areaTypeText: string;
  readonly areaSizeText: string;
  readonly isGlaText: string;
  readonly isIncludedInGla: boolean;
}

export const getGlaRowData = ({
  figure, figurePoints, areaType, walls, precision, unitOfMeasure, gridSizeInFeet,
}: GLARow): GLARowData | undefined => {
  const figureArea = compose(
    multiplyBy((gridSizeInFeet || 1) ** 2),
    ({ radius }: CircleWall) => radius ? circleArea(radius) : polygonArea(figurePoints!),
    (wallId: string) => walls?.get(wallId) || {},
  )(figure.walls[0]);

  const unitOfMeasureArea = (currentFigureArea: number): number => {
    if (unitOfMeasure === 'meters') return precisionedAreaMetric(currentFigureArea);
    return precisionedAreaFeet(currentFigureArea);
  };
  const sqUnitArea = unitOfMeasureArea(figureArea);

  const figureGlaType = getFigureGlaType(figure);
  if (figureGlaType === undefined) {
    return undefined;
  }

  let area;
  let multiplier: string;
  switch (figureGlaType) {
    case GlaType.NON_GLA:
      area = 0;
      multiplier = '0.00';
      break;

    case GlaType.NEGATIVE:
      area = -sqUnitArea;
      multiplier = '-1.00';
      break;

    case GlaType.GLA:
      area = sqUnitArea;
      multiplier = `${getFigureMultiplier(figure)}`;
      break;

    default:
      throw new UnreachableCaseError(figureGlaType);
  }

  let formattedArea: string;

  if (parseInt(multiplier, 10) < 1) {
    formattedArea = compose(
      (pixelArea: number) => (unitOfMeasure && precision) ? formatAreaLabel(unitOfMeasure, precision)(pixelArea) : formatSquareFeetArea(pixelArea),
      (a: number) => a * parseFloat(multiplier),
    )(figureArea);
  } else {
    formattedArea = (unitOfMeasure && precision) ? formatAreaLabel(unitOfMeasure, precision)(figureArea) : formatSquareFeetArea(area);
  }

  const areaTypeText = areaType ? areaType.text : '';
  const areaSizeText = `${sqUnitArea} x ${multiplier} = ${formattedArea}`;
  const isIncludedInGla = (figureGlaType !== GlaType.NON_GLA);
  const isGlaText = isIncludedInGla ? 'Yes' : 'No';

  return {
    areaTypeText,
    areaSizeText,
    isGlaText,
    isIncludedInGla,
  };
};
