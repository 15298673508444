import * as Immutable from 'immutable';
import {
  all, takeLatest, select, put,
} from 'redux-saga/effects';

import { RootState } from 'reducers/rootReducer';
import { Point } from 'types/point';
import { PayloadAction } from 'types/payloadAction';
import { Positionable } from 'types/positionable';
import { getCentroid } from 'helpers/geometry';
import { selectors as modelSelectors } from 'ducks/model/model';
import { selectors as pointsSelectors } from 'ducks/model/points';
import { selectors as wallsSelectors } from 'ducks/model/walls';
import { selectors as figuresSelectors } from 'ducks/model/figures';
import { actions as editModeActions } from 'ducks/editMode';
import { actions as drawAndPanActions } from 'ducks/draw/drawAndPan';

// Action Types
const NAME = 'pageGroupsModal';

const SHOW = `${NAME}/SHOW`;
const HIDE = `${NAME}/HIDE`;

const MOVE = `${NAME}/MOVE`;
const SELECT_WALL = `${NAME}/SELECT_WALL`;
const SELECT_FIGURE = `${NAME}/SELECT_FIGURE`;
const SELECT_POSITIONABLE = `${NAME}/SELECT_POSITIONABLE`;

export interface PageGroupsModalState {
  readonly isShowing: boolean;
}

// Initial State
const initialState: PageGroupsModalState = {
  isShowing: false,
};

// Action Creators
export const actions = {
  hide: () => ({ type: HIDE }),
  show: () => ({ type: SHOW }),

  move: (dropPageId: string, dragItems: string[]) => ({
    type: MOVE,
    payload: {
      dropPageId,
      dragItems,
    },
  }),
  selectWall: (wallId: string) => ({
    type: SELECT_WALL,
    payload: wallId,
  }),
  selectFigure: (figureId: string) => ({
    type: SELECT_FIGURE,
    payload: figureId,
  }),
  selectPositionable: (objectId: string) => ({
    type: SELECT_POSITIONABLE,
    payload: objectId,
  }),
};

// Selectors
const getAreaTypeModal = (rootState: RootState): PageGroupsModalState => rootState.pageGroupsModal;

const isShowing = (rootState: RootState): boolean => getAreaTypeModal(rootState).isShowing;

export const selectors = {
  isShowing,
};

// Reducers
const showReducer = (state: PageGroupsModalState): PageGroupsModalState => ({
  ...state,
  isShowing: true,
});

const hideReducer = (state: PageGroupsModalState): PageGroupsModalState => ({
  ...state,
  isShowing: false,
});

export const reducer = (state: PageGroupsModalState = initialState, action: PayloadAction): PageGroupsModalState => {
  switch (action.type) {
    case HIDE:
      return hideReducer(state);

    case SHOW:
      return showReducer(state);

    default:
      return state;
  }
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doSelectWall({ payload }: PayloadAction) {
    const wallId = payload as string;
    const wallPoints: Immutable.List<Point> = wallsSelectors.getWallPoints(yield select(), wallId);
    const centerOfPolygon = getCentroid(wallPoints);
    yield put(drawAndPanActions.panToPoint(centerOfPolygon));

    yield put(editModeActions.switchToSelected([wallId]));
  }

  function* doSelectFigure({ payload }: PayloadAction) {
    const figureId = payload as string;
    const figurePoints: Immutable.List<Point> = figuresSelectors.getFigurePointsForDraw(yield select(), figureId);
    const centerOfPolygon = getCentroid(figurePoints);
    yield put(drawAndPanActions.panToPoint(centerOfPolygon));

    yield put(editModeActions.switchToSelected([figureId]));
  }

  function* doSelectPositionable({ payload }: PayloadAction) {
    const objectId = payload as string;
    const positionable = (modelSelectors.getModelObject(yield select(), objectId)) as Positionable;
    const point: Point = pointsSelectors.getPointById(yield select(), positionable.pointId);
    yield put(drawAndPanActions.panToPoint(point));

    yield put(editModeActions.switchToSelected([objectId]));
  }

  return function* saga() {
    yield all([
      // TODO: See above comment about non-functionality
      // takeLatest(MOVE, doMove),
      takeLatest(SELECT_WALL, doSelectWall),
      takeLatest(SELECT_FIGURE, doSelectFigure),
      takeLatest(SELECT_POSITIONABLE, doSelectPositionable),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
