import * as Immutable from 'immutable';
import { all, takeLatest, call } from 'redux-saga/effects';
import { createAction, getType, ActionType } from 'typesafe-actions';

import { RootState } from 'reducers/rootReducer';
import { PositionedLabel } from 'types/positionedLabel';
import { Dimensions } from 'types/dimensions';
import { drawLabelAndUpdatePositionedLabel } from 'ducks/draw/drawLabel';

export const ADD = 'positionedLabels/add';

export interface PositionedLabelsState {
  readonly positionedLabels: Immutable.Map<string, PositionedLabel>;
}

// Initial State
const initialState: PositionedLabelsState = {
  positionedLabels: Immutable.Map<string, PositionedLabel>(),
};

interface ResizeLabelPayload {
  readonly positionedLabelId: string;
  readonly size: Dimensions;
  readonly fontScale: number;
}

interface CreateLabelAndUpdatePayload {
  readonly text: string;
  readonly positionedLabel: PositionedLabel;
}

// Action Creators
export const actions = {
  add: createAction(ADD)<PositionedLabel>(),

  update: createAction('positionedLabels/update')<PositionedLabel>(),

  remove: createAction('positionedLabels/remove')<string>(),

  clear: createAction('positionedLabels/clear')(),

  resize: createAction(
    'positionedLabels/resize',
    (positionedLabelId: string, size: Dimensions, fontScale: number) => ({ positionedLabelId, size, fontScale }),
  )<ResizeLabelPayload>(),

  createLabelAndUpdate: createAction(
    'positionedLabels/createLabelAndUpdate',
    (text: string, positionedLabel: PositionedLabel) => ({ text, positionedLabel }),
  )<CreateLabelAndUpdatePayload>(),
};

export type Actions = ActionType<typeof actions>

// Selectors
const getPositionedLabelsState = (rootState: RootState): PositionedLabelsState => rootState.positionedLabels;

const getAllPositionedLabels = (
  rootState: RootState,
): Immutable.Map<string, PositionedLabel> => getPositionedLabelsState(rootState).positionedLabels;

const getPositionedLabelsIds = (
  rootState: RootState,
): string[] => getAllPositionedLabels(rootState).keySeq().toArray();

const getPositionedLabelById = (
  state: RootState, positionedLabelId: string,
): PositionedLabel => getAllPositionedLabels(state).get(positionedLabelId)!;

export const selectors = {
  getAllPositionedLabels,
  getPositionedLabelsIds,
  getPositionedLabelById,
};

// Reducers
const addPositionedLabelReducer = (
  state: PositionedLabelsState, positionedLabel: PositionedLabel,
): PositionedLabelsState => ({
  ...state,
  positionedLabels: state.positionedLabels.set(positionedLabel.positionedLabelId, positionedLabel),
});

const removePositionedLabelReducer = (
  state: PositionedLabelsState, positionedLabelId: string,
): PositionedLabelsState => ({
  ...state,
  positionedLabels: state.positionedLabels.remove(positionedLabelId),
});

const clearPositionedLabelReducer = (
  state: PositionedLabelsState,
): PositionedLabelsState => ({
  ...state,
  positionedLabels: Immutable.Map<string, PositionedLabel>(),
});

const resizeLabelReducer = (
  state: PositionedLabelsState,
  { positionedLabelId, size, fontScale }: ResizeLabelPayload,
): PositionedLabelsState => ({
  ...state,
  positionedLabels: state.positionedLabels.update(positionedLabelId, positionedLabel => ({
    ...positionedLabel,
    size,
    fontScale,
  })),
});

export const reducer = (state: PositionedLabelsState = initialState, action: Actions): PositionedLabelsState => {
  switch (action.type) {
    case getType(actions.add):
    case getType(actions.update):
      return addPositionedLabelReducer(state, action.payload);

    case getType(actions.remove):
      return removePositionedLabelReducer(state, action.payload);

    case getType(actions.resize):
      return resizeLabelReducer(state, action.payload);

    case getType(actions.clear):
      return clearPositionedLabelReducer(state);

    default:
      return state;
  }
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doCreateLabelAndUpdate({ payload }: ReturnType<typeof actions.createLabelAndUpdate>) {
    const { text, positionedLabel } = payload;
    yield call(drawLabelAndUpdatePositionedLabel, text, positionedLabel);
  }

  return function* saga() {
    yield all([
      takeLatest(actions.createLabelAndUpdate, doCreateLabelAndUpdate),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
