/**
 * Motivation: for resize sketch function
 * user prefer to resize only figures which "connected" to the specific wall
 * i.e. we should find connected sub-graph, we use DFS for it
 * https://en.wikipedia.org/wiki/Depth-first_search
 */
import * as Immutable from 'immutable';

import { Wall } from 'types/wall';
import { getAdjacentNodes } from 'helpers/path/graphUtils';

const dfsStep = (
  walls: Immutable.Map<string, Wall>,
  nodeId: string,
  visited: { [nodeId: string]: boolean },
  exceptWalls: string[],
): string[] => {
  if (visited[nodeId]) {
    return [];
  }

  let componentNodes = [nodeId];
  visited[nodeId] = true; // eslint-disable-line no-param-reassign
  const adjacentNodes = getAdjacentNodes(walls, nodeId, visited, exceptWalls);
  adjacentNodes.forEach((node) => {
    componentNodes = componentNodes.concat(dfsStep(walls, node.nodeId, visited, exceptWalls));
  });

  return componentNodes;
};

export const getConnectedComponent = ( // dfs
  walls: Immutable.Map<string, Wall>,
  startNode: string,
  exceptWalls: string[],
): string[] => {
  const visited: { [nodeId: string]: boolean } = {};

  return dfsStep(walls, startNode, visited, exceptWalls);
};
