import * as Immutable from 'immutable';
import {
  all, select, put, call, takeLatest,
} from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

import { messages } from 'config/messages';
import { Point, CoordinatePoint } from 'types/point';
import { Wall } from 'types/wall';
import { Figure } from 'types/figure';
import { WallType } from 'types/wallType';
import { PayloadAction } from 'types/payloadAction';
import { getFiguresWithWall } from 'helpers/model/figureWalls';
import { getClosestWallPoint } from 'helpers/collision/distanceToWall';
import { selectors as pointsSelectors, actions as pointsActions } from 'ducks/model/points';
import { selectors as wallsSelectors, actions as wallsActions } from 'ducks/model/walls';
import { selectors as figuresSelectors, actions as figuresActions } from 'ducks/model/figures';

const NAME = 'splitWall';

const SPLIT_WALL = `${NAME}/SPLIT_WALL`;

// Action Creators
export const actions = {
  splitWall: (wallId: string, point: CoordinatePoint) => ({
    type: SPLIT_WALL,
    payload: {
      wallId,
      point,
    },
  }),
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export function* splitFigureWall(
  figureId: string, wallId: string, newWallId: string, pointId2: string,
) {
  const figure: Figure = figuresSelectors.getFigureById(yield select(), figureId);
  const figureWalls = [...figure.walls];
  const wallIndex = figureWalls.findIndex(wId => wId === wallId);
  let indexForInsert = wallIndex;
  if (wallIndex > 0) {
    const prevWall: Wall = wallsSelectors.getWallById(yield select(), figureWalls[wallIndex - 1]);
    if (prevWall.points.includes(pointId2)) {
      indexForInsert = wallIndex + 1;
    }
  } else {
    const isClosed: boolean = figuresSelectors.isClosingBaseFigureOnId(yield select(), figureId);
    if (isClosed) {
      const prevWall: Wall = wallsSelectors.getWallById(yield select(), figureWalls[figureWalls.length - 1]);
      if (prevWall.points.includes(pointId2)) {
        indexForInsert = figureWalls.length - 1;
      }
    } else if (figureWalls.length > 1) {
      const nextWall: Wall = wallsSelectors.getWallById(yield select(), figureWalls[wallIndex + 1]);
      if (!nextWall.points.includes(pointId2)) {
        indexForInsert = wallIndex + 1;
      }
    }
  }

  figureWalls.splice(indexForInsert, 0, newWallId);
  yield put(figuresActions.update(figure.figureId, {
    walls: figureWalls,
  }));
}

export interface SplitWallResult {
  readonly point: Point;
  readonly wallId: string;
}

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export function* splitWall(wallId: string, p: CoordinatePoint) {
  const middlePointId = uuidv4();

  const wallForSplit: Wall = wallsSelectors.getWallById(yield select(), wallId);

  const pointId1 = wallForSplit.points[0];
  const pointId2 = wallForSplit.points[1];

  const p1: Point = pointsSelectors.getPointById(yield select(), pointId1);
  const p2: Point = pointsSelectors.getPointById(yield select(), pointId2);

  const pointOnTheWall = getClosestWallPoint(p, p1, p2);

  const middlePoint: Point = {
    pointId: middlePointId,
    x: pointOnTheWall.x,
    y: pointOnTheWall.y,
  };

  yield put(
    pointsActions.add(middlePoint),
  );

  const newWallId = uuidv4();
  yield put(
    wallsActions.add({
      wallId: newWallId,
      wallType: wallForSplit.wallType,
      points: [pointId1, middlePointId],
    }),
  );
  yield put(
    wallsActions.add({
      wallId,
      wallType: wallForSplit.wallType,
      points: [middlePointId, pointId2],
    }),
  );

  const figures: Immutable.Map<string, Figure> = figuresSelectors.getAllFigures(yield select());
  const figuresIds = getFiguresWithWall(figures, wallId);
  yield all(figuresIds.map(figureId => call(
    splitFigureWall, figureId, wallId, newWallId, pointId2,
  )));

  const result: SplitWallResult = {
    point: middlePoint,
    wallId: newWallId,
  };

  return result;
}

// sagas
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createSagas = () => {
  function* doSplitWall({ payload }: PayloadAction) {
    const { wallId, point }: { wallId: string; point: CoordinatePoint } = payload;
    const wall: Wall = wallsSelectors.getWallById(yield select(), wallId);
    if (wall.wallType === WallType.ARC) {
      toast.warning(messages.arcCollisionDetected);
      return;
    }

    yield call(splitWall, wallId, point);
  }

  return function* saga() {
    yield all([
      takeLatest(SPLIT_WALL, doSplitWall),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
