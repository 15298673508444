import * as Immutable from 'immutable';
import { CoordinatePoint } from 'types/point';

export const getAdditionalLine = <T extends CoordinatePoint>(
  point: T, x: number, y: number,
): Immutable.List<T | CoordinatePoint> => (
  Immutable.List([
    point,
    {
      x,
      y,
    },
  ])
);

export const isAdditionalLinesDisplayed = (sp: CoordinatePoint, ep: CoordinatePoint): boolean => sp.x !== ep.x && sp.y !== ep.y;
