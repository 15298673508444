import {
  all, put, select, takeLatest, call,
} from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { createAction } from 'typesafe-actions';

import { copyConfig } from 'config/copyConfig';
import { CoordinatePoint, Point } from 'types/point';
import { Page } from 'types/page';
import { PositionedLabel } from 'types/positionedLabel';
import { PositionedSymbol } from 'types/positionedSymbol';
import { Figure, ClosedFigure, isClosedFigure } from 'types/figure';
import { CircleWall, Wall } from 'types/wall';
import { PositionedAreaLabel } from 'types/positionedAreaLabel';
import { actions as pointsActions, selectors as pointsSelectors } from 'ducks/model/points';
import { actions as positionedLabelActions, selectors as positionedLabelsSelectors } from 'ducks/model/positionedLabels';
import { actions as positionedSymbolActions, selectors as positionedSymbolSelectors } from 'ducks/model/positionedSymbols';
import { actions as pagesActions, selectors as pagesSelectors } from 'ducks/model/pages';
import { actions as wallsActions, selectors as wallsSelectors } from 'ducks/model/walls';
import { actions as figuresActions, selectors as figuresSelectors, getObjectFromPages } from 'ducks/model/figures';
import { actions as editModeActions } from 'ducks/editMode';
import { DuplicableObjects, selectors as selectionSelectors } from 'ducks/selection/selection';
import {
  listToArray, compose, filterBy, mapBy, flatten, concat, isSomething, coordinateExtremes,
} from 'helpers/utils';
import { RootState } from 'reducers/rootReducer';
import * as Immutable from 'immutable';

interface CopiedLabel {
  copiedCoordinatePoint: CoordinatePoint;
  copiedPositionedLabel: PositionedLabel;
}

interface CopiedSymbol {
  copiedCoordinatePoint: CoordinatePoint;
  copiedPositionedSymbol: PositionedSymbol;
}

interface Clipboard {
  positionedLabels?: CopiedLabel[];
  positionedSymbols?: CopiedSymbol[];
  figures?: Figure[];
}

// Action Creators
export const actions = {
  copy: createAction('copy/copy')<void>(),
  paste: createAction('copy/paste')<void>(),
  flip: createAction('copy/flip')<void>(),
  mirror: createAction('copy/mirror')<void>(),
};

const getFigureById = (rootState: RootState) => (id: string) => figuresSelectors.getFigureById(rootState, id);

const getWallById = (rootState: RootState) => (id: string) => wallsSelectors.getWallById(rootState, id);

const getPositionedLabelById = (rootState: RootState) => (id: string) => positionedLabelsSelectors.getPositionedLabelById(rootState, id);

const getPositionedSymbolById = (rootState: RootState) => (id: string) => positionedSymbolSelectors.getPositionedSymbolById(rootState, id);

const getPointById = (rootState: RootState) => (id: string) => pointsSelectors.getPointById(rootState, id);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
const getObjectsBy = (fn: any) => compose(
  filterBy(isSomething),
  mapBy(fn),
  listToArray,
);

export const getWallPointIds = compose(
  (array: string[]) => Array.from(new Set(array)),
  flatten,
  mapBy(({ points }: Wall) => points),
);

const flipPoint = (c: CoordinatePoint) => (p: Point) => ({
  ...p,
  y: c.y - (p.y - c.y),
});

const mirrorPoint = (c: CoordinatePoint) => (p: Point) => ({
  ...p,
  x: c.x - (p.x - c.x),
});

const getCenter = ({
  lx, ly, hx, hy,
}: Record<string, number>): CoordinatePoint => ({
  x: (lx + hx) / 2,
  y: (ly + hy) / 2,
});

const getTransformOrigin = compose(
  getCenter,
  coordinateExtremes,
);

// Local data
let clipboard: Clipboard = {
  positionedLabels: [],
  positionedSymbols: [],
  figures: [],
};

export const getClipboard = () => clipboard;

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* copyPointWithOffset(oldPoint: CoordinatePoint, offset: CoordinatePoint) {
    const pointId = uuidv4();
    const point: Point = {
      ...oldPoint,
      pointId,
      x: oldPoint.x + offset.x,
      y: oldPoint.y + offset.y,
    };
    yield put(pointsActions.add(point));
    return point.pointId;
  }

  function* copyFigure(oldFigure: Figure) {

    // first try to get the figure from pages
    const figureFromPages = getObjectFromPages(yield select(), oldFigure.figureId);

    // get points for figure- this can come from points or from pages > page > objects as points
    let gotFigureFromPages = false;
    let oldPoints: Point[] = [];
    if(figureFromPages) {
      oldPoints = (figuresSelectors.getFigurePoints(yield select(), oldFigure.figureId, figureFromPages)).toArray();
      gotFigureFromPages = true; // got points from pages
    }

    if(!oldPoints?.length) {
      oldPoints = (figuresSelectors.getFigurePoints(yield select(), oldFigure.figureId)).toArray();
      gotFigureFromPages = false; // got points from points
    }

    const oldToNewPoints = new Map<string, string>();

    // copy points
    for (let i = 0; i < oldPoints.length; i++) {
      const oldPoint = oldPoints[i];
      const newPointId: string = yield call(copyPointWithOffset, oldPoint, copyConfig.figureOffset);
      oldToNewPoints.set(oldPoint.pointId, newPointId);
    }

    // copy walls
    const oldWallIds = oldFigure.walls;
    const newWallIds: string[] = [];
    const newWalls: Wall | CircleWall[] = [];

    for (let i = 0; i < oldWallIds.length; i++) {
      const oldWall: Wall | CircleWall = gotFigureFromPages ?
        getObjectFromPages(yield select(), oldWallIds[i])! :
        wallsSelectors.getWallById(yield select(), oldWallIds[i]);

      const newWallPoints = oldWall.points.reduce(
        (newPoints: string[], oldPoint) => [...newPoints, oldToNewPoints.get(oldPoint)!],
        [],
      );

      const wallId = uuidv4();

      let newWall: Wall | CircleWall = {
        ...oldWall,
        wallId,
        points: newWallPoints,
      };

      // if this is a circle, it will have a wall with just one
      // point.. and it will have a centerpoint
      // we need to connect the centerpoint
      if("centerPoint" in oldWall && oldWall.centerPoint && oldWall.points.length === 1) {
        newWall = {
          ...newWall,
          centerPoint: {
            ...oldWall.centerPoint,
            pointId: newWall.points[0]
          },
        };
      }

      newWallIds.push(newWall.wallId);
      newWalls.push(newWall);

      yield put(wallsActions.add(newWall));
    }

    // copy figure itself
    const figureId = uuidv4();
    let newFigure: any;

    if (isClosedFigure(oldFigure)) {
      newFigure = {
        ...oldFigure,
        figureId,
        walls: newWallIds,
      };

      // copy positioned label with control point
      const oldPositionedAreaLabel = oldFigure.positionedAreaLabel!;
      const oldAreaLabelPoint: Point = gotFigureFromPages ?
        getObjectFromPages(yield select(), oldPositionedAreaLabel.pointId) :
        pointsSelectors.getPointById(yield select(), oldPositionedAreaLabel.pointId);

      const newPositionedAreaLabelPointId: string = yield call(
        copyPointWithOffset, oldAreaLabelPoint, copyConfig.figureOffset,
      );

      const newPositionedAreaLabel: PositionedAreaLabel = {
        ...oldPositionedAreaLabel,
        pointId: newPositionedAreaLabelPointId,
      };

      newFigure.positionedAreaLabel = newPositionedAreaLabel;
    } else {
      newFigure = {
        ...oldFigure,
        figureId,
        walls: newWallIds,
      };
    }

    // add to page
    yield put(figuresActions.add(newFigure));
    const page: Page | undefined = pagesSelectors.getCurrentPage(yield select());
    if (page) yield put(pagesActions.upsertObject(page.pageId, figureId, newFigure));
    return newFigure;
  }

  function* doCopy() {
    const state = yield select();
    const selected: DuplicableObjects = selectionSelectors.getSelectedDuplicableObjects(state);

    clipboard = {
      positionedLabels: [],
      positionedSymbols: [],
      figures: [],
    };

    for (let i = 0; i < selected.figures.length; i++) {
      const figure = selected.figures[i];

      clipboard.figures?.push(figure);
    }

    for (let i = 0; i < selected.positionedSymbols.length; i++) {
      const copiedPositionedSymbol = selected.positionedSymbols[i];
      const copiedCoordinatePoint: CoordinatePoint = pointsSelectors.getPointById(state, copiedPositionedSymbol.pointId);

      clipboard.positionedSymbols?.push({ copiedCoordinatePoint, copiedPositionedSymbol });
    }

    for (let i = 0; i < selected.positionedLabels.length; i++) {
      const copiedPositionedLabel = selected.positionedLabels[i];
      const copiedCoordinatePoint: CoordinatePoint = pointsSelectors.getPointById(state, copiedPositionedLabel.pointId);

      clipboard.positionedLabels?.push({ copiedCoordinatePoint, copiedPositionedLabel });
    }
  }

  function* doPaste() {
    const newSelected: string[] = [];

    if (clipboard.figures?.length) {
      const pastedFigures: Figure[] = [];

      for (let i = 0; i < clipboard.figures.length; i++) {
        const pastedFigure = yield call(copyFigure, clipboard.figures[i]);
        newSelected.push(pastedFigure.figureId);
        pastedFigures.push(pastedFigure);
      }

      const rootState = yield select();

      // absurd, kludgy, fix to ensure that figures with interior walls work properly after duplication
      const pastedInteriorFigures = pastedFigures.filter(pastedFigure => pastedFigure.type === 'INTERIOR');
      const pastedExteriorFigures = pastedFigures.filter(pastedFigure => pastedFigure.type !== 'INTERIOR');

      const editedExteriorWalls: Wall | CircleWall[] = [];
      const pointsToRemove: string[] = [];

      pastedInteriorFigures.forEach(pastedInteriorFigure => {
        const interiorWall = getWallById(rootState)(pastedInteriorFigure.walls[0]);

        interiorWall.points.forEach(interiorWallPointId => {
          const interiorWallPoint = pointsSelectors.getPointById(rootState, interiorWallPointId);

          pastedExteriorFigures.forEach(pastedExteriorFigure => {
            pastedExteriorFigure.walls.forEach(pastedExteriorFigureWallId => {

              const pastedExteriorFigureWall = getWallById(rootState)(pastedExteriorFigureWallId);

              pastedExteriorFigureWall.points.forEach((pastedExteriorFigureWallPointId, index) => {
                const pastedExteriorFigureWallPoint = pointsSelectors.getPointById(rootState, pastedExteriorFigureWallPointId);

                if (pastedExteriorFigureWallPoint.x === interiorWallPoint.x && pastedExteriorFigureWallPoint.y === interiorWallPoint.y) {
                  pointsToRemove.push(pastedExteriorFigureWall.points[index]);
                  pastedExteriorFigureWall.points[index] = interiorWallPoint.pointId;
                  editedExteriorWalls.push(pastedExteriorFigureWall);
                }
              });
            });
          });
        });
      });

      for (let i = 0; i < editedExteriorWalls.length; i++) {
        yield put(wallsActions.update(editedExteriorWalls[i].wallId, {
          wallType: editedExteriorWalls[i].wallType,
          points: editedExteriorWalls[i].points,
          centerPoint: editedExteriorWalls[i].centerPoint,
          radius: editedExteriorWalls[i].radius,
        }));
      }

      for (let i = 0; i < pointsToRemove.length; i++) {
        yield put(pointsActions.remove(pointsToRemove[i]));
      }
    }

    if (clipboard.positionedLabels?.length) {
      for (let i = 0; i < clipboard.positionedLabels.length; i++) {
        const { copiedPositionedLabel, copiedCoordinatePoint } = clipboard.positionedLabels[i];
        const { labelId, rotation, size } = copiedPositionedLabel;

        const newPointId: string = yield call(copyPointWithOffset, copiedCoordinatePoint, copyConfig.positionedLabelOffset);
        const pastedPositionedLabelId = uuidv4();
        newSelected.push(pastedPositionedLabelId);

        const pastedPositionedLabel: PositionedLabel = {
          positionedLabelId: pastedPositionedLabelId,
          pointId: newPointId,
          labelId,
          size,
          rotation,
        };

        yield put(positionedLabelActions.add(pastedPositionedLabel));

        const page: Page | undefined = pagesSelectors.getCurrentPage(yield select());
        if (page) yield put(pagesActions.upsertObject(page.pageId, pastedPositionedLabelId, pastedPositionedLabel));
      }
    }

    if (clipboard.positionedSymbols?.length) {
      for (let i = 0; i < clipboard.positionedSymbols.length; i++) {
        const { copiedPositionedSymbol, copiedCoordinatePoint } = clipboard.positionedSymbols[i];
        const { symbolId, rotation, size } = copiedPositionedSymbol;

        const newPointId: string = yield call(copyPointWithOffset, copiedCoordinatePoint, copyConfig.positionedSymbolOffset);
        const pastedPositionedSymbolId = uuidv4();
        newSelected.push(pastedPositionedSymbolId);

        const pastedPositionedSymbol: PositionedSymbol = {
          positionedSymbolId: pastedPositionedSymbolId,
          pointId: newPointId,
          symbolId,
          size,
          rotation,
        };

        yield put(positionedSymbolActions.add(pastedPositionedSymbol));

        const page: Page | undefined = pagesSelectors.getCurrentPage(yield select());
        if (page) yield put(pagesActions.upsertObject(page.pageId, pastedPositionedSymbolId, pastedPositionedSymbol));
      }
    }

    yield put(editModeActions.switchToSelected(newSelected));
  }

  function* doTransform(transformType = 'flip') {
    const rootState: RootState = yield select();
    const { editMode: { selectedObjects } } = rootState;
    const figureById = getFigureById(rootState);
    const positionedLabelById = getPositionedLabelById(rootState);
    const positionedSymbolById = getPositionedSymbolById(rootState);
    const pointById = getPointById(rootState);
    const isWall = (id: string) => !!getWallById(rootState)(id);
    const transformFn = transformType === 'flip' ? flipPoint : mirrorPoint;

    const resolvePoints = compose(
      mapBy(pointById),
      filterBy((item: string, index: number, array: string[]) => isSomething(item) && array.indexOf(item) === index),
    );

    const figuresById = getObjectsBy(figureById)(selectedObjects);
    const interiorWallIds = compose(
      filterBy(isWall),
      listToArray,
    )(selectedObjects);

    const positionedLabelPoints = compose(
      mapBy(({ pointId }: PositionedLabel) => pointId),
      getObjectsBy(positionedLabelById),
    )(selectedObjects);

    const positionedSymbolPoints = compose(
      mapBy(({ pointId }: PositionedSymbol) => pointId),
      getObjectsBy(positionedSymbolById),
    )(selectedObjects);

    const areaLabelPoints = compose(
      mapBy(({ positionedAreaLabel }: ClosedFigure) => positionedAreaLabel?.pointId!),
    )(figuresById);

    const wallPoints = compose(
      getWallPointIds,
      mapBy((id: string) => wallsSelectors.getWallById(rootState, id)),
      concat(interiorWallIds),
      flatten,
      mapBy(({ walls }: Figure) => walls),
    )(figuresById);

    const newPoints = compose(
      (points: Point[]) => mapBy(
        compose(
          transformFn,
          getTransformOrigin,
        )(points),
      )(points),
      resolvePoints,
      concat(areaLabelPoints),
      concat(positionedSymbolPoints),
      concat(positionedLabelPoints),
    )(wallPoints);

    yield all(newPoints.map((p: Point) => put(pointsActions.update(p))));
  }

  function* doFlip() {
    yield doTransform('flip');
  }

  function* doMirror() {
    yield doTransform('mirror');
  }

  return function* saga() {
    yield all([
      takeLatest(actions.copy, doCopy),
      takeLatest(actions.paste, doPaste),
      takeLatest(actions.flip, doFlip),
      takeLatest(actions.mirror, doMirror),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
