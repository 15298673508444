import * as Immutable from 'immutable';

import { geometryConfig } from 'config/geometryConfig';
import { Wall } from 'types/wall';
import { Point, CoordinatePoint } from 'types/point';
import { distance } from 'helpers/geometry';

export const getWallPoints = (points: Immutable.Map<string, Point>, wall: Wall): Immutable.List<Point> => {
  let wallPoints: Immutable.List<Point> = Immutable.List<Point>();
  if (!wall || !wall.points.length) {
    return wallPoints;
  }

  wall.points.forEach((pointId) => {
    const point = points.get(pointId)!;
    if (!point) {
      return;
    }

    wallPoints = wallPoints.push(point);
  });

  return wallPoints;
};

/**
 * Check if walls are connected sequentially
 * i.e. wall1 = [p1, p2], wall2 = [p2, p3]
 * because walls are non-oriented (it could be shared between two polygons with different orientation)
 * it is possible that two wall could be in "backward" direction, i.e.
 * i.e. wall1 = [p1, p2], wall2 = [p3, p2]
 */
export const isDirectlyConnected = (
  walls: Immutable.Map<string, Wall>,
  prevWallId: string,
  nextWallId: string,
): boolean => {
  const prevWall = walls.get(prevWallId)!;
  const nextWall = walls.get(nextWallId)!;

  const pointId1 = prevWall.points[1];
  const pointId2 = nextWall.points[0];

  return pointId1 === pointId2;
};

/**
 * get common point
 */
export const getCommonPoint = (
  walls: Immutable.Map<string, Wall>,
  prevWallId: string,
  nextWallId: string,
): string => {
  const prevWall = walls.get(prevWallId)!;
  const nextWall = walls.get(nextWallId)!;

  if ((prevWall.points[0] === nextWall.points[0])
      || (prevWall.points[1] === nextWall.points[0])) {
    return nextWall.points[0];
  }

  return nextWall.points[1];
};

/**
 * get wall without middle point
 */
export const getWallWithoutMiddlePoint = (
  walls: Immutable.Map<string, Wall>,
  prevWallId: string,
  nextWallId: string,
  pointId: string,
): string[] => {
  const prevWall = walls.get(prevWallId)!;
  const nextWall = walls.get(nextWallId)!;

  const p1 = prevWall.points.find((pId, index) => (pId !== pointId) && (index < 2))!;
  const p2 = nextWall.points.find((pId, index) => (pId !== pointId) && (index < 2))!;
  return [p1, p2];
};

/**
 * get non-common point from next wall
 */
export const getNonCommonPoint = (
  walls: Immutable.Map<string, Wall>,
  prevWallId: string,
  nextWallId: string,
): string => {
  const prevWall = walls.get(prevWallId)!;
  const nextWall = walls.get(nextWallId)!;

  if ((prevWall.points[0] !== nextWall.points[0])
    && (prevWall.points[1] !== nextWall.points[0])) {
    return nextWall.points[0];
  }

  return nextWall.points[1];
};

/**
 * get walls with specific point
 * @param walls - all walls
 * @param pointId - specific point
 * @returns array of walls Ids
 */
export const getWallsWithPoint = (
  walls: Immutable.Map<string, Wall>,
  pointId: string,
): string[] => {
  const wallsWithPoint = walls.filter(wall => wall.points.includes(pointId));

  return wallsWithPoint.keySeq().toArray();
};

/**
 * get wall's center
 */
export const getCenterWall = (
  points: Immutable.Map<string, Point>,
  walls: Immutable.Map<string, Wall>,
  wallId: string,
): CoordinatePoint => {
  const wall = walls.get(wallId)!;
  const wallPoints = getWallPoints(points, wall);
  const p1 = wallPoints.get(0)!;
  const p2 = wallPoints.get(1)!;
  const c = {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  };

  return c;
};

/**
 * get 3 sequential points from 2 walls
 */
export const getSequentialPoints = (
  prevWall: Immutable.List<Point>,
  nextWall: Immutable.List<Point>,
): Immutable.List<Point> => {
  const p1 = prevWall.get(0)!;
  const p2 = prevWall.get(1)!;
  const p3 = nextWall.get(0)!;
  const p4 = nextWall.get(1)!;

  let firstPoint = p1;
  let commonPoint = p2;
  if ((p1.pointId === p3.pointId)
      || (p1.pointId === p4.pointId)) {
    firstPoint = p2;
    commonPoint = p1;
  }

  let lastPoint = p4;
  if (p4.pointId === commonPoint.pointId) {
    lastPoint = p3;
  }

  return Immutable.List([firstPoint, commonPoint, lastPoint]);
};

export const isTooShort = (startPoint: CoordinatePoint, endPoint: CoordinatePoint, zoomInPercent: number): boolean =>
  // eslint-disable-next-line implicit-arrow-linebreak
  distance(startPoint, endPoint) <= geometryConfig.getExteriorLineWidth(zoomInPercent);
