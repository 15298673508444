import * as Immutable from 'immutable';
import { point, polygon } from '@turf/helpers';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';

import { CoordinatePoint } from 'types/point';

/**
 * we check if point stays inside polygon (true) or outside (false)
 * this library is based on GeoJSON logic, including point in polygon calculations,
 * but has additional logic to account for points on the corners & boundaries of a given polygon
 * @return {boolean} true if (x, y) is in bounds, defined as being within the polygon, one of the corners, or on one of its boundaries
 */
export const polygonBound = (bounds: Immutable.List<CoordinatePoint>, x: number, y: number): boolean => {
  // if the bounds don't represent a polygon (i.e. less than 3 sides),exit out
  if (bounds.size <= 2) return false;

  /*
   in order to save on refactoring, polygonBound's args have been kept the same.
   booleanPointInPolygon requires use of the turf library's 'polygon' and 'point' types.
   in addition, the last point of a turf polygon's points array must same as the first point.
   for all of these reasons, the 'reduce' below was created.
  */
  const formattedBounds = bounds.toArray().reduce((accum, currentBound, i) => {
    accum.push([currentBound.x, currentBound.y] as number[]);

    if (i === bounds.size - 1) accum.push(accum[0]);

    return accum;
  }, [] as Array<number[]>);

  /*
   turf's polygon helper requires the points to be an array of arrays inside /another/ array.
   i am also not entirely sure why, but it works
  */
  const turfPoly = polygon([formattedBounds]);
  const turfPoint = point([x, y]);

  return booleanPointInPolygon(turfPoint, turfPoly);
};
