import React from 'react';
import { connect } from 'react-redux';
import * as Immutable from 'immutable';

import { RootState } from 'reducers/rootReducer';
import { Figure, isClosedFigure } from 'types/figure';
import { FigureType } from 'types/figureType';
import { CoordinatePoint, Point } from 'types/point';
import { mapMovedPoint } from 'helpers/move/mapMovedPoint';
import { selectors as editModeSelectors } from 'ducks/editMode';
import { selectors as figuresSelectors } from 'ducks/model/figures';
import { selectors as selectionSelectors } from 'ducks/selection/selection';
import Wall from 'components/sketch/Wall/Wall';
import FigureAngles from 'components/sketch/Figure/FigureAngles';

interface InputProps {
  readonly figureId: string;
}

interface StateProps {
  readonly figure: Figure;
  readonly figurePoints: Immutable.List<Point | CoordinatePoint>;
  readonly isMovingMode: boolean;
  readonly movedPoints: Immutable.List<Point>;
}

type Props = InputProps & StateProps;

const FigureWalls = ({ figure, figurePoints, isMovingMode, movedPoints }: Props): JSX.Element | null => {
  if (!figure || !figurePoints || !figurePoints.size) {
    return null;
  }

  const polygon = figurePoints.map((p) => (isMovingMode ? mapMovedPoint(movedPoints, p) : p));
  const isClosed = isClosedFigure(figure);
  const isClosedOrInterior = isClosed || figure.type === FigureType.INTERIOR;
  return (
    <>
      {figure.walls.map((wallId) => (
        <Wall key={wallId} wallId={wallId} figureId={figure.figureId} figurePoints={polygon} isFigureClosed={isClosedOrInterior} />
      ))}
      {figure.type !== FigureType.INTERIOR && <FigureAngles figurePoints={polygon} isFigureClosed={isClosed} />}
    </>
  );
};

export default connect((state: RootState, { figureId }: InputProps): StateProps => {
  const figure = figuresSelectors.getFigureById(state, figureId);
  return {
    figure,
    figurePoints: figuresSelectors.getFigurePointsForDraw(state, figure.figureId),
    isMovingMode: editModeSelectors.isMovingMode(state),
    movedPoints: selectionSelectors.getMovedSelectedPoints(state),
  };
})(FigureWalls);
