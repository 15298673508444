import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Immutable from 'immutable';
import styled from '@emotion/styled';

import { RootState } from 'reducers/rootReducer';
import { CircleWall, Wall } from 'types/wall';
import { Point, CoordinatePoint } from 'types/point';
import { WallType } from 'types/wallType';
import { getCurveByArcPoint } from 'helpers/curve/getArcPoint';
import { mapMovedPoint } from 'helpers/move/mapMovedPoint';
import { isTouchDevice } from 'helpers/browserDetect';
import { createMeasurementLabel } from 'helpers/label/measurementLabel';
import { MeasurementLabelType } from 'types/measurementLabel';
import { getAdditionalLine, isAdditionalLinesDisplayed } from 'helpers/draw/getHelperLine';
import { selectors as wallSelectors, actions as wallActions } from 'ducks/model/walls';
import { selectors as numpadModalSelectors } from 'ducks/modal/numpadModal';
import { actions as editModeActions, selectors as editModeSelectors } from 'ducks/editMode';
import { actions as resizeSketchModalActions } from 'ducks/modal/resizeSketchModal';
import { selectors as moveObjectsSelectors } from 'ducks/moveObjects';
import { actions as splitWallActions } from 'ducks/draw/splitWall';
import { selectors as selectionSelectors } from 'ducks/selection/selection';
import { MousePointToSVGPointFunction, selectors as viewportSelectors, selectors as viewPortSelectors } from 'ducks/viewport';
import { selectors as settingsSelectors } from 'ducks/settings';
import WallLabel from 'components/sketch/MeasurementLabel/WallLabel';
import SelectedSegmentLine from 'components/sketch/SelectedSegment/SelectedSegmentLine';

import { compose } from 'helpers/utils';
import MoveWallPointer from './MoveWallPointer';
import wallStyles from './styles';

interface InputProps {
  readonly wallId: string;
  readonly figureId: string;
  readonly figurePoints: Immutable.List<Point | CoordinatePoint>;
  readonly isFigureClosed: boolean;
}

interface StateProps {
  readonly wall: Wall;
  readonly wallPoints: Immutable.List<Point>;
  readonly isMultiselectMode: boolean;
  readonly isSelectMode: boolean;
  readonly isSelectedMode: boolean;
  readonly isMovingMode: boolean;
  readonly isSplittingMode: boolean;
  readonly isDrawingMode: boolean;
  readonly isEditMode: boolean;
  readonly hasObjectMoved?: boolean;
  readonly selectedObjects: Immutable.List<string>;
  readonly movedPoints: Immutable.List<Point>;
  readonly getMousePointToSvgPoint: MousePointToSVGPointFunction;
  readonly unitOfMeasurement: string;
  readonly precision: number;
  readonly gridSize: number;
  readonly isShowingNumpadModal: boolean;
  readonly zoomInPercent: number;
}

interface ActionProps {
  readonly selectObjects: typeof editModeActions.selectObjects;
  readonly startMove: typeof editModeActions.switchToMoving;
  readonly splitWall: (wallId: string, point: CoordinatePoint) => void;
  readonly showResizeSketchModal: () => void;
  readonly updateWall: typeof wallActions.update;
}

type Props = InputProps & StateProps & ActionProps;

interface LineProps {
  readonly selectedWall: boolean;
  readonly selectedFigure: boolean;
  readonly isEditMode: boolean;
  readonly isInteriorWall: boolean;
}


class WallComponent extends Component<Props> {
  private handleDoubleClick = (): void => {
    const { isShowingNumpadModal, showResizeSketchModal } = this.props;

    if (isTouchDevice || isShowingNumpadModal) {
      showResizeSketchModal();
    }
  };

  private handleTouchStart = (event: React.TouchEvent<SVGLineElement | SVGPathElement>): void => {
    const {
      isSelectMode, isSelectedMode, isSplittingMode, getMousePointToSvgPoint
    } = this.props;

    // if this is not a single touch
    if (event.touches.length !== 1) {
      return;
    }

    const point = getMousePointToSvgPoint({ clientX: event.touches[0].clientX, clientY: event.touches[0].clientY });
    // if we don't have a point
    if (!point) {
      return;
    }

    this.handlePointerDown(point);
    if (isSelectMode || isSelectedMode) {
      event.stopPropagation();
      return;
    }

    if (isSplittingMode) {
      event.stopPropagation();
    }
  }

  private handleMouseDown = (event: React.MouseEvent<SVGLineElement | SVGPathElement>): void => {
    const {
      isSelectMode, isSelectedMode, isSplittingMode, getMousePointToSvgPoint
    } = this.props;

    const point = getMousePointToSvgPoint({ clientX: event.clientX, clientY: event.clientY });
    // if this is not the left button, or we don't have a relative point
    if (event.button !== 0 || !point) {
      return;
    }

    this.handlePointerDown(point);
    if (isSelectMode || isSelectedMode) {
      event.stopPropagation();
      return;
    }

    if (isSplittingMode) {
      event.stopPropagation();
    }
  }

  private handlePointerDown = (point: CoordinatePoint): void => {
    const {
      isSelectMode, isSelectedMode, selectObjects, wallId, selectedObjects, startMove, isSplittingMode, splitWall
    } = this.props;

    if (isSelectMode || isSelectedMode) {
      const selectedWall = selectedObjects.includes(wallId);
      if (!selectedWall) {
        // we can't precisely find collision detection with arc wall
        // so we have to fire start move event here
        selectObjects([wallId]);
      }
      startMove(point);
      return;
    }

    if (isSplittingMode) {
      splitWall(wallId, point);
    }
  };

  private handleTouchEnd = (event: React.TouchEvent<SVGLineElement | SVGPathElement>): void => {
    const {
      hasObjectMoved, isMovingMode, isMultiselectMode
    } = this.props;

    // note that on touch end we will use event.changedTouches instead of
    // event.touches- the later is not available here
    if (event.changedTouches.length !== 1) {
      return;
    }

    this.handlePointerUp();
    if (isMovingMode && !hasObjectMoved) {
      event.stopPropagation();
    }
    if (isMultiselectMode) {
      event.stopPropagation();
    }
  }

  private handleMouseUp = (event: React.MouseEvent<SVGLineElement | SVGPathElement>): void => {
    const {
      hasObjectMoved, isMovingMode, isMultiselectMode
    } = this.props;

    // if this is not the left button
    if (event.button !== 0) {
      return;
    }

    this.handlePointerUp();
    if (isMovingMode && !hasObjectMoved) {
      event.stopPropagation();
    }
    if (isMultiselectMode) {
      event.stopPropagation();
    }
  }

  private handlePointerUp = (): void => {
    const {
      hasObjectMoved, isMovingMode, wallId, selectObjects, isMultiselectMode
    } = this.props;

    if (isMovingMode && !hasObjectMoved) {
      selectObjects([wallId]);
    }
    if (isMultiselectMode) {
      selectObjects([wallId]);
    }
  };

  private measurementLabel = (wallPoints: Immutable.List<CoordinatePoint>, isBaselined?: boolean): MeasurementLabelType =>
    compose(({ unitOfMeasurement, precision, figurePoints, isFigureClosed, gridSize }: Props) =>
      createMeasurementLabel(figurePoints, isFigureClosed, wallPoints, isBaselined, unitOfMeasurement, precision, gridSize))(this.props);

  public render(): JSX.Element | null {
    const {
      wall,
      wallPoints,
      selectedObjects,
      wallId,
      isMovingMode,
      movedPoints,
      figureId,
      isFigureClosed,
      isSplittingMode,
      isDrawingMode,
      isEditMode,
      isShowingNumpadModal,
      updateWall,
      zoomInPercent
    } = this.props;
    if (!wall) {
      return null;
    }

    const styles = wallStyles.get(zoomInPercent);

    const Line = styled.line<LineProps>(
      styles.base,
      ({ isInteriorWall }: LineProps) => isInteriorWall && styles.interior,
      ({ selectedWall }: LineProps) => selectedWall && styles.active,
      ({ selectedFigure }: LineProps) => selectedFigure && styles.active,
      ({ isEditMode }: LineProps) => (isEditMode ? styles.edit : styles.hover),
    );

    const Circle = styled.circle<LineProps>(
      styles.base,
      ({ isInteriorWall }: LineProps) => isInteriorWall && styles.interior,
      ({ selectedWall }: LineProps) => selectedWall && styles.active,
      ({ selectedFigure }: LineProps) => selectedFigure && styles.active,
    );

    const Path = styled.path<LineProps>(styles.base, ({ selectedWall, selectedFigure }: LineProps) => (selectedWall || selectedFigure) && styles.active);


    const point1 = wallPoints.get(0)!;
    const point2 = wall.wallType === WallType.CIRCLE ? point1 : wallPoints.get(1)!;

    if (!point1 || !point2) {
      return null;
    }

    const selectedWall = selectedObjects.includes(wallId);
    const selectedFigure = selectedObjects.includes(figureId);

    const p1: CoordinatePoint = isMovingMode ? mapMovedPoint(movedPoints, point1) : point1;
    const p2: CoordinatePoint = isMovingMode ? mapMovedPoint(movedPoints, point2) : point2;

    const isInteriorWall = wall.wallType === WallType.INTERIOR;

    const wallLabelOnClick = (e: any) => {
      e.preventDefault();
      if (wall.fontSize < 300) {
        updateWall(wall.wallId, { fontSize: wall.fontSize + 25 });
      } else {
        updateWall(wall.wallId, { fontSize: 100 });
      }
    };

    if (wall.wallType === WallType.ARC) {
      const point3: Point = wallPoints.get(2)!;
      const arcPoint: CoordinatePoint = isMovingMode ? mapMovedPoint(movedPoints, point3) : point3;

      const curveParameters = getCurveByArcPoint(p1, p2, arcPoint);
      const curveOrientation = curveParameters.arcHeight < 0 ? 1 : 0;

      // eslint-disable-next-line max-len
      const curveCmd = `A ${curveParameters.r} ${curveParameters.r} ${curveParameters.angle} ${curveParameters.bigArc} ${curveOrientation} ${p2.x} ${p2.y}`;
      const d1 = `
        M ${p1.x} ${p1.y}
        ${curveCmd}
      `;

      const c = {
        x: (p1.x + p2.x) / 2,
        y: (p1.y + p2.y) / 2,
      };

      const arcWidthLine: Immutable.List<CoordinatePoint> = Immutable.List([p1, p2]);

      const arcHeightLine: Immutable.List<CoordinatePoint> = Immutable.List([arcPoint, c]);

      return (
        <>
          <Path
            id={`${wallId}`}
            d={d1}
            onTouchStart={this.handleTouchStart}
            onMouseDown={this.handleMouseDown}
            onMouseUp={this.handleMouseUp}
            onTouchEnd={this.handleTouchEnd}
          />
          <g
            id={`${wallId}-clickable`}
            onTouchStart={this.handleTouchStart}
            onMouseDown={this.handleMouseDown}
            onMouseUp={this.handleMouseUp}
            onTouchEnd={this.handleTouchEnd}
            onDoubleClick={this.handleDoubleClick}
          >
            <use xlinkHref={`#${wallId}`} {...styles.wallSVGSegment} />
            {(isTouchDevice || isShowingNumpadModal) && <use xlinkHref={`#${wallId}`} {...styles.wallSVGSegmentTouchHandle} />}
          </g>
          <SelectedSegmentLine wallPoints={arcWidthLine} hideLabel />
          <SelectedSegmentLine wallPoints={arcHeightLine} hideLabel />
          {selectedWall && <MoveWallPointer p1={arcPoint} p2={arcPoint} />}
        </>
      );
    }

    if (wall.wallType === WallType.LINE && !isFigureClosed) {
      const additionalLine1 = getAdditionalLine(p1, p1.x, p2.y);
      const additionalLine2 = getAdditionalLine(p2, p1.x, p2.y);
      const isSegmentLinesDisplayed = isAdditionalLinesDisplayed(p1, p2);

      return (
        <>
          <Line
            id={wallId}
            isInteriorWall={false}
            selectedWall={selectedWall}
            selectedFigure={selectedFigure}
            isEditMode={isEditMode || isSplittingMode || isDrawingMode || isMovingMode}
            x1={p1.x}
            y1={p1.y}
            x2={p2.x}
            y2={p2.y}
          />
          <g
            id={`${wallId}-clickable`}
            onTouchStart={this.handleTouchStart}
            onMouseDown={this.handleMouseDown}
            onMouseUp={this.handleMouseUp}
            onTouchEnd={this.handleTouchEnd}
            onDoubleClick={this.handleDoubleClick}
          >
            <use xlinkHref={`#${wallId}`} {...styles.wallSVGSegment} />
            {(isTouchDevice || isShowingNumpadModal) && <use xlinkHref={`#${wallId}`} {...styles.wallSVGSegmentTouchHandle} />}
          </g>
          {selectedWall && <MoveWallPointer p1={p1} p2={p2} />}
          <>
            <WallLabel measurementLabel={this.measurementLabel(Immutable.List<CoordinatePoint>([p1, p2]), true)} />
            {isSegmentLinesDisplayed && (
              <>
                <SelectedSegmentLine wallPoints={additionalLine1} />
                <SelectedSegmentLine wallPoints={additionalLine2} />
              </>
            )}
          </>
        </>
      );
    }

    if (wall.wallType === WallType.CIRCLE) {
      const { radius } = wall as CircleWall;
      return (
        <>
          <Circle
            id={wallId}
            isInteriorWall={false}
            selectedWall={selectedWall}
            selectedFigure={selectedFigure}
            isEditMode
            cx={p1.x}
            cy={p1.y}
            r={radius}
            {...styles.drawingBase}
          />
        </>
      );
    }

    return (
      <>
        <Line
          id={wallId}
          isInteriorWall={isInteriorWall}
          selectedWall={selectedWall}
          selectedFigure={selectedFigure}
          isEditMode={isEditMode || isSplittingMode || isDrawingMode || isMovingMode}
          x1={p1.x}
          y1={p1.y}
          x2={p2.x}
          y2={p2.y}
        />
        <g
          id={`${wallId}-clickable`}
          onTouchStart={this.handleTouchStart}
          onMouseDown={this.handleMouseDown}
          onMouseUp={this.handleMouseUp}
          onTouchEnd={this.handleTouchEnd}
          onDoubleClick={this.handleDoubleClick}
        >
          <use xlinkHref={`#${wallId}`} {...(isInteriorWall ? styles.interiorSVGSegment : styles.wallSVGSegment)} />
          {(isTouchDevice || isShowingNumpadModal) && <use xlinkHref={`#${wallId}`} {...styles.wallSVGSegmentTouchHandle} />}
        </g>
        {selectedWall && <MoveWallPointer p1={p1} p2={p2} />}
        {!isInteriorWall && (
          <>
            <WallLabel
              onClick={wallLabelOnClick}
              fontSize={wall.fontSize}
              measurementLabel={this.measurementLabel(Immutable.List<CoordinatePoint>([p1, p2]), true)}
            />
          </>
        )}
      </>
    );
  }
}

export default connect(
  (state: RootState, { wallId }: InputProps): StateProps => ({
    wall: wallSelectors.getWallById(state, wallId),
    wallPoints: wallSelectors.getWallPoints(state, wallId),
    isMultiselectMode: editModeSelectors.isMultiselectMode(state),
    isSelectMode: editModeSelectors.isSelectMode(state),
    isSelectedMode: editModeSelectors.isSelectedMode(state),
    isMovingMode: editModeSelectors.isMovingMode(state),
    isSplittingMode: editModeSelectors.isSplittingMode(state),
    isEditMode: editModeSelectors.isEditMode(state),
    isDrawingMode: editModeSelectors.isDrawingMode(state),
    hasObjectMoved: moveObjectsSelectors.hasMoved(state),
    selectedObjects: editModeSelectors.getSelectedObjects(state),
    isShowingNumpadModal: numpadModalSelectors.isShowing(state),
    movedPoints: selectionSelectors.getMovedSelectedPoints(state),
    getMousePointToSvgPoint: viewportSelectors.getMousePointToSvgPoint(state),
    unitOfMeasurement: settingsSelectors.getUnitOfMeasure(state),
    precision: settingsSelectors.getPrecision(state),
    gridSize: settingsSelectors.getGridSizeInFeet(state),
    zoomInPercent: viewPortSelectors.getZoomInPercent(state),
  }),
  {
    showResizeSketchModal: resizeSketchModalActions.showResize,
    selectObjects: editModeActions.selectObjects,
    startMove: editModeActions.switchToMoving,
    splitWall: splitWallActions.splitWall,
    updateWall: wallActions.update,
  },
)(WallComponent);
