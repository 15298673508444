import * as Immutable from 'immutable';

import { Figure, isClosedFigure } from 'types/figure';
import { Wall } from 'types/wall';
import { SketchModel } from 'types/sketchModel';
import { isClosingBaseFigure } from 'helpers/model/figurePoints';

const undoClosedFigure = (
  oldFigures: Immutable.Map<string, Figure>,
  newFigures: Immutable.Map<string, Figure>,
  oldWalls: Immutable.Map<string, Wall>,
  newWalls: Immutable.Map<string, Wall>,
): boolean => {
  const oldPositionedLabelsCount = oldFigures.filter(figure => isClosedFigure(figure)).size;
  const newPositionedLabelsCount = newFigures.filter(figure => isClosedFigure(figure)).size;
  return oldPositionedLabelsCount !== newPositionedLabelsCount
    && oldWalls.size === newWalls.size; // not on remove wall
};

const redoClosedFigure = (
  oldFigures: Immutable.Map<string, Figure>,
  newFigures: Immutable.Map<string, Figure>,
  oldWalls: Immutable.Map<string, Wall>,
  newWalls: Immutable.Map<string, Wall>,
): boolean => {
  const oldClosedFiguresCount = oldFigures.filter(figure => isClosingBaseFigure(oldWalls, figure)).size;
  const newClosedFiguresCount = newFigures.filter(figure => isClosingBaseFigure(newWalls, figure)).size;
  return oldClosedFiguresCount !== newClosedFiguresCount
    && oldWalls.size + 1 === newWalls.size; // not on remove wall
};

export const hasClosedFigure = (
  step: number,
  oldModel: SketchModel,
  newModel: SketchModel,
): boolean => {
  if (step === 1) return undoClosedFigure(oldModel.figures, newModel.figures, oldModel.walls, newModel.walls);
  return redoClosedFigure(oldModel.figures, newModel.figures, oldModel.walls, newModel.walls);
};
