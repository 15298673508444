/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { versionConfig } from 'config/versionConfig';

const style = css({
  position: 'fixed',
  bottom: '10px',
  right: '10px'
});

const AppVersion = (): JSX.Element => (
  <div css={style}>
    <span className="version">{versionConfig.version}</span>
    <span> - </span>
    <span className="sha1">{versionConfig.gitSHA1}</span>
  </div>
);

export default AppVersion;
