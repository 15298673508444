import {
  all, takeLatest, select, put,
} from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { createAction, getType, ActionType } from 'typesafe-actions';

import { RootState } from 'reducers/rootReducer';
import { PointType } from 'types/pointType';
import { Point } from 'types/point';
import { Dimensions } from 'types/dimensions';
import { Box } from 'types/box';
import { getSnappedToGrid } from 'helpers/snap/snapToGrid';
import { selectors as pointsSelectors, actions as pointsActions } from 'ducks/model/points';
import { selectors as viewportSelectors } from 'ducks/viewport';
import { selectors as settingsSelectors } from 'ducks/settings';

// Action Creators
export const actions = {
  addImage: createAction(
    'bluePrintImage/addImage',
    (imageUrl: string, width: number, height: number) => ({ imageUrl, width, height }),
  )<ImagePayload>(),

  removeImage: createAction('bluePrintImage/removeImage')<void>(),

  setImagePosition: createAction('bluePrintImage/setImagePosition')<string>(),

  setImageLock: createAction('bluePrintImage/setImageLock')<boolean>(),

  resize: createAction('bluePrintImage/resizeImage')<Dimensions>(),
};

export type Actions = ActionType<typeof actions>

export interface BluePrintImageState {
  readonly imageUrl: string;
  readonly rotation: number;
  readonly pointId: string;
  readonly locked: boolean;
  readonly size: Dimensions;
}

export interface ImagePayload {
  readonly imageUrl: string;
  readonly width: number;
  readonly height: number;
}

// Initial State
const initialState: BluePrintImageState = {
  imageUrl: '',
  rotation: 0,
  pointId: '',
  locked: true,
  size: {
    width: 0,
    height: 0,
  },
};

// Selectors
const getImage = (rootState: RootState): BluePrintImageState => rootState.bluePrintImage;

const getImageForSketchModel = (rootState: RootState): ImagePayload => {
  const state = rootState.bluePrintImage;
  return {
    imageUrl: state.imageUrl,
    width: state.size.width,
    height: state.size.height,
  };
};

const getImagePosition = (rootState: RootState): Point => {
  const image = getImage(rootState);
  return pointsSelectors.getPointById(rootState, image.pointId);
};

const isImageLocked = (rootState: RootState): boolean => {
  const image = getImage(rootState);
  return image.locked;
};

export const selectors = {
  getImage,
  getImageForSketchModel,
  getImagePosition,
  isImageLocked,
};

// Reducer
const addImageReducer = (state: BluePrintImageState, imagePayload: ImagePayload): BluePrintImageState => ({
  ...state,
  imageUrl: imagePayload.imageUrl,
  size: {
    width: imagePayload.width,
    height: imagePayload.height,
  },
});

const removeImageReducer = (state: BluePrintImageState): BluePrintImageState => ({
  ...state,
  imageUrl: '',
  size: {
    width: 0,
    height: 0,
  },
  pointId: '',
});

const setImagePositionReducer = (state: BluePrintImageState, pointId: string): BluePrintImageState => ({
  ...state,
  pointId,
});

const changeImageLockReducer = (state: BluePrintImageState, locked: boolean): BluePrintImageState => ({
  ...state,
  locked,
});

const resizeImageReducer = (state: BluePrintImageState, size: Dimensions): BluePrintImageState => ({
  ...state,
  size,
});

export const reducer = (state: BluePrintImageState = initialState, action: Actions): BluePrintImageState => {
  switch (action.type) {
    case getType(actions.addImage):
      return addImageReducer(state, action.payload);

    case getType(actions.removeImage):
      return removeImageReducer(state);

    case getType(actions.setImagePosition):
      return setImagePositionReducer(state, action.payload);

    case getType(actions.setImageLock):
      return changeImageLockReducer(state, action.payload);

    case getType(actions.resize):
      return resizeImageReducer(state, action.payload);

    default:
      return state;
  }
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doAddImage() {
    const image: BluePrintImageState = selectors.getImage(yield select());
    if (image.pointId) {
      const point: Point = pointsSelectors.getPointById(yield select(), image.pointId);
      if (point) return;
    }

    const viewBox: Box = viewportSelectors.getViewBox(yield select());

    const pointId = uuidv4();
    const snapDivision = settingsSelectors.getPrecision(yield select());
    const point = getSnappedToGrid({
      x: viewBox.x + (viewBox.width / 2),
      y: viewBox.y + (viewBox.height / 2),
      pointId,
      pointType: PointType.BLUE_PRINT_IMAGE,
    }, snapDivision);

    yield put(pointsActions.add(point));
    yield put(actions.setImagePosition(pointId));
  }

  return function* saga() {
    yield all([
      takeLatest(actions.addImage, doAddImage),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
