/**
 * Motivation: sketch is a list of polygons, each polygon is a list of walls.
 * I.e. we have non-oriented graph of walls.
 * User can change graph at any place (i.e. add or remove wall)
 * for "close" some polygon, i.e. add to non-closed polygon shared walls
 * we need to find path between start and end point.
 *
 * Here is a BFS implementation with calculating path (if exist) between p1 and p2
 * https://en.wikipedia.org/wiki/Breadth-first_search
 */
import * as Immutable from 'immutable';

import { Wall } from 'types/wall';
import { WallType } from 'types/wallType';
import { BfsNode, backTrace, getAdjacentNodes } from 'helpers/path/graphUtils';

/**
 * Breath-First graph searching algorithm.
 * Returns the shortest path between startNode and targetNode.
 * Time complexity: O(|V|^2).
 *
 * @param walls Adjacency matrix, which represents the graph (list of walls)
 * @param startNode Start node.
 * @param targetNode Target, which should be reached.
 * @param exceptWalls list of wall which should not be in the path
 * @returns Shortest path from startNode to targetNode.
 *
 */
export const findPath = ( // bfs
  walls: Immutable.Map<string, Wall>,
  startNode: string,
  targetNode: string,
  exceptWalls: string[],
): string[] => {
  const exceptWallsFiltered = exceptWalls.filter(wallId => {
    const wall = walls.get(wallId)!;
    return wall.wallType !== WallType.INTERIOR;
  });
  const nonInteriorWalls = walls.filter(wall => wall.wallType !== WallType.INTERIOR);

  const parents: { [nodeId: string]: BfsNode } = {};
  const queue: string[] = [];
  const visited: { [nodeId: string]: boolean } = {};
  let currentNode: string;
  queue.push(startNode);
  visited[startNode] = true;

  if (startNode === targetNode) {
    return [];
  }

  const addToQueue = (node: BfsNode): void => {
    parents[node.nodeId] = node;
    visited[node.nodeId] = true;
    queue.push(node.nodeId);
  };

  while (queue.length) {
    currentNode = queue.shift()!;
    if (currentNode === targetNode) {
      return backTrace(parents, startNode, targetNode);
    }
    const adjacentNodes = getAdjacentNodes(nonInteriorWalls, currentNode, visited, exceptWallsFiltered);
    adjacentNodes.forEach(addToQueue);
  }

  // path is not found
  return [];
};
