import * as Immutable from 'immutable';

import { CoordinatePoint } from 'types/point';

/**
 * get lines for arc
 * it is done for approximate arc due to it is part of ellipse
 * and we don't want to solve some complex equation for detect collisions
 *
 * it is possible that in future we will divide arc to more than 2 segments
 * (for increase precision)
 */
export const arcToLines = <T extends CoordinatePoint>(wallPoints: Immutable.List<T>): Immutable.List<T>[] => {
  const p1 = wallPoints.get(0)!;
  const p2 = wallPoints.get(1)!;
  const arcPoint = wallPoints.get(2)!;

  return [
    Immutable.List([p1, arcPoint]),
    Immutable.List([arcPoint, p2]),
  ];
};
