import * as Immutable from 'immutable';

import { Figure, isClosedFigure } from 'types/figure';
import { getFiguresWithWall } from 'helpers/model/figureWalls';

export const isTriangleRoom = (
  figures: Immutable.Map<string, Figure>,
  wallsWithPoint: string[],
): boolean => {
  if (wallsWithPoint.length !== 2) {
    return false;
  }
  const prevWallId = wallsWithPoint[0];
  const figuresWithPrevWall = getFiguresWithWall(figures, prevWallId);
  const figureId = figuresWithPrevWall[0];
  const figure = figures.get(figureId)!;

  return figure.walls.length === 3 && isClosedFigure(figure);
};
