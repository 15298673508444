import * as Immutable from 'immutable';
import {
  all, select, put, call,
} from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { FigureType } from 'types/figureType';
import { Point } from 'types/point';
import { Figure } from 'types/figure';
import { Page } from 'types/page';
import { Wall, CircleWall } from 'types/wall';
import { SelectableObjects } from 'types/selection';
import { findPath } from 'helpers/path/findPath';
import { minimizeFigureArea } from 'helpers/cycle/minimizeFigureArea';
import { getFiguresForUpdate } from 'helpers/cycle/updateFigures';
import { getSharedWallPaths } from 'helpers/cycle/subPaths';
import { selectors as wallsSelectors } from 'ducks/model/walls';
import { selectors as figuresSelectors, actions as figuresActions } from 'ducks/model/figures';
import { selectors as areaTypeModalSelectors, actions as areaTypeModalActions } from 'ducks/modal/areaTypeModal';
import { actions as subAreaModalActions } from 'ducks/modal/subAreaModal';
import { selectors as modelSelectors } from 'ducks/model/model';
import { selectors as pagesSelectors, actions as pagesActions } from 'ducks/model/pages';
import { actions as editModeActions } from 'ducks/editMode';
import { removeFigure } from 'ducks/remove/removeFigure';
import { WallType } from 'types/wallType';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
/**
 * update figures which has shared points with new one
 * @param figureId - figure id which is closed figure
 * @param lastWallId
 */
export function* updateOtherFigures(figureId: string, lastWallId: string) {
  const selectableObjects: SelectableObjects = modelSelectors.getSelectableObjects(yield select());
  const figures = getFiguresForUpdate(selectableObjects, figureId, lastWallId);

  yield all(figures.map(figure => put(figuresActions.update(figure.figureId, figure))));
}

export function* createFigureWithWall(wallId: string) {
  const figureId = uuidv4();
  const figure: Figure = {
    figureId,
    type: FigureType.BASE,
    walls: [wallId],
  };
  yield put(
    figuresActions.add(figure),
  );
  const page: Page | undefined = pagesSelectors.getCurrentPage(yield select());
  if (page) yield put(pagesActions.upsertObject(page.pageId, figureId, figure));

  return figureId;
}

/**
 * split all shared-wall paths
 */
export function* splitSharedWallsPaths(figureId: string) {
  const selectableObjects: SelectableObjects = modelSelectors.getSelectableObjects(yield select());
  const figuresIds = getSharedWallPaths(selectableObjects, figureId);
  const originalFigure = selectableObjects.figures.get(figureId)!;

  let nonSharedWalls: string[] = [];
  for (let i = 0; i < figuresIds.length; i++) {
    const fId = figuresIds[i];
    const figure = selectableObjects.figures.get(fId)!;
    const wallsIds = figure.walls.filter(
      wallId => ( // eslint-disable-line no-loop-func
        !nonSharedWalls.includes(wallId)
        && !originalFigure.walls.includes(wallId)
      ),
    );

    nonSharedWalls = nonSharedWalls.concat(wallsIds);
  }

  yield all(nonSharedWalls.map((wallId: string) => call(createFigureWithWall, wallId)));
  yield all(figuresIds.map((fId: string) => call(removeFigure, fId)));
}

/**
 * try to "close" figure, i.e. created closed polygon
 */
export function* closeFigure(point: Point, figureId: string) {
  // analyse if polygon is closed but by other path
  const walls: Immutable.Map<string, Wall> = wallsSelectors.getAllWalls(yield select());
  const figure: Figure = figuresSelectors.getFigureById(yield select(), figureId);

  const figurePoints: Immutable.List<Point> = figuresSelectors.getFigurePoints(yield select(), figureId);
  const firstPoint: Point = figurePoints.first();
  const firstWall: Wall | CircleWall = walls.first();
  const path = findPath(walls, point.pointId, firstPoint.pointId, figure.walls);
  const isMultiFamily = areaTypeModalSelectors.isMultiFamily(yield select());
  if (path.length > 0 || firstWall.wallType === WallType.CIRCLE) {
    yield put(figuresActions.update(figureId, {
      walls: [...figure.walls, ...path],
      isMultiFamily,
    }));

    if (figure.type !== FigureType.INTERIOR) {
      const selectableObjects: SelectableObjects = modelSelectors.getSelectableObjects(yield select());
      const newWalls = minimizeFigureArea(
        selectableObjects, point.pointId, firstPoint.pointId, figure.walls, path,
      );

      yield put(figuresActions.update(figureId, {
        walls: newWalls,
        isMultiFamily,
      }));

      yield call(updateOtherFigures, figureId, figure.walls[figure.walls.length - 1]);
      yield call(splitSharedWallsPaths, figureId);
    }
  }
  const isClosingBaseFigure: boolean = figuresSelectors.isClosingBaseFigureOnId(yield select(), figureId);
  if (isClosingBaseFigure) {
    yield put(editModeActions.switchToSelected([figureId]));
    if (figure.isMultiFamily) {
      yield put(subAreaModalActions.showForFigure(figureId));
    } else {
      yield put(areaTypeModalActions.showForFigure(figureId));
    }
  }
}
/* eslint-enable @typescript-eslint/explicit-function-return-type */
