import * as Immutable from 'immutable';

import { Point, CoordinatePoint, isPoint } from 'types/point';
import { getSnappedToGrid } from 'helpers/snap/snapToGrid';

/**
 * Return new corrected vector d1, such that pMoved = p + d1 stays on the grid,
 * where p - is the nearest point of the polygon in the "d" direction
 * @param polygon snapping polygon
 * @param d vector, showing direction for mouse moving
 * @param snapDivision
 * @returns corrected vector d1
 */
export const snapFigureToGrid = (
  polygon: Immutable.List<Point | CoordinatePoint>, d: CoordinatePoint, snapDivision: number,
): CoordinatePoint => {
  const dist = Math.sqrt(d.x * d.x + d.y * d.y);
  if (dist === 0) {
    return d;
  }

  // for find best corner "p" we should rotate polygon
  // from (x,y) coordinates to (u, v) coordinates ,
  // where u is vector d (i.e. direction where user move mouse)
  // and v is orthogonal vector to u
  // https://stackoverflow.com/questions/9190149/how-to-rotate-coordinate-system

  // calculate sin and cos for theta - angle between u and X axis
  const sinF = d.x / dist;
  const cosF = d.y / dist;

  // rotated polygon
  const rotated = polygon.map(p => ({
    ...p,
    x: p.x * cosF - p.y * sinF,
    y: p.x * sinF + p.y * cosF,
  }));

  // i.e. we should find max by u (former y)
  // pMax is the best corner for snap
  const pMax = rotated.maxBy(p => p.y)!;

  // get non-rotated (x,y) coordinates of the pMax point
  const pOrig = polygon.find(p => isPoint(p) && isPoint(pMax)
    ? p.pointId === pMax.pointId
    : !isPoint(p) && !isPoint(pMax))!;

  // snap to grid best corner (pOrig)
  const pMoved = {
    x: pOrig.x + d.x,
    y: pOrig.y + d.y,
  };
  const snapped = getSnappedToGrid(pMoved, snapDivision);

  // find new vector d that after we move polygon to that vector,
  // best corner (point pOrig) should be exactly snapped to the grid
  const d1 = {
    x: d.x + (snapped.x - pMoved.x),
    y: d.y + (snapped.y - pMoved.y),
  };

  return d1;
};
