import { isImmutable } from 'immutable';
import * as Immutable from 'immutable';
import {
  put, select, all, takeLatest, call,
} from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { createAction, getType, ActionType } from 'typesafe-actions';
import { printConfig } from 'config/printConfig';
import { toast } from 'react-toastify';

import { PRINT_TABLE_CONTAINER } from 'components/sketch/PrintTable/PrintTableView';
import { formatSketchObjects } from 'helpers/save/formatSketchObjects';
import { pixelAreaToSquareFeet } from 'helpers/pixelsToDistance';
import { calculateTotalGlaArea } from 'helpers/polygonArea';

import { messages } from 'config/messages';
import { Point } from 'types/point';
import { RootState } from 'reducers/rootReducer';
import { Page, PageObject } from 'types/page';
import { Figure } from 'types/figure';
import { Wall } from 'types/wall';
import { PositionedLabel } from 'types/positionedLabel';
import { PositionedSymbol } from 'types/positionedSymbol';
import { selectors as viewportSelectors } from 'ducks/viewport';
import { selectors as wallsSelectors, actions as wallsActions } from 'ducks/model/walls';
import {
  selectors as figuresSelectors, actions as figuresActions
} from 'ducks/model/figures';
import { selectors as positionedLabelsSelectors, actions as positionedLabelsActions } from 'ducks/model/positionedLabels';
import { selectors as positionedSymbolsSelectors, actions as positionedSymbolsActions, actions as groupObjectActions } from 'ducks/model/positionedSymbols';
import { selectors as editModeSelectors, actions as editModeActions } from 'ducks/editMode';
import {
  selectors as pointsSelectors, actions as pointsActions
} from 'ducks/model/points';
import { svgAsPngUri } from 'helpers/save/svgToPng';

import { compose, listToArray, mapBy, flatten, findIndexBy } from 'helpers/utils';

/**
 * maximum number of pages
 */
export const MAX_PAGES = 6;

export interface PagesState {
  readonly pages: Immutable.List<Page>;
  readonly currentPage: Page | undefined;
  readonly previousPageId: string | undefined;
}

interface PageObjectPayload {
  readonly pageId: string;
  readonly objectId: string;
  readonly object?: Point | Wall | Figure | PositionedLabel | PositionedSymbol | undefined;
}

// Initial State
const initialState: PagesState = {
  pages: Immutable.List<Page>(),
  currentPage: undefined,
  previousPageId: undefined,
};

const parseObjectId = (obj: any) => obj.positionedSymbolId || obj.positionedLabelId || obj.wallId || obj.figureId || obj.pointId;

// Action Creators
export const actions = {
  add: createAction(
    'pages/add',
    (page?: Page): Page => page ?? { pageId: uuidv4(), objects: Immutable.Map() },
  )(),

  update: createAction('pages/update')<Page>(),

  updatePreviewPngs: createAction('pages/updatePreviewPngs')<Record<string, any>>(),

  updateTotalGla: createAction('pages/updateTotalGla')<any>(),

  remove: createAction('pages/remove')<string>(),

  delete: createAction('pages/delete')<Page>(),

  setCurrentPage: createAction('pages/setCurrentPage')<Page>(),

  setCurrentPageSuccess: createAction('pages/setCurrentPageSuccess')<Page>(),

  setFirstPage: createAction('pages/setFirstPage')<void>(),

  upsertObject: createAction(
    'pages/upsertObject',
    (pageId: string, objectId: string, object: any): PageObjectPayload => ({ pageId, objectId, object }),
  )(),

  upsertCurrentPageObjects: createAction('pages/upsertCurrentPageObjects')<void>(),

  doPersistCurrentPageInStateSuccess: createAction('pages/doPersistCurrentPageInStateSuccess')<void>(),

  clearSketchPad: createAction('pages/clearSketchPad')<void>(),

  removeObject: createAction(
    'pages/removeObject',
    (pageId: string, objectId: string): PageObjectPayload => ({ pageId, objectId }),
  )(),

  set: createAction('pages/set')<Immutable.List<Page>>(),

  createNewPage: createAction(
    'pages/createNewPage',
    (page?: Page): Page => page ?? { pageId: uuidv4(), objects: Immutable.Map() },
  )(),

  clear: createAction('pages/clear')<void>(),

  setPagesAndPlaceFirst: createAction('pages/setPagesAndPlaceFirst')<Immutable.List<Page>>(),
};

export type Actions = ActionType<typeof actions>

// Selectors
const getState = (rootState: RootState): PagesState => rootState.pages;

const getPages = (rootState: RootState): Immutable.List<Page> => getState(rootState).pages;


const getAllPagesAsMap = (
  rootState: RootState,
): Immutable.Map<string, Page> => Immutable.Map<string, Page>(getPages(rootState).map(page => [page.pageId, page]));

const getPageIds = (rootState: RootState): string[] => getPages(rootState).reduce(
  (pageIds: string[], page: Page) => ([...pageIds, page.pageId]),
  [],
);

const getPageById = (rootState: RootState, pageId: string): Page => getPages(rootState)
  .find((page: Page) => page.pageId === pageId)!;

const getCurrentPage = (rootState: RootState): Page | undefined => getState(rootState).currentPage;

const getPreviousPageId = (rootState: RootState): string | undefined => getState(rootState).previousPageId;

const getNumberOfPages = (rootState: RootState): number => getPages(rootState).size;

const findPageWithObjectId = (pages: Immutable.List<Page>, objectId: string) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  pages.find((page: Page) => {
    const objects = isImmutable(page.objects) ? page.objects : Immutable.Map<string, PageObject>(page.objects);
    return objects.has(objectId);
  });

const getPageIdWithObject = (rootState: RootState, objectId: string): string | undefined => {
  const page = findPageWithObjectId(getPages(rootState), objectId);
  return page && page.pageId; // undefined when figure is finished but no structure is chosen
};

const getPageObjects = (rootState: RootState, pageId: string, objectIds: string[]): string[] => {
  const page = getPageById(rootState, pageId);
  const objects = Immutable.Map(page.objects);

  return objects
    ? objects.reduce((pageObjectIds: string[], object: PageObject) => objectIds.includes(object.objectId)
      ? [...pageObjectIds, object.objectId]
      : pageObjectIds, [])
    : [];
};

const getObjectsPages = (
  rootState: RootState,
  objectIds: string[],
): string[] => objectIds.map(objectId => getPageIdWithObject(rootState, objectId)!);

const getPageFiguresIds = (rootState: RootState, pageId: string): string[] => getPageObjects(
  rootState, pageId, figuresSelectors.getFiguresIds(rootState),
);

const getPagePositionedSymbolsIds = (rootState: RootState, pageId: string): string[] => getPageObjects(
  rootState, pageId, positionedSymbolsSelectors.getPositionedSymbolsIds(rootState),
);

const getPagePositionedLabelsIds = (rootState: RootState, pageId: string): string[] => getPageObjects(
  rootState, pageId, positionedLabelsSelectors.getPositionedLabelsIds(rootState),
);

export const selectors = {
  getPages,
  getAllPagesAsMap,
  getPageIds,
  getPageById,
  getCurrentPage,
  getPreviousPageId,
  getNumberOfPages,
  getPageIdWithObject,
  getObjectsPages,
  getPageFiguresIds,
  getPagePositionedSymbolsIds,
  getPagePositionedLabelsIds,
};

const getIndex = (
  pages: Immutable.List<Page>, pageId: string,
): number => pages.findIndex(page => page.pageId === pageId);

// Reducers
const addPageReducer = (state: PagesState, page: Page): PagesState => ({
  ...state,
  pages: state.pages.push(page)
});

const removePageReducer = (state: PagesState, pageId: string): PagesState => {
  const { pages } = state;
  const pageIndex = getIndex(pages, pageId);

  return {
    ...state,
    pages: state.pages.remove(pageIndex)
  };
};

const setCurrentPageReducer = (
  state: PagesState,
  page: Page,
): PagesState => ({
  ...state,
  currentPage: page
});

const upsertObjectReducer = (state: PagesState, { pageId, objectId, object }: PageObjectPayload): PagesState => {
  const { pages } = state;
  const pageIndex = getIndex(pages, pageId);
  const currentPage = pages.get(pageIndex);

  const newObjects = currentPage ? Immutable.Map(currentPage.objects) : Immutable.Map<string, PageObject>();

  return {
    ...state,
    pages: pages.update(pageIndex, updatePage => ({
      ...updatePage,
      objects: newObjects.set(objectId, { objectId, object, computed: false }),
    }))
  };
};

const removeObjectReducer = (state: PagesState, { pageId, objectId }: PageObjectPayload): PagesState => {
  const { pages } = state;
  const objectIndex = pages.findIndex(page => page.pageId === pageId);

  const objects = isImmutable(pages.get(objectIndex)) ? pages.get(objectIndex)!.objects : Immutable.Map(pages.get(objectIndex)!.objects);
  if (objects && !objects.has(objectId)) {
    return state;
  }

  return {
    ...state,
    pages: pages.update(objectIndex, updatePage => ({
      ...updatePage,
      objects: objects.remove(objectId),
    })),
  };
};

const clearPagesReducer = (state: PagesState): PagesState => ({
  ...state,
  pages: Immutable.List<Page>(),
  currentPage: undefined
});

const updatePagePreviewPngsReducer = (state: PagesState, { pageId, previewPngs }: Record<string, string>): PagesState => {
  const { pages } = state;
  const objectIndex = findIndexBy((page: Page) => page.pageId === pageId)(pages);
  return {
    ...state,
    pages: pages.update(objectIndex, updatePage => ({
      ...updatePage,
      previewPngs,
    })),
  };
};

const updateTotalGlaReducer = (state: PagesState, { pageId, totalGla }: Record<any, any>): PagesState => {
  const { pages } = state;
  const objectIndex = findIndexBy((page: Page) => page.pageId === pageId)(pages);
  return {
    ...state,
    pages: pages.update(objectIndex, updatePage => ({
      ...updatePage,
      totalGla,
    })),
  };
};

const setPagesReducer = (state: PagesState, pages: Immutable.List<Page>): PagesState => ({
  ...state,
  pages,
  currentPage: pages.get(0)
});

export const reducer = (state: PagesState = initialState, action: Actions): PagesState => {
  switch (action.type) {
    case getType(actions.createNewPage):
      return addPageReducer(state, action.payload);
    case getType(actions.remove):
      return removePageReducer(state, action.payload);
    case getType(actions.setCurrentPageSuccess):
      return setCurrentPageReducer(state, action.payload);
    case getType(actions.upsertObject):
      return upsertObjectReducer(state, action.payload);
    case getType(actions.removeObject):
      return removeObjectReducer(state, action.payload);
    case getType(actions.clear):
      return clearPagesReducer(state);
    case getType(actions.updatePreviewPngs):
      return updatePagePreviewPngsReducer(state, action.payload);
    case getType(actions.updateTotalGla):
      return updateTotalGlaReducer(state, action.payload);
    case getType(actions.set):
      return setPagesReducer(state, action.payload);
    default:
      return state;
  }
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doDelete({ payload: page }: ReturnType<typeof actions.delete>) {
    const rootState: RootState = yield select();
    const pages = listToArray(getPages(rootState));
    const currentPage = getCurrentPage(rootState);

    const setNewCurrentPage = compose(
      actions.setCurrentPage,
      (idx: number) => idx > 0 ? pages[idx - 1] : pages[1],
      findIndexBy(({ pageId }: Page) => pageId === currentPage?.pageId),
    );

    if (currentPage?.pageId === page.pageId) {
      yield put(actions.clearSketchPad());
      yield put(setNewCurrentPage(pages));
    }

    yield put(actions.remove(page.pageId));
  }

  function* doClearSketchPad() {
    yield all([
      put(wallsActions.clear()),
      put(figuresActions.clear()),
      put(positionedLabelsActions.clear()),
      put(positionedSymbolsActions.clear()),
      put(groupObjectActions.clear()),
      put(pointsActions.clear()),
      put(editModeActions.clearSelection())
    ]);
  }

  function* doPersistCurrentPageInState() {
    // save currently displayed sketchpad into pages state object
    const rootState: RootState = yield select();
    const currentPage = getCurrentPage(rootState);

    // prevents images from having selection even though sketchpad doesnt show an object as selected
    yield put(editModeActions.clearSelection());

    // return here to reduce nesting
    if (!currentPage?.pageId) return;

    const { pageId } = currentPage;
    const [walls, figures, positionedLabels, positionedSymbols, points] = [
      wallsSelectors.getAllWalls(rootState),
      figuresSelectors.getAllFigures(rootState),
      positionedLabelsSelectors.getAllPositionedLabels(rootState),
      positionedSymbolsSelectors.getAllPositionedSymbols(rootState),
      pointsSelectors.getAllPoints(rootState),
    ];

    const objectsArray = compose(
      mapBy((object: any) => [parseObjectId(object), { objectId: parseObjectId(object), object, computed: false }]),
      flatten,
      mapBy(listToArray),
    )([walls, figures, positionedLabels, positionedSymbols, points]);

    // page here can be undefined
    const page = getPageById(rootState, pageId);

    // only get objects if page is defined
    const objects = page?.objects;

    const newObjects = isImmutable(objects) ? objects : Immutable.Map<string, PageObject>(objects);
    const currentPageObjects = newObjects?.entrySeq().toArray();
    const objectIds = objectsArray?.map((object: any) => object[0]);
    const removedObjects = currentPageObjects?.filter((object: any) => !objectIds.includes(object[0]));

    // removes page objects
    yield all(removedObjects.map((object: any) => (put(actions.removeObject(pageId, object[0])))));

    // updates page objects
    yield all(objectsArray.map((object: any) => (put(actions.upsertObject(pageId, parseObjectId(object[1].object), object[1].object)))));

    // updates page totalGla count
    const totalGla = pixelAreaToSquareFeet(calculateTotalGlaArea(figures, points, walls));
    yield put(actions.updateTotalGla({ pageId: currentPage.pageId, totalGla }));

    // generates png for page
    const newPageObjects = Immutable.Map(objectsArray);
    yield call(doGeneratePng, { pageId: currentPage.pageId, objects: newPageObjects });
  }

  function* doAddPage(action: any) {
    const rootState: RootState = yield select();
    const page = action.payload;
    const loadingState = editModeSelectors.getEditMode(yield select());

    // update current page with all objects before doing any work
    yield put(actions.upsertCurrentPageObjects());

    if (getPageIds(rootState).length >= MAX_PAGES) {
      toast.error(messages.maxPages);
      yield undefined;
    } else {
      // clear out currently displayed objects from visible sketch pad
      if (loadingState !== 'loading') yield put(actions.clearSketchPad());

      // only clear the sketchpad if we have a sketch from localStorage while we are loading
      const localStorageSketch = localStorage.getItem('currentSketch');
      if (loadingState === 'loading' && localStorageSketch) yield put(actions.clear());

      // create new page object and set it as the currentPage
      yield put(actions.createNewPage(page));
      yield put(actions.setCurrentPageSuccess(page));
    }
  }

  function* doSetCurrentPage(action: any) {
    const page = action.payload; // page we are going to
    const currentPage = getCurrentPage(yield select());

    yield put(actions.upsertCurrentPageObjects());
    // yield put(printPreviewActions.updatePreviewImage());

    if (currentPage?.pageId !== page.pageId) {
      yield put(actions.upsertCurrentPageObjects());

      // clear out currently displayed objects from visible sketch pad
      yield put(actions.clearSketchPad());

      // update the redux store for to set the currentPage in state.
      yield put(actions.setCurrentPageSuccess(page));

      // place the items from the new current page onto the sketch pad
      yield call(placePageObjects, page);
    }
  }

  function* placePageObjects({ objects }: Page) {
    const parsedObjects = isImmutable(objects) ?
      compose(
        listToArray,
      )(objects) : listToArray(Immutable.Map(objects));

    yield all(parsedObjects.map(({ computed, object }: PageObject) => {
      if (computed) return undefined;
      if (object) {
        if ('positionedLabelId' in object) {
          return put(positionedLabelsActions.add(object));
        }
        if ('positionedSymbolId' in object) {
          return put(positionedSymbolsActions.add(object));
        }
        if ('pointId' in object) {
          return put(pointsActions.add(object));
        }
        if ('figureId' in object) {
          return put(figuresActions.add(object));
        }
        if ('wallId' in object) {
          return put(wallsActions.add(object));
        }
        if ('groupId' in object) {
          return put(groupObjectActions.add(object));
        }
      }
      return undefined;
    }));
  }

  function* doSetPagesAndPlaceFirst(action: any) {
    const pages: Immutable.List<Page> = action.payload;
    // assign array to pages object in state
    yield put(actions.set(pages));
    const firstPage = pages.get(0);
    if (firstPage) {
      // clear out visible sketch pad and model of all objects
      yield put(actions.clearSketchPad());

      // draw the first page on the sketch pad
      yield call(placePageObjects, firstPage);
    }
  }

  function* doGeneratePng(action: any) {
    const svgElement: SVGSVGElement = yield select(viewportSelectors.getSvgElement);
    const isLoading = editModeSelectors.getEditMode(yield select());

    const { pageId, objects, previewPngs } = action;
    const newObjects = Immutable.Map<string, PageObject>(objects);

    if (pageId && isLoading !== 'loading') {
      if (svgElement) {
        let formattedSketchObjects: any;

        // if there are no objects to render, this check fixes saving bug & outputs an empty sketch page successfully
        if (newObjects.size) {
          formattedSketchObjects = formatSketchObjects(newObjects);
        }
        const tableEl = document.getElementById(PRINT_TABLE_CONTAINER) as any as SVGSVGElement;

        const [sketchPng, tablePng] = yield all([
          call(svgAsPngUri, svgElement, printConfig, formattedSketchObjects),
          call(svgAsPngUri, tableEl, printConfig),
        ]);

        yield put(actions.updatePreviewPngs({ pageId, previewPngs: { sketch: sketchPng, areaTable: tablePng } }));
      } else {
        yield put(actions.updatePreviewPngs({ pageId, previewPngs }));
      }
    }
  }

  return function* saga() {
    yield all([
      takeLatest(actions.delete, doDelete),
      takeLatest(actions.add, doAddPage),
      takeLatest(actions.setCurrentPage, doSetCurrentPage),
      takeLatest(actions.setPagesAndPlaceFirst, doSetPagesAndPlaceFirst),
      takeLatest(actions.clearSketchPad, doClearSketchPad),
      takeLatest(actions.upsertCurrentPageObjects, doPersistCurrentPageInState),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
