/* eslint-disable default-case */
import {
  all, takeLatest, call, put, select,
} from 'redux-saga/effects';
import { createAction, getType, ActionType } from 'typesafe-actions';
import { toast } from 'react-toastify';

import { authConfig } from 'config/authConfig';
import { messages } from 'config/messages';
import { ApiSettings } from 'types/api/apiSettings';
import { LogLevel } from 'types/log';
import log from 'helpers/log';
import { RootState } from 'reducers/rootReducer';
import { getAccessToken } from 'effects/auth';
import * as ApiEffects from 'effects/api';
import { selectors as settingsSelectors, actions as settingsActions } from 'ducks/settings';
import { selectors as networkPersistenceSelectors, actions as networkPersistenceActions } from 'ducks/persistence/networkPersistence';
import { Setting } from 'types/settings';

// Action Types
const NAME = 'settingsPersistence';

export interface SettingsPersistenceState {
  readonly isLoading: boolean;
}

// Initial State
const initialState: SettingsPersistenceState = {
  isLoading: false,
};

// Action Creators
export const actions = {
  loadSettings: createAction(`${NAME}/loadSettings`)<void>(),
  setLoadingSettings: createAction(`${NAME}/setLoadingSettings`)<boolean>(),
  updateSetting: createAction(`${NAME}/updateSetting`)<void>(),
};

export type Actions = ActionType<typeof actions>

// Selectors
const getState = (rootState: RootState): SettingsPersistenceState => rootState[NAME];

const isLoading = (rootState: RootState): boolean => getState(rootState).isLoading;

export const selectors = {
  isLoading,
};

// Reducers
const setLoadingSettingsReducer = (
  state: SettingsPersistenceState, loading: boolean,
): SettingsPersistenceState => ({
  ...state,
  isLoading: loading,
});

export const reducer = (
  state: SettingsPersistenceState = initialState, action: Actions,
): SettingsPersistenceState => {
  switch (action.type) {
    case getType(actions.setLoadingSettings):
      return setLoadingSettingsReducer(state, action.payload as boolean);

    default:
      return state;
  }
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doloadSettings(): any {
    if (authConfig.enabled || process.env.REACT_APP_USE_MOCKS) {
      yield put(actions.setLoadingSettings(true));
      let response: ApiSettings[] | null = null;
      try {
        const accessToken = yield call(getAccessToken);
        response = yield call(ApiEffects.getSettings, accessToken);
      } catch (e) {
        toast.warning(messages.cannotLoadSettings);
        log(LogLevel.error, e);
      }

      if (response) {
        const responseObj = response.reduce((acc, { name, value, type }) => {
          switch (type) {
            case 'toggle':
              acc.showGrid = JSON.parse(value);
              break;
            case 'static':
              acc[name] = parseFloat(value);
              break;
            case 'radio':
              if (name === 'precision') {
                acc[name] = parseFloat(value);
              } else {
                acc[name] = value;
              }
              break;
          }
          return acc;
        }, {} as Record<string, string | boolean | number>);

        yield put(settingsActions.setUserSettings(responseObj));
        yield put(settingsActions.setSettingsModalForm(response));
      }
      yield put(actions.setLoadingSettings(false));
    }
  }

  function* doUpdateSettings(): any {
    if (authConfig.enabled || process.env.REACT_APP_USE_MOCKS) {
      const rootState = yield select();
      const updatedSetting: Setting = settingsSelectors.getLastUpdatedSetting(rootState);

      yield put(actions.setLoadingSettings(true));
      try {
        const accessToken = yield call(getAccessToken);
        yield call(ApiEffects.updateSetting, accessToken, updatedSetting);
      } catch (e) {
        toast.warning(messages.cannotUpdateSettings);
        log(LogLevel.error, e);
      }

      // if offline, store the action in a log to send when connection is established again
      const isOffline = networkPersistenceSelectors.getIsOffline(yield select());
      if (isOffline) {
        const offlineModeObject = {
          ...updatedSetting,
          requestType: 'POST',
          type: 'setting'
        };
        yield put(networkPersistenceActions.updateOfflineModeActions(offlineModeObject));
      }

      yield put(actions.setLoadingSettings(false));
    }
  }

  return function* saga() {
    yield all([
      takeLatest(actions.loadSettings, doloadSettings),
      takeLatest(actions.updateSetting, doUpdateSettings),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
